.invite {
  padding-bottom: 10px;
  padding-top: 90px;
  background-image: linear-gradient(#BCD1DB, #e7ebec);

  h3 {
    margin-bottom: 40px;
  }
}

.invite--2 .invite {
  position: relative;
  padding-bottom: 110px;

  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 52px;
    bottom: 0;
    left: 0;
    background-image: url(../img/onas-z-m.svg);
    background-position: center;
    background-repeat: repeat-x;
    z-index: 1;
    position: absolute;
  }

  margin-bottom: 30px;
}

.invite__row {
  width: 100%;
  display: flex;
  gap: 100px;
  align-items: center;
}

.invite__left {
  p {
    margin-bottom: 20px;
  }
}

.invite__right {
  flex: 0 0 660px;
}

.nr__list {
  display: flex;
  gap: 30px 20px;
  flex-wrap: wrap
}

.nr__item {
  flex: 0 0 50%;
  max-width: calc(50% - 20px);
  display: flex;
  align-items: center;

  strong {
    color: $main;
    font-size: 37px;
    flex: 0 0 100px;
    display: block;
    padding-right: 15px;
    text-align: right;
    margin-right: 10px;
    border-right: 4px solid #B8BCBD;
    line-height: 50px;
  }
}

@media(max-width: $grid-breakpoints-xl) {
  .invite {
    padding-top: 50px;
  }
  .invite__row {
    display: block;
  }
  .invite__left {
    margin-bottom: 30px;

    p {
      margin-bottom: 10px;
    }
  }
  .nr__list {
    max-width: 620px;
  }
}

@media(max-width: $grid-breakpoints-sm) {
  .nr__item strong {
    font-size: 26px;
    flex: 0 0 70px;
    padding-right: 10px;
    margin-right: 10px;
    line-height: 36px;
  }
  .nr__list {
    gap: 10px 6px;
  }
  .nr__item {
    max-width: 100%;
    flex: 0 0 100%
  }
}


.footer {
  overflow: hidden;
  background-image: linear-gradient(#E7EBEC, #E7EBEC, #220E06, #220E06);
  position: relative;
}


.footer--2 {
  background-color: transparent;

  .footer {
    background-color: transparent;
  }
}

.footer__bgImg {
  width: auto;
  height: 100%;

  position: absolute;
  top: 0;
  left: 50%;
  z-index: 0;
  transform: translate(-50%);

  @media(min-width: 1670px) {
    width: 100%;
  }

  @media(max-width: $grid-breakpoints-sm) {
    width: auto;

  }
}

.newsletter__title {
  font-size: $font20;
  font-weight: 700;
  color: $white;
}

.newsletter__form {
  max-width: 100%;
  position: relative;

  display: flex;
  gap: 5px;


  ::placeholder {
    color: #fff;
  }
}

.newsletter__form input {
  background: 0 0;
  color: #fff;
  border: 2px solid rgba(255, 255, 255, 0.4);
  padding: 10px;
  font-size: $font16;
  transition: all 0.3s ease-out;
  border-radius: 0;
  width: 100%;

  &:focus-visible {
    outline: none;

  }
}

.footer__wide {
  line-height: 2;
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media(min-width: $grid-breakpoints-xl) {
    flex-direction: row;
    gap: 50px;
  }
  @media(min-width: 1440px) {
    gap: 100px;
  }
}


@media(min-width: $grid-breakpoints-md) {
  .newsletter__form input {
    max-width: 300px;
  }
  .newsletter__title {
    margin-bottom: 0;
  }
  .newsletter__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
  }

  .newsletter__form {
    gap: 10px;

  }
}

@media(max-width: $grid-breakpoints-sm) {
  .newsletter__form .btn-form {
    text-indent: -10000px;
    padding: 0 24px;
    background-position: center right 16px;

    &:after {
      text-indent: 0px;
    }
  }

  .mt-30-sm {
    margin-top: 30px
  }
}


.footer__center {
  color: $white;

  padding-bottom: 25px;
  border-bottom: 1px solid $line-white;

  [class*=col-] {
    margin-bottom: 0;
  }

  .accordion {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }

  .accordion__btn {
    color: #fff !important;
    font-size: $font20;
    font-weight: 700;

    &:after {
      color: #fff !important;
      top: 10px;

    }
  }

  .accordion h3 {
    display: none;
  }

  h3 {
    font-size: $font20;
    font-weight: 700;
    margin-bottom: 30px;
  }

  ul {
    list-style: none;
    gap: 10px;
    margin-bottom: 30px;

    > li {
      position: relative;

      a {
        padding: 5px 0px;
        font-size: $font16;
        color: #fff;
        line-height: 1.7;
        display: inline-block;

        @media(hover: hover) {
          &:hover {
            color: $main;
          }
        }
      }
    }
  }

}


.footer__top {
  padding-top: 190px;
  padding-bottom: 40px;
  position: relative;
  z-index: 5;
  background-image: url("../img/bg-decor-h.svg");
  background-position: bottom;
  background-repeat: repeat-x;
}

.footer__logo {
  display: block;
  margin-bottom: 40px;
}


.footer__bottom {

  font-size: $font14;
  color: #fff;
  opacity: 0.7;
  padding-bottom: 13px;
  padding-top: 13px;

  img {
    max-height: 40px;
    width: auto;
    max-width: 100%;
    object-fit: contain;
  }

  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;

}


.footer__brand {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding-bottom: 10px;
  padding-top: 10px;
  line-height: 1;
  gap: 20px;

  a,
  span {
    display: inline-block;
    padding: 5px;
    vertical-align: bottom;
    line-height: 1;
  }

  img {
    width: auto;
  }
}

.footer__brandItem {
  display: flex;
  align-items: center;
}

@media(min-width: $grid-breakpoints-sm) {
  .footer__center {
    margin-bottom: 0;
    padding-top: 50px;

    .accordion__btn {
      display: none !important;
    }

    .accordion {
      border-bottom: none;
    }

    .accordion h3 {
      display: block;
    }
  }


}


@media(min-width: $grid-breakpoints-md) {
  .footer__bottom {
    .container {
      display: flex;
      align-items: center;
      gap: 30px;

    }
  }

  .footer__bgWrapper.trapez__imgWraper {
    height: 100%;

    border-bottom: none;
  }

  .footer .container {
    position: relative;
    z-index: 2;
  }


}

@media(min-width: $grid-breakpoints-lg) {
  .footer__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: auto;
    flex-direction: row;
  }
  .footer__logo {
    margin-bottom: 0px;
  }
}


@media(max-width: $grid-breakpoints-lg) {
  .footer__bottom {
    display: block;
  }
  .footer__brand {
    justify-content: center;
  }

}

@media(max-width: $grid-breakpoints-lg) {

  .footer__brand {
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }

}


@media(max-width: $grid-breakpoints-sm) {
  .footer .accordion__content {
    display: none;
  }


  .footer__top {
    margin-bottom: 15px;
    padding-top: 130px;
  }

}
