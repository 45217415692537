.container {
  padding-left: 20px;
  padding-right: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: $container-max-width-xxl;
  @media (min-width: $grid-breakpoints-sm) {
    padding-left: 60px;
    padding-right: 60px;
  }


  //@media (min-width: $grid-breakpoints-md) {
  //    max-width: $container-max-width-md;
  //}
  //
  //@media (min-width: $grid-breakpoints-lg) {
  //    max-width: $container-max-width-lg;
  //}
  //
  //@media (min-width: $grid-breakpoints-xl) {
  //    max-width: $container-max-width-xl;
  //
  //}
  //
  //@media (min-width: $grid-breakpoints-xxl) {
  //    max-width: $container-max-width-xxl;
  //
  //}
}


.container--wide {
  max-width: 1920px;
}
.container--500 {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}
.container--sm {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;

}
.container--md {
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}

.container--xl {
  max-width: 930px;
  margin-left: auto;
  margin-right: auto;
}

.container--xxl {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}


.container--1300 {
  max-width: 1340px;
  margin-left: auto;
  margin-right: auto;
}

.container--1400 {
  max-width: 1420px;
}

.container--1500 {
  max-width: 1480px;
}


.container--header {
  max-width: 1580px;
}


.justify-content-center {
  justify-content: center !important;
}

.break-column {
  flex-basis: 100%;
  width: 0;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  width: calc(100% + 30px);
}

ul.row{
  padding-left:0 !important;
}

[class*="col-"] {
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 30px;
  width: 100%;
}

.row--24 {
  margin-left: -12px;
  margin-right: -12px;
  width: calc(100% + 24px);

  [class*="col-"] {
    padding-left: 12px;
    padding-right: 12px;
    margin-bottom: 24px;
  }

  @media (max-width: $grid-breakpoints-sm) {
    margin-left: -5px;
    margin-right: -5px;
    width: calc(100% + 10px);

    [class*="col-"] {
      padding-left: 5px;
      padding-right: 5px;
      margin-bottom: 10px;
    }
  }
}

.row--16 {
  margin-left: -8px;
  margin-right: -8px;
  width: calc(100% + 16px);

  [class*="col-"] {
    padding-left: 8px;
    padding-right: 8px;
    margin-bottom: 16px;

  }

  @media (max-width: $grid-breakpoints-sm) {
    margin-left: -5px;
    margin-right: -5px;
    width: calc(100% + 10px);

    [class*="col-"] {
      padding-left: 5px;
      padding-right: 5px;
      margin-bottom: 10px;
    }
  }
}

.row--60 {
  @media(min-width: $grid-breakpoints-xl) {
    margin-left: -30px;
    margin-right: -30px;
    width: calc(100% + 60px);

    [class*="col-"] {
      padding-left: 30px;
      padding-right: 30px;
      margin-bottom: 60px;
      width: 100%;
    }
  }
}

.w-100 {
  width: 100% !important;
  max-width: 100% !important;
  flex: 0 0 100% !important;
}

.col-6 {
  width: 50%;
  flex: 0 0 50%;
}

@media (min-width: $grid-breakpoints-sm) {
  .col-sm-4 {
    max-width: 33.33%;
    flex: 0 0 33.33%;
  }

  .col-sm-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }
}


@media (min-width: $grid-breakpoints-md) {
  .col-md-4 {
    max-width: 33.33%;
    flex: 0 0 33.33%;
  }
  .col-md-5 {
    max-width: 41.66%;
    flex: 0 0 41.66%;
  }
  .col-md-8 {
    max-width: 66.66%;
    flex: 0 0 66.66%;
  }

  .col-md-50,
  .col-md-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }
}


@media (min-width: $grid-breakpoints-lg) {
  .col-lg-2 {
    max-width: 16.66%;
    flex: 0 0 16.66%;
  }
  .col-lg-3 {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .col-lg-4 {
    max-width: 33.33%;
    flex: 0 0 33.33%;
  }

  .col-lg-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }
  .col-lg-9 {
    max-width: 75%;
    flex: 0 0 75%;
  }
}

@media (min-width: $grid-breakpoints-xl) {
  .col-xl-2 {
    max-width: 16.66%;
    flex: 0 0 16.66%;
  }

  .col-xl-3 {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .col-xl-4 {
    max-width: 33.33%;
    flex: 0 0 33.33%;
  }

  .col-xl-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .col-xl-8 {
    max-width: 66.66%;
    flex: 0 0 66.66%;
  }

  .col-xl-15 {
    max-width: 15%;
    flex: 0 0 15%;
  }
  .col-xl-20 {
    max-width: 20%;
    flex: 0 0 20%;
  }
  .col-xl-65 {
    max-width: 65%;
    flex: 0 0 65%;
  }
}


.img--fluid {
  max-width: 100%;
  height: auto;
}

.box--center {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.nowrap {
  white-space: nowrap !important;
}

.section {
  padding-top: 40px;
  padding-bottom: 40px;
}

@media(max-width:$grid-breakpoints-sm){
  .mainpage{
    .section {
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }
}

.section--20{
  padding-top:20px;
}

.section--bottom{
 padding-bottom:40px;
}

.section--first{
  padding-top:60px;
}

.section--sh{
  padding-top: 130px;

  @media(max-width:$grid-breakpoints-lg){
    padding-top: 115px;
  }
}
.section--sh2{
  padding-top: 150px;

  @media(max-width:$grid-breakpoints-lg){
    padding-top: 130px;
  }
}

.d-block {
  display: block;
}

.ml-auto {
  margin-left: auto !important;
}

.pt-0 {
  padding-top: 0px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pt-30 {
  padding-top: 30px !important;
}

.pt-40 {
  padding-top: 40px !important;
}

.pt-50 {
  padding-top: 50px !important;
}

.pb-0 {
  padding-bottom: 0px !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.pb-30 {
  padding-bottom: 30px !important;
}

.pb-40 {
  padding-bottom: 40px !important;
}

.pb-50 {
  padding-bottom: 50px !important;
}

.mt-0 {
  margin-top: 0px !important;
}
.mt-10, .m-t-10 {
  margin-top: 10px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mt-100 {
  margin-top: 100px !important;

  @media(max-width: $grid-breakpoints-md) {
    margin-top: 50px !important;
  }
}

.mb-0 {
  margin-bottom: 0px !important;
}
.mb-5 {
  margin-bottom: 5px !important;
}


.mb-10 {
  margin-bottom: 10px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}
.mb-25 {
  margin-bottom: 25px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}
.mb-60 {
  margin-bottom: 60px !important;
}

.mb-70 {
  margin-bottom: 70px !important;
}

@media(max-width: $grid-breakpoints-md) {
  .mb-50 {
    margin-bottom: 40px !important;
  }
  .mb-60 {
    margin-bottom: 40px !important;
  }

  .mb-70 {
    margin-bottom: 40px !important;
  }
}

.flex-center{
  display:flex;
  align-items: center;
  justify-content: center;
}