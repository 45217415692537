.breadcrumbs {
  padding-top: 0px;
  padding-bottom: 7px;


  ul {
    display: inline-flex;
    flex-wrap: wrap;

    span,
    a {
      font-size: $font12;
      color: rgba(255, 255, 255, 0.8);
      display: inline-block;
      padding: 5px 0;
      line-height: 1;
      font-weight: 400;

      &:before {
        margin-right: 5px;
        color: inherit;
        content: "/" / "";
      }
    }

    li:first-child {
      a:before {
        content: none;
      }
    }

    a {
      margin-right: 5px;

      svg {
        stroke: $txt;
        transition: all 0.3s ease-in;
      }

      &:focus,
      &:hover {
        outline: none;
        color: $main;

        svg {
          stroke: $main;

        }

        &:after {
          color: $txt;
        }
      }

      &:focus-visible {
        outline: 2px solid $main;

      }
    }
  }

  @media(min-width: $grid-breakpoints-md) {
    padding-bottom: 15px;
    ul {
      line-height: 1;

      a,
      span {
        font-size: $font14;
      }
    }
  }

  @media(max-width: $grid-breakpoints-sm) {
    overflow: auto;
    margin-bottom:10px;
    ul{
      flex-wrap: initial;
    }
    a,
    span {
    white-space: nowrap;
    }
  }
}

.breadcrumbs2 {
  padding-bottom: 10px;

  .breadcrumbs2, .breadcrumbs {
    padding-bottom: 0px;
  }

  ul {

    a, span {
      color: $txt-light;
    }
  }

  @media(max-width: $grid-breakpoints-lg) {
    padding-bottom: 5px;


  }
}

//.heroS {
//  .breadcrumbs {
//    ul {
//
//      padding-right: 10px;
//      background: rgba(0, 0, 0, 0.15);
//      border-radius: 6px;
//    }
//  }
//}