.btn--heart {
  font-size: 14px;
  font-weight: 700;
  padding: 10px 0 10px 30px;
  min-width: 50px;
  height: 50px;
  border: 0;
  background: 0 0;
  position: relative;

  &:before {
    content: url("../img/ico/ico-heart-white.svg");
    position: absolute;
    top: 18px;
    left: 5px;
  }
}

.btn--heartActive:before {
  content: url("../img/ico/ico-heart-white-full.svg");
}


.product__figure {
  position: relative;
  height: 0;
  padding-top: 65%;
  transition: all 0.3s ease-in-out;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    bottom: 50%;
    left: 50%;
    transform: translate(-50%, 50%);
    position: absolute;
    transition: all 0.3s ease-out;
  }
}

.product__link {
  position: relative;
  height: 100%;
  color: $txt;
  display: flex;
  flex-direction: column;
  background: $white;
  @media(hover: hover) {
    &:hover,
    &.active {
      box-shadow: 0 6px 9px rgba(0, 0, 0, 0.2);

      .product__figure img {
        width: 104%;
        height: 104%;
      }
    }
  }
}


.product__item {
  transition: opacity .3s ease-in-out;
  position: relative;


  &.swiper-slide {
    height: auto;
  }

  .btn--heart {
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 4;
  }
}

.product__item--op0 {
  opacity: 0;
}

.product__info {
  padding: 20px 30px 30px 30px;
}

.product__top {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  gap: 0 20px;

  .product__level {
    margin-left: auto;
  }
}

.product__last {
  padding: 5px 10px 5px 24px;
  background: $red;
  color: $white;
  font-weight: 700;
  font-size: $font11;
  line-height: 1;
  display: block;
  border-radius: 15px;
  white-space: nowrap;
  position: absolute;
  top: 15px;
  left: 15px;
  z-index: 2;

  &:before {
    content: url("../img/ico/ico-bell-2.svg");
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -5px;
    margin-top: 1px;
  }
}

.product__country {
  display: flex;
  align-items: center;
  gap: 5px;
  color: $txt-dark;
}

.product__bottom {
  border-top: 1px solid #D6D6D6;
  margin-top: auto;
  display: flex;
  align-items: center;
  gap:30px;
  padding: 10px 0;

  margin-left: 30px;
  margin-right: 30px;
}

.product__right {
  margin-left: auto;
  font-size: 14px;
  color: $txt-dark;
  white-space: nowrap;

}

.product__price{
  font-size: 18px;
  color:$txt;
}

.product__calendar {
  background: url("../img/ico/ico-calendar.svg") no-repeat center;
  background-size: 20px;
  padding-left: 30px;
  background-position: left center;
  font-size: 14px;
  color: $txt-dark;
  line-height: 27px;
}

.otherTherm__wrapper{
  &:after{
    content: url("../img/ico/ico-arrow-calendar.svg");
    margin-left:5px;
    position:relative;
    top:1    px;
  }
}


.product__title {
  margin-bottom: 0;
  transition: color 0.3s ease-in-out;
  line-height: 1.2;
  color: $txt;
  font-size: $font16;

}

.product__title--big {
  font-size: $font26
}

.product__description {
  font-size: 16px;
  margin-top:25px;
  margin-bottom:35px;
  color:$txt;
}

.product__feature {
  color:$txt-light;
  font-size:12px;
  display:flex;
  gap:5px 30px;
  flex-wrap:wrap;

  >div{
    display:flex;
    align-items: center;
    gap:10px;
  }
}

.product__new {
  color: $main;
  display: inline-block;
  font-size: $font17;
  background: #E4E4E4;
  border-radius: 24px;
  padding: 3px 10px 3px 23px;
  position: absolute;
  top: 20px;
  left: 37px;
  z-index: 20;
  line-height: 1;
  font-family: $font2-family-name;

  &:before {
    content: "";
    display: inline-block;
    width: 34px;
    height: 34px;
    border-radius: 2px;
    background-image: url("../img/ico/ico-wykrzyknik.svg");
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -17px;
  }
}


.product__swiper {


}


.otherTherm__btn {
  font-size: 15px;
  opacity: 0.8;

  &:after {
    content: "\00BB";
    padding-left: 5px;
  }
}

.otherTherm__wrapper {
  position: relative;
  line-height: 1.5;


  &:hover {
    .otherTherm__list {
      opacity: 1;
      z-index: 10;
    }
  }
}


.product__item {
  transition: max-width 0.2s ease-out, 0.1s ease-out;

  &.noactive {
    opacity: 0;
    max-width: 0 !important;
    overflow: hidden;
    visibility: hidden;
    z-index: -1000;
    padding: 0;

  }
}

.otherTherm__list {
  position: absolute;
  bottom: 30px;
  right: -10px;
  min-width: 100%;
  padding: 5px 10px;
  background: $txt;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  color: #fff;
  font-size: 12px;
  opacity: 0;
  z-index: -100;
  transition: opacity 0.3s ease-out;
  border-radius: 6px;

  li {
    white-space: nowrap;
  }

  &:before {
    content: "";
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 7px solid $txt;
    position: absolute;
    bottom: -7px;
    left: 50%;
    margin-left: -4px;

  }
}


@media(max-width:$grid-breakpoints-xl){
  .product__title--big {
    font-size: $font20;
  }

  .product__description{
    font-size: $font15;
  }
  .product__calendar {
    font-size: 12px;
  }
  .product__right {
    font-size: 13px;
  }
  .product__price {
    font-size: 16px;
  }
  .product__info {
    padding: 20px;
  }
  .product__bottom {
    margin-left: 20px;
    margin-right: 20px;
    gap:15px;
  }
}

@media(max-width:$grid-breakpoints-sm){
  .product__title {
    font-size: $font14;
  }
  .product__calendar {
    font-size: 10px;
    background-size: 15px;
    padding-left: 22px;
  }
  .product__right {
    font-size: 11px;
  }
  .product__price {
    font-size: 14px;
  }
  .product__description {
    font-size: 10px;
  }
}