
html {
  font-size: 16px;
  scroll-behavior: smooth;
}

@import "variables";
@import "fonts";
@import "reset";
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

iframe,
video {
  max-width: 100%;
  width: 100%;
  vertical-align: middle;

}

strong {
  font-weight: 700;
}

.bold {
  font-weight: 700 !important;
}

.semi-bold {
  font-weight: 500 !important;
}

.font-weight-400 {
  font-weight: 400 !important;
}

.font--12 {
  font-size: $font12 !important;
}

.font--14 {
  font-size: $font14 !important;
}

.font--16 {
  font-size: $font16 !important;
}

.font-family-1 {
  font-family: $font-family-name !important;
}

.font--18 {
  font-size: $font18 !important;
}

.font--20 {
  font-size: $font20 !important;
}

.main {
  color: $main !important;
}

.grey {
  color: $txt-light !important;
}

.black {
  color: #000 !important;
}

.red {
  color: $red !important;
}

.green {
  color: $green !important;
}

.blue {
  color: $blue !important;
}

.violet {
  color: $violet !important;
}

.yellow {
  color: $yellow !important;
}

.brown {
  color: $brown !important;
}


.bg--light {
  background-color: $bg-light;
}

.bg--grey {
  background-color: $bg-grey;
}

.bg--dark {
  background-color: $txt;
}

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: left !important;
}


input,
textarea,
select {

  border: 1px solid $input-border;
  line-height: 1.6;
  padding: 10px 15px;
  color: $input;
  font-family: $font-family-name;
  font-weight: 400;
  font-size: $font18;

}

::placeholder {
  color: $placeholder;
  font-weight: 400;
  font-family: $font-family-name;
}

input:not([type="date"], [type="number"]),
textarea,
button {
  -webkit-appearance: none;
  cursor: pointer;
}


button {
  padding: 0;
}

/* remember to define focus styles! */
:focus {
  outline: none;
}

input, textarea {
  &:focus-visible {
    outline: none;
    box-shadow: inset 0px 0px 0px 1px $green;
    border-color: $green;
  }
}

a,
button {
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:focus-visible {
    outline: 2px solid $main;
    outline-offset: 1px;
  }
}

a {
  color: $main;
  text-decoration: none;

  @media(hover: hover) {
    &:hover {
      color: $main-hover;
    }
  }

  &:focus-visible {
    outline-offset: 2px;
  }
}

body {
  line-height: 1.5;
  font-size: $font16;
  font-family: $font-family-name;
  font-weight: 400;
  color: $txt;
  font-style: normal;

}

*,
*:before,
*:after {
  box-sizing: border-box;
  font-family: $font-family-name;
}


em {
  font-style: italic;
}

h1,
h2,
h3 {

  font-family: $font2-family-name;
  line-height: 1.2;
  letter-spacing: initial;
  font-weight: 700;

  a {
    color: inherit;
  }
}

h1,
.h1 {
  font-size: $font40;
  margin-bottom: 30px;
  font-weight: 700;

}

h2 {
  font-size: $font34;
  margin-bottom: 25px;
  font-family: $font2-family-name;
  font-weight: 700;
}

h3 {
  font-size: $font16;
  margin-bottom: 15px;
}

.title {
  margin-bottom: 30px;
  font-size: $font37;
  font-weight: 700;
  position: relative;
}

.title-s {
  margin-bottom: 15px;
  font-size: $font20;
  font-weight: 700;
  display: block;
}


.title-quote {
  &:after {
    content: url("../img/ico/ico-quote.svg");
    position: relative;
    top: -17px;
    left: 5px;
  }
}


.title2 {
  text-align: center;
  font-size: $font34;
  margin-bottom: 45px;

  span {
    display: block;
    font-size: $font18;
    color: $green;
    font-family: Georgia;
    font-style: italic;
    margin-bottom: 7px;
  }
}

.title2--w {
  color: #fff;

  span {
    color: #fff;
  }
}

.title2--blue {
  color: #fff;
  margin-bottom: 35px;

  span {
    color: $blue;
  }
}


.title--4 {
  font-family: $font2-family-name;
  font-size: $font24;
  font-weight: 700;
}

.title-f1 {
  font-family: $font-family-name;
  font-weight: 700;
  font-size: $font24;

}


h4,
h5,
h6 {
  line-height: 1.5;
  font-size: $font16;
  font-weight: 700;
  margin-bottom: 10px;
}

.h--main {
  margin-bottom: 25px;
  color: $main;
  font-size: $font30
}

.h--main2 {
  margin-bottom: 25px;
  color: $main !important;
  font-size: $font28 !important;
}

p,
ul,
ol {
  line-height: 1.6;
}

ul,
ol {
  list-style: none;

}

.description {
  margin-bottom: 40px;
  max-width: 1000px;
}

@media(max-width: $grid-breakpoints-xl) {
  .title {
    font-size: $font34;
  }
}


@media(max-width: $grid-breakpoints-md) {
  .title {
    font-size: $font30;
  }
}

@media(max-width: $grid-breakpoints-sm) {
  .title {
    font-size: $font24;
  }
  .title--4 {
    font-size:20px;
  }

}


.page__txt {


  h1,
  .title--2 {
    font-size: $font40;
  }

  h2 {
    font-size: $font34;

    margin-bottom: 15px;

    &:not(:first-child) {
      margin-top: 30px;
    }
  }

  h3 {
    font-size: $font26;
    margin-top: 30px;
  }

  ul,
  ol {
    list-style: initial;
    padding-left: 30px
  }

  ol {
    list-style: decimal;

    ul {
      list-style: lower-alpha;
    }
  }

  ol,
  ul,
  p {
    margin-bottom: 15px;
  }

  li {
    margin-bottom: 5px;
  }

  a {
    text-decoration: underline;
  }

  @media(max-width: $grid-breakpoints-sm) {

    h1,
    .title--2 {
      font-size: $font30;
    }

    h2 {
      font-size: $font34;
    }

    h3 {
      font-size: $font22;
    }
  }
}


a,
span,
strong {
  line-height: inherit;
}

.link--underline {
  text-decoration: underline;
  color: $txt;
  border: none;
  background: none;

  @media(hover: hover) {
    &:hover {
      color: $main;
    }
  }
}

.link--underline-main {
  text-decoration: underline;
  color: $main;

  @media(hover: hover) {
    &:hover {
      color: #000;
    }
  }
}

.link--dark {
  color: $txt;

  @media(hover: hover) {
    &:hover {
      color: $main;
    }
  }
}

.alert,
#komunikat,
.komunikat {
  border: 1px solid rgba(0, 0, 0, 0);

  margin-bottom: 20px;
  padding: 15px;
  text-align: center;
  font-size: $font16;
  line-height: 1.4;
  background-color: #f8fbcb;
  color: $txt !important;
  display: block;

}

.alert-success {
  background-color: rgba(139, 145, 60, 0.1) !important;
  // border-color: #D6E9C6;
  color: $green !important;
}

.alert-info {
  background-color: #eaf6fb !important;
  // border-color: rgb(103, 121, 171);
  //     color: rgb(103, 121, 171);; }
}

.alert-warning {
  background-color: #fef6f6 !important;
  // border-color: #FBEED5;
  // color: #C09853;
}

.alert-info2 {
  background-color: $blue !important;
  // border-color: #FBEED5;
  color: #fff !important;
}

.alert-danger {
  background-color: #fef8f8 !important;
  // border-color: #f1e3e5;
  color: $red !important;
}

hr {
  border: none;
  border-top: 1px solid $line;
  margin-top: 20px;
  margin-bottom: 20px;
}


.img--fluid {
  max-width: 100%;
  height: auto;
}

.img--border {
  border-radius: 12px;
}

.box--center {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.nowrap {
  white-space: nowrap !important;
}

.btn {
  background: $main;
  color: #fff;
  padding: 15px 60px 15px 20px;
  min-height: 50px;
  font-size: $font14;
  font-weight: 700;
  white-space: nowrap;
  position: relative;
  display: inline-flex;
  align-items: center;
  transition: all 0.3s ease-in-out;
  border: 1px solid transparent;
  line-height: 1;
  text-align: center;
  text-decoration: none !important;
  background-image: url("../img/ico/ico-btn-arrow-2.svg");
  background-repeat: no-repeat;
  background-position: center right 22px;

  @media(hover: hover) {
    &:hover {
      background-color: $txt;
      color: #fff !important;
    }
  }
}

.btn--trans {
  background: transparent;
  border: 1px solid $line-white;

}

.btn--blue {
  background-color: #E1EAF0;
  color: $txt;
  @media(hover: hover) {
    &:hover {
      background-color: $blue;
      color: #fff !important;
    }
  }
}


.btn--black {
  background-color: $txt;
  color: #fff;
  @media(hover: hover) {
    &:hover {
      background-color: $main;
      color: #fff !important;
    }
  }
}

.btn--white {
  background-color: $white;
  color: $txt;
  border: 1px solid #DBDBDB;
  background-image: url("../img/ico/ico-btn-arrow.svg");

  @media(hover: hover) {
    &:hover {
      border-color: $txt;
      background-color: $txt;
      color: #fff !important;
    }
  }
}

.btn--plus {
  background-image: url("../img/ico/ico-plus.svg");
}

.btn--big {
  font-size: 20px;
  @media(min-width: $grid-breakpoints-md) {
    font-size: 25px;
    background-size: 26px;
    padding-right: 80px;
    padding-left: 30px;
  }
}


.btn--shop {
  padding-left: 80px;
  background-color: $main;
  background-image: url("../img/ico-cart-white.svg");
  background-position: 25px center;
  background-repeat: no-repeat;

  &:hover {
    background-color: $main-hover;
    color: #fff !important;
  }
}


.btn--all {

  .hidden {
    display: none;
  }

  &.active {
    span {
      display: none;
    }

    .hidden {
      display: inline-block;
    }
  }

}

.btn--more {
  padding-left: 45px;

  &:before {
    content: "+";
    position: absolute;
    top: 20px;
    left: 25px;
  }
}

.btn--map {
  background: $bg-grey;
  color: #000;
  padding-left: 55px;
  position: relative;

  &:before {
    content: url("../img/ico/ico-map.svg");
    position: absolute;
    top: 20px;
    left: 22px;
    transition: all 0.3s ease-in-out;
  }

  @media(hover: hover) {
    &:hover {
      background: #000;
      color: #fff !important;

      &:before {
        filter: brightness(0) invert(1);
      }
    }
  }
}

.btn--search {
  border: 1px solid rgba(65, 65, 65, .8);
  background: transparent;
  width: 100%;
  text-align: left;
  font-size: 18px;

  &:after {
    content: "";
    width: 12px;
    height: 12px;
    border-left: 3px solid #fff;
    border-bottom: 3px solid #fff;
    position: absolute;
    display: block;
    top: 50%;
    right: 20px;
    transform-origin: center;
    transform: translateY(-50%) rotate(-45deg);
    margin-top: -3px;
  }
}


.pr__nav-btn {
  position: relative;
  background: $bg-grey;
  width: 100%;
  padding-right: 50px;
  color: #000;
  text-align: left;

  &:after {
    content: "";
    width: 12px;
    height: 12px;
    border-left: 3px solid #000;
    border-bottom: 3px solid #000;
    position: absolute;
    display: block;
    top: 50%;
    right: 20px;
    transform-origin: center;
    transform: translateY(-50%) rotate(-45deg);
    margin-top: -3px;
  }
}


table {
  max-width: 100%;

  font-size: $font14;

  th {
    background: $bg-grey;
    font-weight: 700;
    border: 1px solid $line;
    padding: 10px 10px;
    vertical-align: middle;
  }

  td {
    border: 1px solid $line;
    padding: 7px 10px;
    vertical-align: middle;
  }

  [align="right"] {
    text-align: right;
  }

  [align="left"] {
    text-align: left;
  }

  [align="center"] {
    text-align: center;
  }
}


.table__wrapper {
  table {
    width: 100%;
  }

  a {
    display: inline-block;
    padding: 1px 5px;
    text-decoration: underline;
    font-weight: bold;
  }

  font-size: $font12;

  @media(max-width: 768px) {
    overflow: auto;

    table {
      min-width: 600px;
      width: initial;
    }

    th,
    td {
      padding: 5px;
    }
  }
}


.btn--table {
  background: $txt;
  color: #fff;
  padding: 3px 10px !important;
  border-radius: 13px;
  margin-top: 7px;
  text-decoration: none !important;

  @media(hover: hover) {
    &:hover {
      background: $main-hover;
      color: #fff;
    }
  }
}

.btn--tableR {
  background: $red;

  @media(hover: hover) {
    &:hover {
      background: $red-hover;

    }
  }
}

.overflow--hidden {
  overflow: hidden;
}

[disabled] {
  opacity: 0.3;
}


.file__list {
  margin-bottom: 20px;
  padding-left: 0 !important;
  list-style: none !important;
  border: 1px solid $line;
  border-radius: 12px;
}

.file__item {
  padding-left: 15px;
  padding-right: 15px;

  &:last-child {
    margin-bottom: 0;

    a {
      border-bottom: none;
    }
  }
}


.file__left {
  display: flex;
  gap: 15px;
  align-items: center;
  width: 100%;
}

.file__link {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 15px 0;
  color: $txt;
  gap: 20px;

  border-bottom: 1px solid $line;
  text-decoration: none !important;

  @media(hover: hover) {
    &:hover {
      color: $txt;

      .file__title {
        color: $main;
      }

      .btn {
        background: $main;
      }
    }
  }

  @media(max-width: $grid-breakpoints-md) {
    flex-wrap: wrap;
    gap: 10px;

    .file__left {
      width: 100%;
      padding-right: 40px;
      flex-direction: column;
      gap: 5px;


    }

    .file__des {
      max-width: 100%;
      flex: 0 0 100%;
      width: 100%;
    }

    .file__size {
      margin-left: 0;
    }

    .btn--file {
      margin-left: auto;
      padding: 10px 15px 10px 40px;

      &:after {
        left: 12px;
        margin-top: 2px;

      }
    }
  }
}

.file__size {
  color: $txt-light;
  font-size: $font16;
  flex: 0 0 100px;
  max-width: 100px;
  margin-left: auto;

}

.file__title {
  font-size: $font18 !important;
  margin: 0 !important;
  display: inline;
  width: 100%;
  transition: all .3s ease-out;

}

.file__des {
  max-width: 40%;
  flex: 0 0 40%;
  font-size: $font12 !important;
  margin-bottom: 0 !important;
}

.btn--file {
  padding-left: 50px;
  text-align: left;
  position: relative;

  &:after {
    content: url("../img/ico-download.svg");
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 20px;
    margin-top: 3px;
  }
}

.file__heading {
  margin-bottom: 20px;
}

.info-important {
  padding-left: 20px;
  background-image: url("../img/ico/ico-i-grey.svg");
  background-size: 14px;
  background-position: left center;
  background-repeat: no-repeat;
  color: #959595;

}


.info-important__bubbles {
  padding-left: 30px;
  background-image: url("../img/ico/ico-i-grey.svg");
  background-size: 14px;
  background-position: left center;
  background-repeat: no-repeat;
  color: #959595;

}


.info-bubble {
  display: inline-flex;
  width: 30px;
  height: 30px;
  background-image: url("../img/ico/ico-i-new.svg");
  background-size: 24px;
  background-position: center;
  background-repeat: no-repeat;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  position: relative;
  border-radius: 50%;
  cursor: pointer;
  vertical-align: middle;

  span {
    position: absolute;
    bottom: 125%;
    font-size: $font10;
    line-height: 1.3;
    display: block;
    width: 250px;
    left: 0;
    background: #fff;
    z-index: -10;
    opacity: 0;
    font-weight: 400;
    box-shadow: 0 6px 9px 0 rgba(0, 0, 0, .16);
    transition: all .3s ease-in-out;
    padding: 6px 5px;
    text-align: center;
    color: $txt !important;
    font-weight: 400;
  }

  &:hover {
    span {
      opacity: 1;
      z-index: 10;
    }
  }
}

.pr__title {
  margin-bottom: 40px;
  padding-top: 10px;
  padding-left: 25px;
  padding-bottom: 10px;
  background-image: url(../img/line-orange.svg);
  background-repeat: repeat-y;
  background-position: left top;
  font-size: 37px;

  @media(max-width: $grid-breakpoints-md) {
    font-size: 30px;
  }
  @media(max-width: $grid-breakpoints-sm) {
    font-size: 24px;
  }
}
