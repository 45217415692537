.tabs__navlist {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 3px;
  position: relative;
  overflow: auto;
  width: 100%;
  padding: 8px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.16);
  border-radius: 12px;
  margin-bottom: 20px;
  background: #fff;
  @media(min-width: $grid-breakpoints-xl) {
    gap: 6px;
    margin-bottom: 40px;
  }
}

.tabs{
  ::-webkit-scrollbar {
    height: 2px;
  }

  ::-webkit-scrollbar-track {
    background: $line;
    border-radius: 2px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: $txt;
    outline: 1px solid $txt-light;
    border-radius: 2px;
  }

  ::-webkit-scrollbar:horizontal {
    height: 2px;
  }
}


.tabs__nav-trigger {
  padding: 9px 8px 9px 8px;
  border: none;
  background: none;
  display: inline-block;
  font-size: $font20;
  transition: all 0.3s ease-in-out;
  white-space: nowrap;
  display: flex;
  align-items: center;
  font-family: $font2-family-name;
  gap: 7px;
  color: $main;
  height: 50px;
  border-radius: 6px;

  @media(hover: hover) {
    &.hover {
      &.tabs__nav--last {
        background: $main;
        color: #fff;
      }

      &.guide__btn--filter.tabs__nav--last, &.tabs__nav--last.tabs__nav--white{
        img {
          filter: brightness(0) invert(1);
        }
      }
    }

  }

  &[aria-selected="true"], &.active {
    &.tabs__nav--last {
      background: $main;
      color: #fff;

      img {
        filter: none;
      }
    }

    &.guide__btn--filter.tabs__nav--last, &.tabs__nav--last.tabs__nav--white{
      img {
        filter: brightness(0) invert(1);
      }
    }
  }
}

.tabs__navContainer {
  &.container {
    @media(max-width: $grid-breakpoints-xl) {
      max-width: 100%;
    }
  }
}


.tabs__panel {
  display: none;

  &.is-current {
    display: block;
  }
}

@media(min-width: 1420px) {
  //
  //.tabs__nav-item {
  //  width: 100%;
  //}

  .tabs__nav-trigger {
    width: 100%;
    justify-content: center;
    gap: 10px;
    font-size: $font24;
    padding: 9px 16px 9px 16px;

    img {
      width: 36px;
    }
  }

}