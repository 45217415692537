.disabled {
  color: $placeholder;
}

fieldset {
  border: none;
  padding: 0;
}


.form-h-info {
  margin-top: 5px;
  font-size: $font10;
  max-height: 0;
  overflow: hidden;
  color: $txt-light;
  transition: all 0.3s ease-in-out;
}

.form__input input:focus + .form-h-info {
  max-height: 100px;
}

.input--data{
  background-image: url("../img/ico/ico-calendar-input.svg");
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: center right 15px;
}


.form__select {

  padding: 0;
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin-bottom: 25px;
  z-index: 10;
  width: 100%;

  label {
    color: $label;
    font-size: $font12;
    margin-bottom: 6px;
    font-weight: 700;
    display: block;
  }

  &.is--invalid {

    &.custom-select .select-selected, select {
      border-color: $red;
    }
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 1px solid $input-border;
    border-radius: 6px;
    padding: 15px 45px 15px 15px;
    display: block;
    background: transparent;
    width: 100%;
    min-height: 48px;
    font-size: $font18;

    line-height: 1.5;
    cursor: pointer;

    &:focus,
    &:focus-visible {
      box-shadow: inset 0px 0px 0px 1px $green;
      border-color: $green;
      // outline:none;
    }
  }

  option {
    border: none;
    padding: 10px;
    font-size: $font16;
    color: $input;
    line-height: 40px;
  }

  &:after {
    content: "";
    position: absolute;
    right: 15px;
    bottom: 26px;
    z-index: -1;
    width: 10px;
    height: 10px;
    display: block;
    border-left: 3px solid $input;
    border-bottom: 3px solid $input;
    transform: rotate(-45deg);
  }

  @media(max-width: $grid-breakpoints-sm) {
    width: 100%;

    select {
      width: 100%;
    }
  }

}


.is--desktop {
  .custom-select.select {
    display: none; /*hide original SELECT element: */
  }

  .custom-select select {
    display: none; /*hide original SELECT element: */
  }

  .custom-select.form__select:after {
    content: none;
  }
}

.custom-select {
  position: relative;
  z-index: 40;


  .select-selected {
    color: $txt;
    border-radius: 6px;
    padding: 15px 45px 15px 15px;
    border: 1px solid $input-border;
    display: block;
    background: #fff;
    width: 100%;
    font-weight: 700;
    font-size: $font18;
    position: relative;
    z-index: 98;

    &:after {
      content: "";
      position: absolute;
      right: 15px;
      bottom: 25px;
      z-index: -1;
      width: 10px;
      height: 10px;
      display: block;
      border-left: 3px solid $input;
      border-bottom: 3px solid $input;
      transform: rotate(-45deg);
    }
  }

  .select-arrow-active {
    border-color: transparent;
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.26);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background:$bg-grey;
  }

  .select-items {
    position: absolute;
    background-color: #fff;
    border:1px solid $bg-grey;
    border-top:0;


    top: 100%;
    left: 0;
    right: 0;
    z-index: 99;
    border-radius: 12px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    box-shadow: 0 6px 9px rgba(0, 0, 0, 0.36);
    overflow: auto;
    max-height: 300px;


    &:after{
      width:100%;
      height:5px;
      background:#fff;
      z-index:10;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      content:"";
      display:block;
      position:sticky;
      bottom:0;
      left:0;
    }

    &.select-hide {
      display: none;
    }

    div {
      padding: 8px 15px;
      cursor: pointer;
      font-size: $font18;

      &:not(.same-as-selected) {
        @media (hover: hover) {
          &:hover {
            background-color: $main;
            color: #fff;
          }
        }
      }
    }
  }

  .same-as-selected {
    background-color: rgba(0, 0, 0, 0.1);
  }
}


.form__checkbox {
  margin-bottom: 0px;
  position: relative;

  input {
    position: absolute;
    left: 0;
    top: 0;
    padding: 0;
    z-index: -1000;
    opacity: 0;
    width: 0;
    height: 0;
  }

  a {
    text-decoration: underline;
    font-weight: 500;
  }

  label {
    position: relative;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 34px;
    line-height: 20px;
    color: $label;
    font-size: $font18;
    line-height: 1.4;
    cursor: pointer;
    display: inline-block;
    width: 100%;

    @media(max-width:$grid-breakpoints-sm){
      font-size: $font16;
    }

    &:before {
      content: "";
      width: 24px;
      height: 24px;
      position: absolute;
      top: 5px;
      left: 0;
      display: block;
      border: 2px solid $input-border;
      background-color: #fff;
      transition: all 0.3s ease-out;
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 12px;
      left: 7px;
      width: 0;
      height: 0px;
      transform: rotate(-45deg);
      border-radius:2px;
    }

  }

  input:checked ~ label:before {
    background-color: $main;


  }

  input:checked ~ label:after {

    width: 12px;
    height: 7px;
    border-bottom: 3px solid #fff;
    border-left: 3px solid #fff;
  }

  input:focus-visible ~ label:before {
    outline: 2px solid $main;
    outline-offset: 2px;
  }

  input[type="radio"] ~ label {

    &:before {
      border-radius: 50%;
    }
  }

  input[type="radio"]:checked ~ label:after {
    width: 14px;
    height: 14px;
    border: none;
    border-radius: 50%;
    background: $main;
    top: 10px;
    left: 5px;
  }

  input[type="radio"]:checked ~ label:before {
    background: #fff;
  }

  &.is--invalid {

    label:before {
      border-color: $red;
    }
  }
}


.form__checkbox--small {
  label {
    font-size: $font12;
  }
}

.form__checkbox--medium {
  label {
    font-size: $font16;
  }
}

//.input--data {
//  background: url("../img/ico/ico-date-input.svg") right 15px center no-repeat;
//}

.form__input {
  margin-bottom: 15px;
  position: relative;

  label {
    font-size: $font10;
    margin-bottom: 6px;
    font-weight: 700;
    color: $txt;
    display: block;
    position: absolute;
    background: #fff;
    padding: 0 5px;
    top: 10px;
    z-index: -1;
    opacity: 0;
    transition: top 0.3s ease-out;
  }

  textarea {
    width: 100% !important;
  }

  input,
  textarea {
    border: 1px solid $input-border;
    width: 100%;
    display: block;
    color: $input;
    font-size: $font16;
    padding: 12px 15px;
    min-height: 50px;
    border-radius:0;

    &:focus,
    &:focus-visible {
      border-color: $txt;
      box-shadow: inset 0px 0px 0px 2px $txt;

      &::placeholder {
        opacity: 0;
      }
    }
  }

  input:focus ~ label, input:not(:placeholder-shown) ~ label,
  textarea:focus ~ label, textarea:not(:placeholder-shown) ~ label
  input:valid:required ~ label {
    opacity: 1;
    top: -5px;
    left: 20px;
    z-index: 5;
  }


  &.is--invalid {

    label {
      color: $red;
    }

    input,
    textarea {
      border-color: $red !important;
      //box-shadow: inset 0px 0px 0px 1px $red;
      background-image: none;

      &:focus,
      &:focus-visible {
        box-shadow: inset 0px 0px 0px 2px $red;
        border-color: $red;

        // outline:none;
      }
    }

    &:after {
      content: "!";
      color: $red;
      position: absolute;
      top: 6px;
      right: 20px;
      font-size: 26px;
      font-weight: 700;
    }
  }

  &.is--valid {
    label{
      color:$green2;
    }
    input,
    textarea {
      border-color: $green2 !important;
      //box-shadow: inset 0px 0px 0px 1px $green;
      background-image: none;
      &:focus,
      &:focus-visible {
        box-shadow: inset 0px 0px 0px 2px $green2;
        border-color: $green2;
        // outline:none;
      }
    }

    &:after {
      content: "";
      transform: rotate(-45deg);
      border-left: 2px solid $green2;
      border-bottom: 2px solid $green2;
      position: absolute;
      top: 20px;
      right: 20px;
      display: block;
      width: 12px;
      height: 8px;
    }
  }

  @media(max-width: $grid-breakpoints-lg) {
    input,
    textarea {
      font-size: $font16;
    }
  }

}


.form__input--bold {
  label {
    font-size: $font18;
    color: $txt;
    font-weight: 700;
    margin-bottom: 10px;
  }

  &.is--valid:after {
    top: 52px;
  }

  &.is--invalid:after {
    top: 46px;
  }

}

.form__nolabel {
  &.is--valid {
    &:after {
      top: 15px;
    }
  }

  &.is--invalid {
    &:after {
      top: 7px;
    }
  }
}


.form__input--error {
  color: $red;
  font-size: $font10;
  display: block;
  margin-top: 5px;

}

.form__checkbox {
  .form__input--error {
    margin-bottom: 5px;

  }
}

.form__select + .form__input--error {
  position: relative;
  top: -20px;
}


.form__switch {
  position: relative;
  display: inline-block;
  white-space: nowrap;


  /* Hide default HTML checkbox */
  input {
    opacity: 0;
    width: 0;
    height: 0;
    position: absolute;
    z-index: -1;
  }

  label {
    padding-right: 60px;;
    line-height: 24px;
    display: inline-block;
    font-size: $font20;
    position: relative;
  }

  /* The slider */
  label:before {
    content: "";
    height: 26px;
    width: 50px;
    border-radius: 16px;
    position: absolute;
    cursor: pointer;
    top: 50%;
    transform: translateY(-14px);
    right: 0;
    bottom: 0;
    background-color: $bg-grey;
    -webkit-transition: .4s;
    transition: .4s;
    z-index: 2;
    cursor: pointer;
  }

  label:after {
    content: "";
    position: absolute;
    height: 20px;
    width: 20px;
    right: 26px;
    bottom: 3px;
    border-radius: 11px;
    background-color: #959595;
    -webkit-transition: .4s;
    transition: .4s;
    z-index: 3;
    cursor: pointer;
  }

  input:checked + label:before {
    background-color: $main;
  }

  input:checked + label:after {
    background: #fff;
  }

  input:checked + label:after {
    right: 3px;
  }

  /* Rounded sliders */

}


.cart__customer {
  display: flex;
  margin-bottom: 20px;
  margin-top: 20px;
  align-items: center;
  gap: 15px;
  font-size: $font14;


  .form__checkbox {
    margin: 0;

    label {
      font-size: $font16;
      font-weight: 700;
      border: none;
      min-height: auto;

      &:before {
        top: 50% !important;
        transform: translateY(-50%);
      }

      &:after {
        top: 50% !important;
        transform: translateY(-50%);
        left: 7px !important;
      }
    }

    // input[type=radio]:checked~label:after {
    //     left: 5x;
    // }
  }

  @media(max-width: 992px) {
    flex-wrap: wrap;
    gap: 10px;
    flex-direction: column;
    align-items: flex-start;
  }
}

.form-s {

  fieldset {
    margin-bottom: 30px;
  }

  fieldset {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    column-gap: 20px;
  }

  .form__input--25 {
    max-width: calc(50% - 15px)
  }

  .form__input,
  .form__select {
    width: 100%;
  }

  @media(min-width: 768px) {

    .form__input,
    .form__select {
      width: 100%;
    }


  }


  @media(min-width: 992px) {

    .form__input,
    .form__select,
    .form__checkbox--50 {
      flex: 0 0 50%;
      max-width: calc(50% - 10px)
    }

    .form__input--25 {
      max-width: calc(25% - 15px)
    }
  }
}

.fieldset,
.fieldset--border {
  .form__gus {
    @media(min-width: $grid-breakpoints-md) {
      margin-top: 25px;
    }
  }
}


.button__password {
  position: absolute;
  right: 1px;
  top: 8px;
  width: 40px;
  height: 38px;
  background-image: url("../img/eye.svg");
  background-size: 20px;
  background-position: center;
  background-repeat: no-repeat;
  border: none;
  z-index: 20;
  background-color: transparent;


  &:after {
    content: "";
    display: block;
    height: 1px;
    background: #ddd;
    transform: rotate(45deg);
    position: absolute;
    transition: all 0.3s ease-in-out;
    width: 20px;
    top: 19px;
    left: 10px;
  }

  &.show {
    &:after {
      top: 10px;
      left: 10px;
      width: 0px;
    }
  }

}

.form__password.form__input {
  &:after {
    content: none !important;
    opacity: 0;
  }
}


.btn-gnerate {

  background: $main;
  font-size: $font14;
  color: #fff;
  padding: 10px 15px;
  border-radius: 20px;
  border: none;
  white-space: nowrap;
  font-weight: 700;

}

.generate__score {
  transition: all 0.3s ease-out;
  max-height: 0px;
  overflow: hidden;
  display: flex;
  font-size: $font14;
  align-items: center;

  line-height: 1.2;
  gap: 10px;

  @media (min-width: $grid-breakpoints-xl) {
    gap: 20px;
  }


  @media (max-width: 410px) {
    font-size: $font12;
  }

  .generate__password {
    display: block;
  }

  &.open {
    max-height: 100px;
  }
}


#input-accept {
  border: none;
  background: none;
  color: $main;
  padding: 10px 0;
  font-size: $font14;
  font-weight: 700;
}

.generate__wrapper {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  display: flex;
  color: $txt;

  @media (min-width: $grid-breakpoints-lg) {
    margin-top: 10px;

  }

  @media (min-width: $grid-breakpoints-lg) {
    flex: 0 0 50%;
    max-width: calc(50% - 10px);
    align-items: flex-start;
  }


}

@media (max-width: 390px) {
  .generate__wrapper {
    flex-direction: column;
  }

  .generate__score .generate__password {
    display: inline-block;
    margin-left: 10px;
  }
}

.btn-date-trigger{
  position: absolute;
  top: 1px;
  right: 5px;
  width: 50px;
  height: 50px;
  background: rgba(0, 0, 0, 0);
  padding: 0;
  display: block;
  z-index: 10;
  border:none;

}

.form__input .datepicker{
  left:initial !important;
  right:0;
}