@import "components/typography";
@import "components/form";
@import "components/grid";
@import "components/header";
@import "components/footer";
@import "components/product-item";
@import "components/modal";

.starWrapper {
  display: block;
  width: 100px;
  background-image: url("../img/ico/ico-star-grey.svg");
  background-repeat: repeat-x;
  background-position: left;
  height: 20px;
  position: relative;

  span {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    background-image: url("../img/ico/ico-star.svg");
    background-repeat: repeat-x;
    background-position: left;
    height: 20px;

  }

}

.quick-access-nav {
  position: absolute;
  top: -1000px;
  width: 100%;
  z-index: 1000;

  ul {
    width: 100%;
  }

  li {
    position: absolute;
    width: 100%;

    a {
      display: block;
      margin: 0 auto;
      padding: 0.5em 3em;
      text-align: center;
      text-decoration: none;
      color: #fff;
      background-color: #000;
      outline: none;
      border: none;
      left: 50%;
      transform: translateX(-50%);

      &:focus,
      &:focus-within {
        position: absolute;
        top: 1000px;
        width: fit-content;
      }
    }
  }
}

.relative {
  position: relative;
}

.skip__link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding: 0 3em;
  text-align: center;
  text-decoration: none;
  color: #fff;
  background-color: #000;
  outline: none;
  border: none;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  height: 0;
  overflow: hidden;

  &:focus,
  &:focus-within {
    position: absolute;
    top: 0;
    width: fit-content;
    height: 50px;
  }
}

.bg__wrapper {
  position: relative;
  z-index: 1;
}

.bg__img {
  position: absolute;
  overflow: hidden;
  bottom: 0;
  left: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.section-txt-white {
  color: $white;
}

.boxDecor {
  padding-left: 50px;
  padding-top: 20px;
  padding-bottom: 20px;
  position: relative;
  min-height: 120px;
  margin-bottom: 40px;
  background-image: url("../img/line-orange.svg");
  background-repeat: repeat-y;
  background-position: left top;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;

  .title {
    margin-bottom: 0;
  }
}

.boxDecor--bottom {
  padding-left: 50px;
  margin-bottom: 40px;
}

.boxDecor--green {

  background-image: url("../img/line-green.svg");

}

.boxDecor--blue {
  background-image: url("../img/line-blue.svg");
}

@media(max-width: $grid-breakpoints-xl) {
  .boxDecor--bottom {
    padding-left: 40px;
  }
  .boxDecor {
    min-height: 100px;
    padding-left: 40px;
  }
}

@media(max-width: $grid-breakpoints-lg) {
  .boxDecor--bottom {
    padding-left: 0;
  }
}


@media(max-width: $grid-breakpoints-sm) {
  .boxDecor {
    min-height: initial;
    padding-left: 0px;
    background: none;
    margin-bottom: 10px;
    padding-top: 0;

    h1 {
      padding-left: 20px;
      background-image: url(../img/line-orange.svg);
      background-repeat: repeat-y;
      background-position: left top;
      padding-bottom: 40px;
      padding-top: 40px;
    }


    .hero-no-mobile {
      display: none;
    }
  }

  .boxDecor--blue {
    h1 {
      background-image: url("../img/line-blue.svg");
    }
  }

  .boxDecor--green {
    h1 {
      background-image: url("../img/line-green.svg");
    }
  }

  .heroProduct {
    .boxDecor {
      h1 {
        padding-bottom: 10px;
        padding-top: 10px;
      }
    }
  }
}

.swiper2__nav {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 50px;
  margin-top: 30px;
  width: 100%;

  .container {
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 50px;
  }

  .swiper-pagination-bullets.swiper-pagination {
    width: auto;
  }

  .swiper-button-prev, .swiper-button-next, .swiper-pagination {
    position: static;
    margin-top: 0;
  }

  .swiper-button-prev, .swiper-button-next {
    background-color: transparent;
    @media(hover: hover) {
      &:hover {
        background-color: $white;
      }
    }
  }

  .swiper-button-prev {
    background-image: url("../img/ico/ico-swiper-left.svg");
  }

  .swiper-button-next {
    background-image: url("../img/ico/ico-swiper-right.svg");
  }
}

.swiper {
  .swiper-pagination-bullet {
    width: 6px;
    height: 6px;
    display: inline-block;
    border-radius: 0;
    background: $txt;
    opacity: var(--swiper-pagination-bullet-inactive-opacity, 0.2);
  }

  .swiper-pagination-bullet-active {
    opacity: 1;
    background: $txt;
  }

  .swiper-pagination {
    display: flex;
    align-items: center;
    padding-right: 20px;
  }
}

.swiper-button-stop {
  width: 50px;
  height: 50px;
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("../img/ico/ico-swiper-stop.svg");
  transition: all .3s ease-out;
  @media(hover: hover) {
    &:hover {
      background-color: $white;
    }
  }

  &.swiper-button-play {
    background-image: url("../img/ico/ico-swiper-play.svg");
  }
}


.swiper-lazy:not(.swiper-lazy-loaded) {
  opacity: 0;
}

section {

  ::-webkit-scrollbar {
    height: 2px;
  }

  ::-webkit-scrollbar-track {
    background: $line;
    border-radius: 0px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: $txt;
    outline: 1px solid $txt-light;
    border-radius: 2px;
  }

  ::-webkit-scrollbar:horizontal {
    height: 2px;
  }

}

.section--top {
  padding-top: 60px;
  @media(max-width: $grid-breakpoints-md) {
    padding-top: 30px;
  }
}


.heroS {
  min-height: 350px;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;

  margin-top: 80px;
  color: #fff;
  background: #000;

  @media(max-width: $grid-breakpoints-sm) {
    margin-top: 60px;
  }
}

.heroS__title h1 {
  font-size: 37px;
  margin-bottom: 5px;
}

.heroProduct {
  margin-top: 0px;
  min-height: 800px;
  justify-content: flex-end;

  .heroS__imgWrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &:before {
      content: "";
      display: block;
      width: 100%;
      top: 50%;
      height: 50%;
      background-image: linear-gradient(rgba(45, 44, 42, 0), rgba(45, 44, 42, 1));
      z-index: 1;
      position: absolute;
    }
  }

  .heroS__img {
    opacity: 1;
    z-index: 0;
  }

  .heroS__container {
    padding-bottom: 0px;

  }

  .heroS__title {
    max-width: 800px;
  }

  @media(max-width: $grid-breakpoints-md) {
    min-height: 600px;
    overflow: hidden;
    .breadcrumbs {
      position: absolute;
      top: 80px;
      left: 20px;
      width: calc(100% - 20px);
    }

    &:before {
      content: "";
      display: block;
      width: 100%;
      top: 0;
      left: 0;
      height: 200px;
      background-image: linear-gradient(rgba(45, 44, 42, 1), rgba(45, 44, 42, 0));
      z-index: 1;
      position: absolute;

    }
  }

}


.heroS.heroS-small {
  min-height: 200px;

}


.heroS-mountains {
  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 50px;
    bottom: 0;
    left: 0;
    background-image: url("../img/header-mountains.svg");
    background-position: center;
    background-repeat: repeat-x;
    z-index: 1;
    position: absolute;

  }

  .boxDecor {
    margin-bottom: 0;
  }

  .heroS__container {
    padding-bottom: 50px;
  }
}

.heroS-list {
  &.heroS-mountains {
    min-height: 350px;

    .heroS__container {
      padding-bottom: 150px;
    }

    &:after {
      bottom: 0px;
    }
  }
}


.heroS__img {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
  opacity: 0.4;
}

.heroS__container {

  padding-top: 50px;
  padding-bottom: 90px;
  z-index: 20;

  //border-left: 1px solid $line-white;
  //padding-left: 0px;
  //padding-right: 0px;
  //width: calc(100% - 40px);
  .boxDecor {
    gap: 10px;
  }

  ::-webkit-scrollbar {
    height: 2px;
  }

  ::-webkit-scrollbar-track {
    background: #000;
    border-radius: 0px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #333;
    outline: 1px solid #333;
    border-radius: 2px;
  }

  ::-webkit-scrollbar:horizontal {
    height: 2px;
  }
}

.heroS__des {
  opacity: 0.8;
  font-size: $font14;
}


.hero__info {
  display: flex;
  align-items: center;
  gap: 5px;

  img {
    width: 35px;
  }

  //.ico {
  //  color: $black;
  //
  //
  //}

  .hero__time {
    padding-left: 15px;
    padding-right: 10px;
    border-left: 1px solid rgba(255, 255, 255, 0.3);
    border-right: 1px solid rgba(255, 255, 255, 0.3);
    //border-left: 1px solid rgba(0, 0, 0, 0.4);
    //border-right: 1px solid rgba(0, 05, 0, 0.4);
    white-space: nowrap;

    &.ico--time {
      padding-left: 26px;
      margin-left: 6px;

      &:before {
        left: 3px;
      }
    }
  }
}


@media(max-width: $grid-breakpoints-md) {
  .heroS__title h1 {
    font-size: 30px;
  }
}

@media(max-width: $grid-breakpoints-sm) {
  .heroS__title h1 {
    font-size: 24px;
  }
}


@media(min-width: $grid-breakpoints-lg) {


  .heroS {
    min-height: 710px;
  }
}


.accordion {
  img:not(:last-child) {
    max-width: 100%;
    margin-bottom: 10px;
  }

  p:not(:last-child) {
    margin-bottom: 10px;
  }
}

.accordion__content {

  //overflow: hidden;
  //transition: all 0.3s ease-out;
  //max-height: 10000px;

  &[aria-hidden='true'] {
    display: none;
  }

}

.accordion__btn:not(.btn) {
  border: none;
  width: 100%;
  display: block;
  position: relative;
  padding: 18px 50px 18px 20px;
  text-align: left;
  background: transparent;
  font-family: $font2-family-name;
  font-size: $font23;
  color: $black;
  background: $bg-grey2;

  &:after {
    content: "";
    width: 40px;
    height: 40px;
    display: block;
    background: url("../img/ico/ico-arrow-down-black.svg") no-repeat;
    background-position: center;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.3s ease-out;
    position: absolute;

  }

  &.active {
    &:after {
      transform: translateY(-50%) rotate(180deg);
    }
  }
}


.accordion__panel {
  padding-bottom: 10px;
  padding-top: 20px;

  img {
    width: 100%;
  }

  @media(min-width: $grid-breakpoints-sm) {
    img.w-50 {
      display: inline-block;
      width: calc(50% - 10px) !important;
      margin-bottom: 14px !important;

      &:not(:nth-child(even)) {
        margin-right: 10px;
      }
    }
  }
}

.accordion--small {
  font-size: 14px;

  .accordion__btn:not(.btn) {
    font-size: 16px;
    width: fit-content;
    padding-top: 7px;
    padding-bottom: 7px;
    padding-right: 20px;
    color: $txt-light;

    &:after {
      font-size: 1.7rem;
      top: 3px;
    }
  }
}


@import "components/tabs";

.tabs__nav-trigger {

  &[aria-selected="true"], &.active {
    color: #fff !important;

    img {
      filter: brightness(0) invert(1);
    }
  }
}

.accordion2__btn:not(.btn) {
  background: transparent;
  border: 0;
  width: 100%;
  display: block;
  position: relative;
  padding: 15px 25px 15px 0;
  text-align: left;
  background: 0 0;
  font-family: "Lexend Deca", sans-serif;
  font-size: 1.4375rem;
  color: $txt;

  &:after {
    content: "+";
    font-size: 2.5rem;
    position: absolute;
    right: 0;
    top: 6px;
    color: $txt-light;
    font-weight: 300;
    line-height: 1;
    background: none;
    transform: none !important;
    width: initial;
    height: initial;
  }

  &.active:after {
    content: "-" / "";
  }
}

.category__wrapper {
  margin-top: 30px;
}

.category__title {
  text-transform: uppercase;
  font-size: $font13;
  padding: 15px 10px;
  text-align: center;
  font-weight: 400;
  transition: color 0.3s ease-in-out;
  margin-bottom: 0;
  line-height: 1.2;
}


.category__figure {
  position: relative;
  height: 0;
  padding-top: 61%;
  border: 1px solid $line;


  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;

  }
}


.listProduct__wrapper {
  .category__link {
    background: $bg-grey;
  }
}

.category__link {
  display: block;
  text-align: center;
  @media(hover: hover) {
    &:hover,
    &.active {
      box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.15);
      background: $bg-grey;

      .category__title {
        color: $main;
      }
    }
  }
}

.category__item {
  margin-bottom: 16px;
}

.heart__wrapper {
  .hide {
    display: none;
  }
}


.similar {
  padding-top: 30px;
  padding-bottom: 30px;

  .product__brand {
    font-size: $font13;
  }

  .product__title {
    font-size: $font14;
    margin-bottom: 5px;
  }

  .product__priceOld {
    font-size: $font14;
  }

  .product__priceNew {
    font-size: $font14;
  }

  .product__figure {
    margin-bottom: 20px;
  }
}

.product__discount2 {
  width: 25px;
  height: 25px;
  background: $bg-grey;
  color: $txt;
  border-radius: 50%;
  z-index: 10;
  font-size: $font10;
  display: flex;
  justify-content: center;
  align-items: center;
}


.gallery__wrapper {
  display: grid;
  padding-left: 0 !important;
  list-style: none !important;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;

  @media(max-width: $grid-breakpoints-sm) {
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    grid-template-areas:
            "gallery1 gallery1 "
            "gallery2 gallery3 "
            "gallery4 gallery4 "
  }
}


.gallery__list {
  padding-left: 0 !important;
  list-style: none !important;
}

.gallery__link {
  height: 0;
  padding-top: 63%;
  position: relative;
  display: block;
  width: 100%;
  overflow: hidden;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    object-fit: cover;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
    transition: all .3s ease-out;
  }

  &:before {
    content: url(../img/ico/ico-search.svg);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1.5);
    z-index: -1;
    opacity: 0;
    transition: opacity 1s ease-out;
    margin-top: 5px;
  }

  @media(hover: hover) {
    &:hover {
      background: black;

      &:before {
        z-index: 12;
        opacity: 1;
      }

      img {
        transform: translate(-50%, -50%) scale(1.1);
        opacity: 0.7;
      }
    }
  }
}


.blog {
  background: $bg-grey;

}

.blog__list {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  margin-bottom: 30px;
  list-style: none !important;
  padding-left: 0 !important;
}


.blog__item {
  flex: 0 0 100%;
  background: #fff;
  padding-bottom: 10px;
  box-shadow: 0 6px 9px rgba(0, 0, 0, 0);
  transition: all 0.3s ease-out;
  overflow: hidden;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    bottom: 20px;
    right: 25px;
    transition: all 0.3s ease-out;
    width: 33px;
    height: 33px;
    background-image: url("../img/ico/ico-btn-arrow.svg");
    background-size: 20px;
    background-repeat: no-repeat;

  }

  a {
    display: block;
    height: 100%;
  }

  @media(hover: hover) {
    &:hover {
      color: $txt;
      box-shadow: 0 6px 9px rgba(0, 0, 0, .16);

      .blog__icons {
        &:after {
          background-image: url("../img/ico/arrow-blog-hover.svg");
        }
      }

      .blog__figure > img {
        transform: translate(-50%, -50%) scale(1.1);
      }
    }
  }
  @media(max-width: $grid-breakpoints-xl) {
    &:after {
      bottom: 10px;
      right: 15px;
    }
  }

}


.blog__figure {
  width: 100%;
  padding-top: 64%;
  position: relative;
  overflow: hidden;
  height: 0;
  margin-bottom: 30px;
  @media(max-width: $grid-breakpoints-sm) {
    margin-bottom: 20px;
  }

  > img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 2;
    transform: translate(-50%, -50%);
    transition: all 0.3s ease-out;
  }
}

.blog__title {
  font-size: $font18;
  margin-top: 10px;
  font-weight: 700;
  transition: all 0.3s ease-out;
  color: $txt;
  font-weight: 400;
}


.blog__txt {
  padding-left: 35px;
  padding-right: 35px;
  padding-bottom: 50px;
  @media(max-width: $grid-breakpoints-xl) {
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 40px;
  }
  @media(max-width: $grid-breakpoints-sm) {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 40px;
  }
}

.blog__data {
  color: #AAAAAA;
  position: relative;
  font-size: $font11;
  display: block;
  line-height: 1;
  margin-bottom: 15px;
}


.blog__summary {
  font-size: $font16;
  color: $txt;
}


@media(min-width: $grid-breakpoints-sm) {
  .blog__list {
    margin-bottom: 50px;
  }

  .blog__item {
    max-width: calc(50% - 15px);
    flex: 0 0 50%;
  }
}

@media(min-width: $grid-breakpoints-lg) {
  .blog__item {
    max-width: calc(33.33% - 20px);
    flex: 0 0 33.33%;
  }
}

@media(min-width: $grid-breakpoints-xl) {
  .blog__data {
    font-size: $font16;
    margin-bottom: 25px;
  }
}


.artpag__list {
  padding-top: 50px;
  padding-bottom: 90px;
  position: relative;
  gap: 30px;

  @media(min-width: $grid-breakpoints-lg) {
    display: flex;

    li:not(.artpag__last) {
      flex: 0 0 50%;
      max-width: calc(50% - 15px);
    }
  }
  @media(max-width: $grid-breakpoints-sm) {
    padding-top: 30px;
  }
}

.img--bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.img__wrapper {
  width: 100%;
  padding-top: 100%;
  position: relative;
  overflow: hidden;
  height: 0;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  img {
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.artpag__last {
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);

  @media(max-width: $grid-breakpoints-sm) {
    bottom: 30px;
  }

}

.artpag__link {
  display: block;

  strong {
    display: block;
    margin-bottom: 10px;
    font-size: 13px;
    color: $txt-light;
    font-weight: 400 !important;
    position: relative;

    &:after {
      content: "";
      width: 10px;
      height: 10px;
      border-left: 2px solid $txt-light;
      border-bottom: 2px solid $txt-light;
      position: absolute;
      display: block;
      top: 5px;
      transform-origin: center;
      border-radius: 2px;
    }
  }

  &.artpag__link--left {
    strong {
      padding-left: 13px;

      &:after {
        left: 0;
        transform: rotate(45deg);
      }
    }
  }

  &.artpag__link--right {
    strong {
      padding-right: 13px;

      &:after {
        right: 0;
        transform: rotate(-135deg);
      }
    }
  }

  margin-bottom: 30px;


  .img__wrapper {
    padding-top: 56px;
    max-width: 100px;
    min-width: 100px;
  }

  .date {
    font-size: $font12;
    margin-bottom: 5px;
    display: block;
    font-weight: 400;
  }

  .artpag__title {
    font-weight: 700;
    font-size: $font20;
    margin-bottom: 0px;
    color: $txt;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-family: $font2-family-name;
    transition: all 0.3s ease-out;
    line-height: 1.1;

    span {
      background-position: 0 80%;
    }
  }


  @media(hover: hover) {
    &:hover {
      .artpag__title {
        color: $main;
      }
    }
  }

  @media(min-width: $grid-breakpoints-lg) {

    gap: 30px;
    .date {
      margin-top: -5px;
    }
    .img__wrapper {
      padding-top: 78px;
      max-width: 140px;
      min-width: 140px;
    }
  }

  @media(max-width: $grid-breakpoints-sm) {
    .artpag__title {
      font-size: $font16;
    }
    .artpag__wrapper {
      gap: 10px;
      position: relative;
    }
    .artpag__txt {
      position: static;
      padding-bottom: 0;
      width: 100%;
    }

    .artpag__link strong {
      margin-bottom: 5px;
    }

  }
}

.artpag__wrapper {
  display: flex;
  align-items: center;
  gap: 20px;
  transition: background 0.3s ease-out;

  img {
    border-radius: 6px;
  }
}

.artpag__txt {

  position: relative;
  width: 100%;

  svg {
    position: absolute;
    transition: all 0.3s ease-out;
    bottom: 10px;
  }

}

.artpag__link--right {
  margin-left: auto;
  text-align: right;

  svg {
    right: 0;
  }
}

.artpag__link--left {
  margin-right: auto;

  svg {
    left: 0;
  }

}

.article__intro {
  margin-top: 30px;
  margin-bottom: 20px;
  font-size: $font20;
  font-weight: 700;
}


.article__imggroup {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  padding-top: 20px;
  padding-bottom: 20px;

  img {
    max-width: calc(25% - 9px);
    flex: 0 0 33.33%;
    margin: 0;
  }

  @media(max-width: $grid-breakpoints-md) {
    gap: 10px;

    img {
      max-width: calc(33.33% - 10px);
      flex: 0 0 33.33%;
    }
  }

  @media(max-width: $grid-breakpoints-md) {
    gap: 10px;

    img {
      max-width: 100%;
      flex: 0 0 100%;
    }
  }
}


.ico2 {
  width: 18px;
  height: 18px;
  display: inline-block;
  background: url("../img/ico/ico-ostatnie-miejsca-s.svg") no-repeat center;
  background-size: 100% !important;
  @media(min-width: $grid-breakpoints-md) {
    width: 34px;
    height: 34px;
  }
}

.ico2--last {
  background-image: url("../img/ico/ico-ostatnie-miejsca-s.svg");
}

.ico2--important {
  background-image: url("../img/ico/ico-wykrzyknik.svg");
}


.ico {
  display: block;
  position: relative;
  padding-left: 24px;
  min-height: 15px;
  color: #fff;
  font-size: $font20;
  font-family: $font2-family-name;
  line-height: 1.1;


  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 22px;
    height: 22px;
    background: url("../img/ico/ico-calendar.svg") no-repeat center;
    background-size: contain;
  }

  @media(min-width: $grid-breakpoints-lg) {
    font-size: $font27;
    //padding-left: 35px;
    min-height: 30px;
    &:before {
      width: 30px;
      height: 30px;
    }
  }
}

.ico--date {
  &:before {
    background: url("../img/ico/ico-calendar.svg") no-repeat center;
  }
}

.ico--destination {
  &:before {
    background: url("../img/ico/ico-kraj.svg") no-repeat center;
  }
}

.ico--time {
  &:before {
    background: url("../img/ico/ico-time.svg") no-repeat center;
  }
}


.ico3 {
  display: inline-flex;
  position: relative;
  padding-left: 25px;
  min-height: 48px;
  color: #fff;
  font-size: $font16;
  line-height: 48px;
  white-space: nowrap;

  &:before {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 100%;
    background: url("../img/ico/ico-phone.svg") no-repeat center;
  }

  @media(hover: hover) {
    &:hover {
      color: $main;
    }
  }
}

.ico--tel {
  &:before {
    background: url("../img/ico/ico-phone.svg") no-repeat center;
  }
}

.ico--mail {
  &:before {
    background: url("../img/ico/ico-mail.svg") no-repeat center;
  }
}


.advantages__section {
  padding-top: 40px;

}

.advantages__row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  padding-bottom: 10px;
}

.advantages__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: flex-end;
  margin-bottom: 30px;
  gap: 20px;
  flex: 0 0 50%;
  max-width: 50%;

  img {
    max-width: 90%;
  }
}

.advantages__title {
  font-size: $font18;

  span {
    color: $green;
    display: block;
    font-size: $font23;
  }
}

@media(min-width: $grid-breakpoints-lg) {
  .advantages__title {
    font-size: $font20;

    span {
      font-size: $font25;
    }
  }
}

@media(min-width: $grid-breakpoints-lg) {
  .advantages__item {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
}

@media(min-width: $grid-breakpoints-xl) {
  .advantages__item {
    flex: 0 0 20%;
    max-width: 20%;
    gap: 30px;
    margin-bottom: 0;
  }
}


.searchM__section {
  z-index: 42;
  position: relative;
  margin-bottom: 20px;

  background: rgba(0, 0, 0, 0.6);
  width: 100%;

  .btn {
    font-family: $font2-family-name;
  }

  @media(max-width: $grid-breakpoints-lg){
    background-color: $txt;
  }
}


.searchM__container {

  padding: 20px;
  width: 100%;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  max-width: 1100px;

  @media(max-width: $grid-breakpoints-lg) {

    .searchM__btn {
      border-color: $black;
    }

  }
}

.searchM__box {
  display: flex;
  gap: 6px;
  justify-content: center;
  flex-wrap: wrap;

}

.searchM__btnList {
  display: none;
}

.searchM__panel {
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  background: #fff;
  z-index: 30;
  width: 100%;
  box-shadow: 0px 7px 12px 0px rgba(0, 0, 0, .16);
  display: none;
  overflow: hidden;


  &.show {
    //display: flex;
    height: auto;
    //flex-direction: column;
    //justify-content: flex-end;
  }
}


.searchM__btn {
  width: 100%;
  text-transform: initial;
  text-align: left;
  position: relative;
  border: 1px solid #414141;
  padding-right: 50px;
  padding-left: 25px;
  padding-top: 15px;
  padding-bottom: 15px;
  color: $white;
  font-size: $font16;
  min-height: 60px;
  font-weight: 700;
  background: transparent;

  &:after {
    content: "";
    width: 12px;
    height: 12px;
    border-left: 3px solid $white;
    border-bottom: 3px solid $white;
    position: absolute;
    display: block;
    top: 50%;
    right: 20px;
    transform-origin: center;
    transform: translateY(-50%) rotate(-45deg);
    margin-top: -3px;
  }

  @media(hover: hover) {
    &:hover {
      background: $main;
      color: #fff !important;

      &:after {
        border-color: #fff;
      }
    }
  }

  &[aria-expanded="true"] {
    background: #fff;
    color: $black !important;

    &:after {
      border-color: $black;
    }

    &:before {
      content: "";
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 8px solid rgba(255, 255, 255, 1);
      position: absolute;
      right: 18px;
      top: 105%;
    }
  }

}

.searchM__fieldset {
  width: 100%;
}

.searchM__btn2 {
  background: $main;
  min-width: 170px;
  margin-top: 20px;
  color: #fff !important;
  padding: 15px 20px;
  font-size: 20px;
  justify-content: center;

  &:before {
    content: url("../img/ico/ico-search.svg");
    margin-right: 10px;
    position: relative;
    top: 3px;
  }

  @media(hover: hover) {
    &:hover {
      background: $main-hover;
    }
  }
}

.searchM__wrapperCC {
  padding: 30px;
}

.searchM__continent {
  width: 280px;
  padding-right: 30px;
  border-right: 1px solid $line;

  label {
    font-weight: 700;
    font-size: $font18;
    padding-top: 3px;
    padding-bottom: 3px;
    margin-bottom: 15px;
  }
}


#searchMDirection {
  position: absolute;
  left: 0px;
  top: 0px;
  width: calc(100% - 130px);
}

.searchM__countryWrapper {
  overflow: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  max-height: 90%;

  .form__checkbox label {
    font-size: 16px;
    padding-left: 28px;

    &:before {
      width: 20px;
      height: 20px;
    }

    &:after {
      top: 10px;
      left: 5px;
    }
  }
}

.searchM__country {
  position: absolute;
  top: 30px;
  height: calc(100% - 60px);
  left: 350px;
  width: calc(100% - 400px);
  padding-top: 70px;


  .form__checkbox--hide {
    display: none;
  }

  label {
    small {
      color: $txt-light;
      font-size: 80%;
    }
  }

}

#searchMDirectionClear {
  position: absolute;
  right: 0px;
  top: 4px;
  color: $red;
  font-size: $font16;
  background: none;
  border: none;
  font-weight: 700;
  padding: 10px 25px 10px 10px;

  &:after {
    content: "+";
    display: inline-block;
    right: 0;
    top: 0px;
    font-weight: 300;
    transform: rotate(45deg);
    position: absolute;
    font-size: 30px;
  }
}

.searchM__fieldset {
  position: relative;
}

.searchM__options {
  transition: max-height 0.3s ease-out;
}

.searchM__options--wide {
  max-width: 1084px;
  width: calc(100vw - 60px);
  left: 0;
}

.searchM__options--small {
  width: 280px;
  left: initial;
  right: 0;

  //height: 350px;

  &.active {
    max-height: 350px !important;
  }


  .overflow-auto {
    max-height: 340px;
    margin-top: 5px;
    overflow: auto;
    width: calc(100% - 10px);
  }

  .searchM__wrapper {
    padding: 20px 10px 20px 20px;

    label {
      font-weight: 700;
      padding-top: 8px;
      padding-bottom: 8px;
      padding-left: 44px;

      transition: all 0.3s ease-out;

      &:before {
        left: 10px;
        top: 8px;
      }

      &:after {
        left: 16px;
        top: 15px;
      }

      @media(hover: hover) {
        &:hover {
          background: #f3f3f3;;
        }
      }
    }
  }
}


.searchM__mobile, .searchM__mobileTitle, .searchM__btnTab {
  display: none;
}


@media(min-width: $grid-breakpoints-sm) {
  .searchM__fieldset {
    max-width: 45%;
    flex: 0 0 45%;
    justify-content: space-between;

  }
  .searchM__btn2 {
    margin-top: 0px;
    width: 45%;
  }

}


@media(min-width: $grid-breakpoints-lg) {
  .searchM__box {
    flex-wrap: nowrap;
    gap: 15px;
  }
  .searchM__btn2 {
    width: auto;
  }
  .searchM__fieldset {
    flex: 1 1 auto;
  }
  .searchM__container {
    border-radius: 12px;
    padding: 8px;

  }
  .searchM__options--small {
    right: 0px;
    left: initial;
  }
  .searchM__section {
    margin-top: -76px;
  }
  .heroS-list.heroS-mountains:after {
    bottom: 76px;
  }
}
@media(min-width: $grid-breakpoints-xl) {
  .searchM__btn2 {
    min-width: 180px;
  }
}


@media(max-width: $grid-breakpoints-lg) {
  .searchM__countryWrapper {
    grid-template-columns: 1fr 1fr;
  }
  .searchM__options--wide {
    left: -10%;
  }
  .searchM__continent {
    width: 250px;
    padding-right: 20px;
  }
  .searchM__country {
    left: 300px;
    width: calc(100% - 320px);
  }
}

@media(max-width: $grid-breakpoints-lg) {
  .searchM__section.container {
    padding-left: 0px;
    padding-right: 0px;
    max-width: 100%;
  }
}


@media(max-width: $grid-breakpoints-md) {
  .accordion-active {
    overflow: hidden;
    height: 100vh;
    position: fixed;

    .header {
      z-index: 30;
    }
  }
  .searchM__wrapperCC {
    padding: 10px 25px 30px 25px;

  }

  .searchM__options--small {
    .searchM__mobileTitle {
      padding: 10px 25px 10px 25px;
      border-bottom: 2px solid $line;
    }

    .searchM__wrapper {
      padding-top: 0px;
      margin-top: 15px;
    }

    .overflow-auto {
      height: calc(100% - 180px);
      padding-top: 0;
      position: absolute;
      left: 0;
      top: 70px;
      z-index: 10;
      max-height: 100%;

    }
  }

  .searchM__btnTab {
    position: absolute;
    bottom: 22px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 100;
    display: none;

  }

  .searchM__options {
    transition: none;

    &.active {
      .searchM__btnTab {
        display: block;
      }

    }
  }

  .searchM__mobileTab {
    display: flex;
    width: 100vw;
    position: relative;
    left: -25px;
    margin-top: 5px;
    margin-bottom: 15px;
  }
  .searchM__btnT {
    height: 50px;
    background: $bg-grey;
    color: $txt-light;
    text-align: center;
    font-family: $font2-family-name;
    width: 50%;
    border: none;
    position: relative;
    border-radius: 0;
    font-size: $font20;
    z-index: 10;


    &.active {
      background: #000;
      color: #fff;

      &:before {
        content: "";
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 8px solid #000;
        position: absolute;
        left: calc(50% - 4px);
        top: 100%;
      }
    }
  }

  .searchM__options.active {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    border-radius: 0;
    z-index: 10000;
    max-height: 100vh !important;
    height: 100vh !important;
    height: -webkit-fill-available !important;
    height: stretch !important;
    transition: none;

  }

  .searchM__mobile {
    display: block;

  }
  .searchM__mobileTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: $font20;
    font-family: $font2-family-name;
    position: relative;
    z-index: 2;
  }

  .searchM__mobileClose {
    font-size: 50px;
    min-width: 48px;
    height: 48px;
    background: none;
    border: 0;
    transform: rotate(45deg);
    color: #000;
    position: relative;
    left: 15px;
    top: -6px;


  }

  #searchMDirection {
    left: 25px;
    width: calc(100% - 145px);
    top: 0px;
  }

  #searchMDirectionClear {
    top: 10px;
    right: 20px;
  }

  .searchM__countryWrapper {
    max-height: calc(100% - 135px);
  }

  .searchM__continent {
    width: 100%;
    padding: 0;
    height: 100%;
    position: absolute;
    left: 25px;
    top: 125px;
    opacity: 1;
    background: #fff;
    border: none;
    overflow: auto;
    max-height: calc(100% - 210px);
    width: calc(100% - 50px);
    z-index: -1;
    opacity: 0;

    &.active {
      z-index: 10;
      opacity: 1;
    }

  }
  .searchM__country {
    background: #fff;
    width: 100%;
    max-width: 100%;
    left: 0;
    top: 125px;
    padding: 70px 25px 30px 25px;
    z-index: -1;
    opacity: 0;

    &.active {
      z-index: 10;
      opacity: 1;
    }

  }
}

@media(max-width: $grid-breakpoints-sm) {
  .searchM__btn {
    padding-bottom: 15px;
    padding-top: 15px;
  }
  .searchM__btn2 {
    margin-top: 5px;
    padding-bottom: 15px;
    padding-top: 15px;
  }
}

.why__section {
  background-image: url("../img/dh-decor-bg.svg");
  overflow: hidden;
  position: relative;
  z-index: 1;
  padding-top: 20px;
  background-position: bottom;


  .title3 {
    //padding-top: 50px;
    //border-top: 2px solid $line;
    padding-top: 30px;
  }

  padding-bottom: 40px;
}

.why__decor {
  position: absolute;
  top: 0px;
  left: 50%;
  height: 100%;
  width: auto;
}

.why__txt {
  max-width: 650px;

  strong {
    display: block;
    margin-top: 25px;
  }
}

.why__icons {
  display: flex;
  gap: 20px;
  padding-bottom: 40px;
  position: relative;
  margin-bottom: 30px;
  margin-top: 50px;

  &:after {
    content: "";
    height: 8px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(to right, $yellow 0%, $yellow 10%, $brown 19%, $brown 28%,
            $red 37%, $red 46%, $blue 55%, $blue 64%, $violet 73%, $violet 82%, $green 90%, $green 100%);
  }
}

.why__item {
  strong {
    font-size: $font50;
    display: block;
    color: $green;
    font-weight: 400;
    font-family: $font2-family-name;
  }
}

@media(min-width: $grid-breakpoints-xl) {
  .why__icons {
    gap: 40px;

    img {
      width: auto;
      height: 40px;
    }
  }
}

@media(min-width: $grid-breakpoints-md) {
  .why__icons {
    justify-content: flex-end;
    margin-top: -25px;
    margin-bottom: 40px;
  }
}


@media(max-width: $grid-breakpoints-sm) {
  .advantages__section, .why__section, .ueInfo {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.guide__link {
  display: flex;
  width: 100%;
  position: relative;
  color: $txt;
  background: $bg-grey2;

  @media(hover: hover) {
    &:hover {
      color: $txt;

      .guide__img {
        transform: translate(-50%, -50%) scale(1.1);
      }
    }
  }
}

.guide__item--hide {
  opacity: 0;
  max-width: 0 !important;
  overflow: hidden;
  visibility: hidden;
  z-index: -1000;
  padding: 0 !important;

}

.guide2__item {
  transition: max-width .2s ease-out, .1s ease-out;
}

.guide__figure {
  position: relative;
  flex: 0 0 140px;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
}

.guide__img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
  transition: all .3s ease-out;
}

.guide__title {
  font-weight: 700;
  margin-bottom: 12px;
  font-size: 20px;
}


.quide__info {
  padding: 20px 10px 20px 25px;
  min-height: 140px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.guide__icons--front {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 15;
}

.guide2__hover {
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: #F4F4F4;
  z-index: 20;
  padding: 20px;
  color: $txt;
  transition: all 0.3s ease-out;
  overflow: hidden;
}

.guide__figure2 {
  display: flex;
  gap: 15px;
  align-items: center;

  img {
    min-width: 90px;
    max-width: 90px;
    height: 90px;
    object-fit: cover;
    border-radius: 50%;
  }

}

.guide__btn {
  font-size: $font14;
  border-radius: 8px;
  display: block;
  line-height: 1;
  padding: 12px 12px 12px 30px;
  position: relative;
  color: $txt;
  text-transform: uppercase;
  font-weight: 700;
  transition: all 0.3s ease-out;
  background: $bg-grey;

  &:before {
    display: block;
    content: "";
    width: 14px;
    height: 100%;
    background: url("../img/ico/ico-i.svg") center no-repeat;
    background-size: 14px;
    position: absolute;
    left: 10px;
    top: 0;
  }

  &:hover {
    background: #000;
    color: #fff;

    &:before {
      filter: brightness(0) invert(1);
    }

  }
}

.guide__des {
  margin-top: 20px;
  display: -webkit-box;
  width: 100%;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.guide__bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  position: absolute;
  bottom: 0;
  left: 20px;
  width: calc(100% - 40px);
  padding: 20px 0;
  border-top: 1px solid $line;

}

.guide2__title {
  margin-bottom: 0;
}

.guide2__link {
  &:hover {
    .guide2__hover {
      left: 0;
    }
  }
}


@media(max-width: $grid-breakpoints-md) {

  .guide__figure {
    flex: 0 0 100px;
  }
  .guide__title {
    font-size: 16px;
  }

  .quide__info {
    font-size: 14px;
    padding: 15px 10px 15px 20px;
    min-height: 100px;
  }
}


.slice__contactOuter {

}

.slice__contact {

  p {
    color: $txt-light;
  }

  background: #F2F7F8;
  padding: 30px;
  column-gap: 10px;

  .ico3 {
    color: #000;
    font-size: $font20;
    font-weight: 700;
    display: block;
    min-height: 30px;
    line-height: 30px;
  }

  .ico--mail {
    font-size: 16px;
  }
  @media(max-width: $grid-breakpoints-sm){
    padding:20px;
  }
}

.slice__h {
  font-size: 10px;
  padding-left: 25px;

}

.slice__telForm {
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 15px;

  input {
    height: 50px;
    box-sizing: border-box;
    border: 2px solid $line2;
    border-right: none;
    background-image: url("../img/ico/ico-phone.svg");
    background-position: left 20px center;
    padding: 9px 0 9px 50px;

    background-repeat: no-repeat;
    width: calc(100% - 50px);

    &:focus-visible {
      border-color: $txt;
      box-shadow: inset 0 0 0 1px $txt;
    }
  }

  .btn {
    padding: 0;
    width: 50px;
    background-position: center;
  }
}

.subHero {
  background: $bg-grey2;

  padding-bottom: 40px;
  margin-bottom: 30px;
  font-size: $font18;

  p {
    max-width: 950px;
  }

  h2 {
    color: $violet;
  }

  img {
    border-radius: 12px;
  }

}

.subHero__p {
  padding: 0 40px;
  margin: 0 auto;
  text-align: center;
}


.paymentsUrl {
  border: 1px solid $line;
  box-shadow: 0 6px 9px rgba(0, 0, 0, 0.16);
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 30px;

  h2 {
    padding-top: 5px;


    font-size: $font28;

    strong {
      white-space: nowrap;
      color: $green;
    }
  }
}

.paymentsUrl__list {

  p {
    margin-bottom: 5px;
  }
}

.paymentsUrl__item {
  border-radius: 12px;
  background: $bg-grey;
  padding: 30px;

  &.noborder {
    border: none;
    background: transparent;
    padding: 20px;
  }

  &.border-main {
    border: 1px solid $main;
  }
}

.paymentsUrl__title {
  font-size: 20px;
  font-family: $font2-family-name;
}

.copy-bank-account {
  display: inline-block;
  font-size: 12px;
  color: #fff;
  background: $txt;
  border-radius: 12px;
  line-height: 22px;
  padding: 0 12px 0 26px;
  position: relative;
  font-weight: 700;
  background-image: url(../img/ico-copy.svg);
  background-position: left 10px center;
  background-repeat: no-repeat;
  background-size: 12px;
  border: none;
}

.copy-to-clipboard-info {
  position: absolute;
  top: 120%;
  right: -10px;
  background: #fff;
  border-radius: 10px;
  padding: 5px 10px;
  font-size: 10px;
  display: block;
  white-space: nowrap;
  z-index: 20;
  color: $txt-light;
  line-height: 1;
  font-weight: 400;
  width: auto !important;
  box-shadow: 2px 2px 15px 0 rgb(0, 0, 0, 0.25);

  &:after {
    position: absolute;
    right: 20px;
    top: -4px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #fff;
    content: "";
    display: block;
  }

}

.item-bank-account {
  display: flex;
  align-items: center;
  gap: 5px;
  flex-wrap: wrap;
  margin-bottom: 5px;
  justify-content: center;
}


.term__label2 .btn {
  background: $main;
  padding: 12px 15px;

  font-size: 16px;
  font-family: $font2-family-name;
  font-weight: 400;
  margin-top: 15px;
  margin-bottom: 5px;

  @media(hover: hover) {
    &:hover {
      background: $black;
    }
  }
}

.property .detailsMap {
  display: none;
  color: #fff;
  background: #000;
  padding: 5px 10px;
  border-radius: 2px;
  position: absolute;
  top: 100%;
  left: 50%;
  font-size: 15px;
  transform: translateX(-50%);
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.3);
  white-space: nowrap;
}

.property .icon img {
  filter: drop-shadow(2px 2px 2px rgb(0 0 0 / 0.3));
}

.highlight .detailsMap {
  display: block;
}


.mpl {
  padding-bottom: 40px;
}

.mpl__list {
  margin-bottom: 10px;
}

.mpl__item {
  padding-top: 75%;
  position: relative;
  color: $white;
  display: block;
  overflow: hidden;

  h3 {
    font-size: 18px;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 25px 10px 25px 25px;
    width: 100%;
    margin-bottom: 0;
    z-index: 3;
  }

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: cover;
    width: 100%;
    height: 100%;
    z-index: 0;
    transition: all 0.3s ease-out;
  }
}

a.mpl__item {
  @media(hover: hover) {
    &:hover {
      color: $white;
      box-shadow: 0 6px 9px rgba(0, 0, 0, .16);

      img {
        transform: translate(-50%, -50%) scale(1.1);
      }

    }
  }
}

.mpl__list--shadow {
  a:before {
    display: block;
    content: "";
    width: 100%;
    height: 80%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75));

  }
}


.wideo__wrapper {
  position: relative;
  padding-top: 56%;

  iframe, video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

}

.wideo__outer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  .wideo__item {
    width: 100%;
  }


  @media(min-width: $grid-breakpoints-sm) {
    .wideo__item:not(:first-child) {
      max-width: calc(50% - 10px);
    }
  }
}

.fullWidth__outer {
  padding-left: 60px !important;

  @media(max-width: $grid-breakpoints-lg) {
    padding-left: 20px !important;

    .swiper-button-next, .swiper-button-prev {
      opacity: 0;
      z-index: -1;
      width: 0;
      height: 0;
      overflow: hidden;
    }
  }
}


.fullWidth__wrapper {
  position: relative;
  padding-top: 40px;
  padding-bottom: 40px;

  .swiper-slide.swiper-slide-active + .swiper-slide + .swiper-slide + .swiper-slide {
    opacity: 0.4;
  }


  .swiper-button-next, .swiper-button-prev {
    top: 40px;
    left: initial;

    &:after {
      border: none;
      transform: none;
      width: 26px;
      height: 26px;
      top: 0;
      left: 0;
      transform: none;
      background-size: contain;

    }
  }

  .swiper-button-prev {
    right: 80px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:after {
      background: url("../img/ico/arrow-left-black.svg") center center no-repeat;
      background-size: contain;
      position: static;
    }
  }

  .swiper-button-next {
    right: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:after {
      background: url("../img/ico/arrow-right-black.svg") center center no-repeat;
      background-size: contain;
      position: static;
    }
  }

}

@media(max-width: $grid-breakpoints-sm) {
  .fullWidth__wrapper {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
