@charset "UTF-8";
html {
  font-size: 16px;
  scroll-behavior: smooth;
}

/* lexend-deca-regular - latin_latin-ext */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Lexend Deca";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/lexend-deca-v21-latin_latin-ext-regular.woff2") format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* lexend-deca-500 - latin_latin-ext */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Lexend Deca";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/lexend-deca-v21-latin_latin-ext-500.woff2") format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* lexend-deca-600 - latin_latin-ext */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Lexend Deca";
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/lexend-deca-v21-latin_latin-ext-600.woff2") format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* lexend-deca-700 - latin_latin-ext */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Lexend Deca";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/lexend-deca-v21-latin_latin-ext-700.woff2") format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* lexend-deca-800 - latin_latin-ext */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Lexend Deca";
  font-style: normal;
  font-weight: 800;
  src: url("../fonts/lexend-deca-v21-latin_latin-ext-800.woff2") format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* lexend-deca-900 - latin_latin-ext */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Lexend Deca";
  font-style: normal;
  font-weight: 900;
  src: url("../fonts/lexend-deca-v21-latin_latin-ext-900.woff2") format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Merriweather";
  font-style: italic;
  font-weight: 400;
  src: url("../fonts/merriweather-v30-latin_latin-ext-italic.woff2") format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
tt,
var,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
figure,
figcaption,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-weight: inherit;
  font-style: inherit;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

img {
  vertical-align: middle;
}

/* tables still need 'cellspacing="0"' in the markup */
table {
  border-collapse: separate;
  border-spacing: 0;
  color: #000000;
  -moz-text-size-adjust: none;
       text-size-adjust: none;
  -webkit-text-size-adjust: none;
}

caption,
th,
td {
  text-align: left;
  font-weight: normal;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

iframe,
video {
  max-width: 100%;
  width: 100%;
  vertical-align: middle;
}

strong {
  font-weight: 700;
}

.bold {
  font-weight: 700 !important;
}

.semi-bold {
  font-weight: 500 !important;
}

.font-weight-400 {
  font-weight: 400 !important;
}

.font--12 {
  font-size: 0.75rem !important;
}

.font--14 {
  font-size: 0.875rem !important;
}

.font--16 {
  font-size: 1rem !important;
}

.font-family-1 {
  font-family: "Lexend Deca", sans-serif !important;
}

.font--18 {
  font-size: 1.125rem !important;
}

.font--20 {
  font-size: 1.25rem !important;
}

.main {
  color: #F46B37 !important;
}

.grey {
  color: #767676 !important;
}

.black {
  color: #000 !important;
}

.red {
  color: #D83F40 !important;
}

.green {
  color: #00AE50 !important;
}

.blue {
  color: #428CC3 !important;
}

.violet {
  color: #505184 !important;
}

.yellow {
  color: #FFB534 !important;
}

.brown {
  color: #995348 !important;
}

.bg--light {
  background-color: #F3F3F3;
}

.bg--grey {
  background-color: #EDEDED;
}

.bg--dark {
  background-color: #000000;
}

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: left !important;
}

input,
textarea,
select {
  border: 1px solid #D6D6D6;
  line-height: 1.6;
  padding: 10px 15px;
  color: #000;
  font-family: "Lexend Deca", sans-serif;
  font-weight: 400;
  font-size: 1.125rem;
}

::-moz-placeholder {
  color: #666;
  font-weight: 400;
  font-family: "Lexend Deca", sans-serif;
}

:-ms-input-placeholder {
  color: #666;
  font-weight: 400;
  font-family: "Lexend Deca", sans-serif;
}

::placeholder {
  color: #666;
  font-weight: 400;
  font-family: "Lexend Deca", sans-serif;
}

input:not([type=date], [type=number]),
textarea,
button {
  -webkit-appearance: none;
  cursor: pointer;
}

button {
  padding: 0;
}

/* remember to define focus styles! */
:focus {
  outline: none;
}

input:focus-visible, textarea:focus-visible {
  outline: none;
  box-shadow: inset 0px 0px 0px 1px #00AE50;
  border-color: #00AE50;
}

a,
button {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
a:focus-visible,
button:focus-visible {
  outline: 2px solid #F46B37;
  outline-offset: 1px;
}

a {
  color: #F46B37;
  text-decoration: none;
}
@media (hover: hover) {
  a:hover {
    color: #f55b1f;
  }
}
a:focus-visible {
  outline-offset: 2px;
}

body {
  line-height: 1.5;
  font-size: 1rem;
  font-family: "Lexend Deca", sans-serif;
  font-weight: 400;
  color: #000000;
  font-style: normal;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  font-family: "Lexend Deca", sans-serif;
}

em {
  font-style: italic;
}

h1,
h2,
h3 {
  font-family: "Lexend Deca", sans-serif;
  line-height: 1.2;
  letter-spacing: initial;
  font-weight: 700;
}
h1 a,
h2 a,
h3 a {
  color: inherit;
}

h1,
.h1 {
  font-size: 2.5rem;
  margin-bottom: 30px;
  font-weight: 700;
}

h2 {
  font-size: 2.125rem;
  margin-bottom: 25px;
  font-family: "Lexend Deca", sans-serif;
  font-weight: 700;
}

h3 {
  font-size: 1rem;
  margin-bottom: 15px;
}

.title {
  margin-bottom: 30px;
  font-size: 2.3125rem;
  font-weight: 700;
  position: relative;
}

.title-s {
  margin-bottom: 15px;
  font-size: 1.25rem;
  font-weight: 700;
  display: block;
}

.title-quote:after {
  content: url("../img/ico/ico-quote.svg");
  position: relative;
  top: -17px;
  left: 5px;
}

.title2 {
  text-align: center;
  font-size: 2.125rem;
  margin-bottom: 45px;
}
.title2 span {
  display: block;
  font-size: 1.125rem;
  color: #00AE50;
  font-family: Georgia;
  font-style: italic;
  margin-bottom: 7px;
}

.title2--w {
  color: #fff;
}
.title2--w span {
  color: #fff;
}

.title2--blue {
  color: #fff;
  margin-bottom: 35px;
}
.title2--blue span {
  color: #428CC3;
}

.title--4 {
  font-family: "Lexend Deca", sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
}

.title-f1 {
  font-family: "Lexend Deca", sans-serif;
  font-weight: 700;
  font-size: 1.5rem;
}

h4,
h5,
h6 {
  line-height: 1.5;
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 10px;
}

.h--main {
  margin-bottom: 25px;
  color: #F46B37;
  font-size: 1.875rem;
}

.h--main2 {
  margin-bottom: 25px;
  color: #F46B37 !important;
  font-size: 1.75rem !important;
}

p,
ul,
ol {
  line-height: 1.6;
}

ul,
ol {
  list-style: none;
}

.description {
  margin-bottom: 40px;
  max-width: 1000px;
}

@media (max-width: 1200px) {
  .title {
    font-size: 2.125rem;
  }
}
@media (max-width: 768px) {
  .title {
    font-size: 1.875rem;
  }
}
@media (max-width: 576px) {
  .title {
    font-size: 1.5rem;
  }

  .title--4 {
    font-size: 20px;
  }
}
.page__txt h1,
.page__txt .title--2 {
  font-size: 2.5rem;
}
.page__txt h2 {
  font-size: 2.125rem;
  margin-bottom: 15px;
}
.page__txt h2:not(:first-child) {
  margin-top: 30px;
}
.page__txt h3 {
  font-size: 1.625rem;
  margin-top: 30px;
}
.page__txt ul,
.page__txt ol {
  list-style: initial;
  padding-left: 30px;
}
.page__txt ol {
  list-style: decimal;
}
.page__txt ol ul {
  list-style: lower-alpha;
}
.page__txt ol,
.page__txt ul,
.page__txt p {
  margin-bottom: 15px;
}
.page__txt li {
  margin-bottom: 5px;
}
.page__txt a {
  text-decoration: underline;
}
@media (max-width: 576px) {
  .page__txt h1,
.page__txt .title--2 {
    font-size: 1.875rem;
  }
  .page__txt h2 {
    font-size: 2.125rem;
  }
  .page__txt h3 {
    font-size: 1.375rem;
  }
}

a,
span,
strong {
  line-height: inherit;
}

.link--underline {
  text-decoration: underline;
  color: #000000;
  border: none;
  background: none;
}
@media (hover: hover) {
  .link--underline:hover {
    color: #F46B37;
  }
}

.link--underline-main {
  text-decoration: underline;
  color: #F46B37;
}
@media (hover: hover) {
  .link--underline-main:hover {
    color: #000;
  }
}

.link--dark {
  color: #000000;
}
@media (hover: hover) {
  .link--dark:hover {
    color: #F46B37;
  }
}

.alert,
#komunikat,
.komunikat {
  border: 1px solid rgba(0, 0, 0, 0);
  margin-bottom: 20px;
  padding: 15px;
  text-align: center;
  font-size: 1rem;
  line-height: 1.4;
  background-color: #f8fbcb;
  color: #000000 !important;
  display: block;
}

.alert-success {
  background-color: rgba(139, 145, 60, 0.1) !important;
  color: #00AE50 !important;
}

.alert-info {
  background-color: #eaf6fb !important;
}

.alert-warning {
  background-color: #fef6f6 !important;
}

.alert-info2 {
  background-color: #428CC3 !important;
  color: #fff !important;
}

.alert-danger {
  background-color: #fef8f8 !important;
  color: #D83F40 !important;
}

hr {
  border: none;
  border-top: 1px solid #D6D6D6;
  margin-top: 20px;
  margin-bottom: 20px;
}

.img--fluid {
  max-width: 100%;
  height: auto;
}

.img--border {
  border-radius: 12px;
}

.box--center {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.nowrap {
  white-space: nowrap !important;
}

.btn {
  background: #F46B37;
  color: #fff;
  padding: 15px 60px 15px 20px;
  min-height: 50px;
  font-size: 0.875rem;
  font-weight: 700;
  white-space: nowrap;
  position: relative;
  display: inline-flex;
  align-items: center;
  transition: all 0.3s ease-in-out;
  border: 1px solid transparent;
  line-height: 1;
  text-align: center;
  text-decoration: none !important;
  background-image: url("../img/ico/ico-btn-arrow-2.svg");
  background-repeat: no-repeat;
  background-position: center right 22px;
}
@media (hover: hover) {
  .btn:hover {
    background-color: #000000;
    color: #fff !important;
  }
}

.btn--trans {
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.btn--blue {
  background-color: #E1EAF0;
  color: #000000;
}
@media (hover: hover) {
  .btn--blue:hover {
    background-color: #428CC3;
    color: #fff !important;
  }
}

.btn--black {
  background-color: #000000;
  color: #fff;
}
@media (hover: hover) {
  .btn--black:hover {
    background-color: #F46B37;
    color: #fff !important;
  }
}

.btn--white {
  background-color: #fff;
  color: #000000;
  border: 1px solid #DBDBDB;
  background-image: url("../img/ico/ico-btn-arrow.svg");
}
@media (hover: hover) {
  .btn--white:hover {
    border-color: #000000;
    background-color: #000000;
    color: #fff !important;
  }
}

.btn--plus {
  background-image: url("../img/ico/ico-plus.svg");
}

.btn--big {
  font-size: 20px;
}
@media (min-width: 768px) {
  .btn--big {
    font-size: 25px;
    background-size: 26px;
    padding-right: 80px;
    padding-left: 30px;
  }
}

.btn--shop {
  padding-left: 80px;
  background-color: #F46B37;
  background-image: url("../img/ico-cart-white.svg");
  background-position: 25px center;
  background-repeat: no-repeat;
}
.btn--shop:hover {
  background-color: #f55b1f;
  color: #fff !important;
}

.btn--all .hidden {
  display: none;
}
.btn--all.active span {
  display: none;
}
.btn--all.active .hidden {
  display: inline-block;
}

.btn--more {
  padding-left: 45px;
}
.btn--more:before {
  content: "+";
  position: absolute;
  top: 20px;
  left: 25px;
}

.btn--map {
  background: #EDEDED;
  color: #000;
  padding-left: 55px;
  position: relative;
}
.btn--map:before {
  content: url("../img/ico/ico-map.svg");
  position: absolute;
  top: 20px;
  left: 22px;
  transition: all 0.3s ease-in-out;
}
@media (hover: hover) {
  .btn--map:hover {
    background: #000;
    color: #fff !important;
  }
  .btn--map:hover:before {
    filter: brightness(0) invert(1);
  }
}

.btn--search {
  border: 1px solid rgba(65, 65, 65, 0.8);
  background: transparent;
  width: 100%;
  text-align: left;
  font-size: 18px;
}
.btn--search:after {
  content: "";
  width: 12px;
  height: 12px;
  border-left: 3px solid #fff;
  border-bottom: 3px solid #fff;
  position: absolute;
  display: block;
  top: 50%;
  right: 20px;
  transform-origin: center;
  transform: translateY(-50%) rotate(-45deg);
  margin-top: -3px;
}

.pr__nav-btn {
  position: relative;
  background: #EDEDED;
  width: 100%;
  padding-right: 50px;
  color: #000;
  text-align: left;
}
.pr__nav-btn:after {
  content: "";
  width: 12px;
  height: 12px;
  border-left: 3px solid #000;
  border-bottom: 3px solid #000;
  position: absolute;
  display: block;
  top: 50%;
  right: 20px;
  transform-origin: center;
  transform: translateY(-50%) rotate(-45deg);
  margin-top: -3px;
}

table {
  max-width: 100%;
  font-size: 0.875rem;
}
table th {
  background: #EDEDED;
  font-weight: 700;
  border: 1px solid #D6D6D6;
  padding: 10px 10px;
  vertical-align: middle;
}
table td {
  border: 1px solid #D6D6D6;
  padding: 7px 10px;
  vertical-align: middle;
}
table [align=right] {
  text-align: right;
}
table [align=left] {
  text-align: left;
}
table [align=center] {
  text-align: center;
}

.table__wrapper {
  font-size: 0.75rem;
}
.table__wrapper table {
  width: 100%;
}
.table__wrapper a {
  display: inline-block;
  padding: 1px 5px;
  text-decoration: underline;
  font-weight: bold;
}
@media (max-width: 768px) {
  .table__wrapper {
    overflow: auto;
  }
  .table__wrapper table {
    min-width: 600px;
    width: initial;
  }
  .table__wrapper th,
.table__wrapper td {
    padding: 5px;
  }
}

.btn--table {
  background: #000000;
  color: #fff;
  padding: 3px 10px !important;
  border-radius: 13px;
  margin-top: 7px;
  text-decoration: none !important;
}
@media (hover: hover) {
  .btn--table:hover {
    background: #f55b1f;
    color: #fff;
  }
}

.btn--tableR {
  background: #D83F40;
}
@media (hover: hover) {
  .btn--tableR:hover {
    background: #b61820;
  }
}

.overflow--hidden {
  overflow: hidden;
}

[disabled] {
  opacity: 0.3;
}

.file__list {
  margin-bottom: 20px;
  padding-left: 0 !important;
  list-style: none !important;
  border: 1px solid #D6D6D6;
  border-radius: 12px;
}

.file__item {
  padding-left: 15px;
  padding-right: 15px;
}
.file__item:last-child {
  margin-bottom: 0;
}
.file__item:last-child a {
  border-bottom: none;
}

.file__left {
  display: flex;
  gap: 15px;
  align-items: center;
  width: 100%;
}

.file__link {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 15px 0;
  color: #000000;
  gap: 20px;
  border-bottom: 1px solid #D6D6D6;
  text-decoration: none !important;
}
@media (hover: hover) {
  .file__link:hover {
    color: #000000;
  }
  .file__link:hover .file__title {
    color: #F46B37;
  }
  .file__link:hover .btn {
    background: #F46B37;
  }
}
@media (max-width: 768px) {
  .file__link {
    flex-wrap: wrap;
    gap: 10px;
  }
  .file__link .file__left {
    width: 100%;
    padding-right: 40px;
    flex-direction: column;
    gap: 5px;
  }
  .file__link .file__des {
    max-width: 100%;
    flex: 0 0 100%;
    width: 100%;
  }
  .file__link .file__size {
    margin-left: 0;
  }
  .file__link .btn--file {
    margin-left: auto;
    padding: 10px 15px 10px 40px;
  }
  .file__link .btn--file:after {
    left: 12px;
    margin-top: 2px;
  }
}

.file__size {
  color: #767676;
  font-size: 1rem;
  flex: 0 0 100px;
  max-width: 100px;
  margin-left: auto;
}

.file__title {
  font-size: 1.125rem !important;
  margin: 0 !important;
  display: inline;
  width: 100%;
  transition: all 0.3s ease-out;
}

.file__des {
  max-width: 40%;
  flex: 0 0 40%;
  font-size: 0.75rem !important;
  margin-bottom: 0 !important;
}

.btn--file {
  padding-left: 50px;
  text-align: left;
  position: relative;
}
.btn--file:after {
  content: url("../img/ico-download.svg");
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 20px;
  margin-top: 3px;
}

.file__heading {
  margin-bottom: 20px;
}

.info-important {
  padding-left: 20px;
  background-image: url("../img/ico/ico-i-grey.svg");
  background-size: 14px;
  background-position: left center;
  background-repeat: no-repeat;
  color: #959595;
}

.info-important__bubbles {
  padding-left: 30px;
  background-image: url("../img/ico/ico-i-grey.svg");
  background-size: 14px;
  background-position: left center;
  background-repeat: no-repeat;
  color: #959595;
}

.info-bubble {
  display: inline-flex;
  width: 30px;
  height: 30px;
  background-image: url("../img/ico/ico-i-new.svg");
  background-size: 24px;
  background-position: center;
  background-repeat: no-repeat;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  position: relative;
  border-radius: 50%;
  cursor: pointer;
  vertical-align: middle;
}
.info-bubble span {
  position: absolute;
  bottom: 125%;
  font-size: 0.625rem;
  line-height: 1.3;
  display: block;
  width: 250px;
  left: 0;
  background: #fff;
  z-index: -10;
  opacity: 0;
  font-weight: 400;
  box-shadow: 0 6px 9px 0 rgba(0, 0, 0, 0.16);
  transition: all 0.3s ease-in-out;
  padding: 6px 5px;
  text-align: center;
  color: #000000 !important;
  font-weight: 400;
}
.info-bubble:hover span {
  opacity: 1;
  z-index: 10;
}

.pr__title {
  margin-bottom: 40px;
  padding-top: 10px;
  padding-left: 25px;
  padding-bottom: 10px;
  background-image: url(../img/line-orange.svg);
  background-repeat: repeat-y;
  background-position: left top;
  font-size: 37px;
}
@media (max-width: 768px) {
  .pr__title {
    font-size: 30px;
  }
}
@media (max-width: 576px) {
  .pr__title {
    font-size: 24px;
  }
}

.disabled {
  color: #666;
}

fieldset {
  border: none;
  padding: 0;
}

.form-h-info {
  margin-top: 5px;
  font-size: 0.625rem;
  max-height: 0;
  overflow: hidden;
  color: #767676;
  transition: all 0.3s ease-in-out;
}

.form__input input:focus + .form-h-info {
  max-height: 100px;
}

.input--data {
  background-image: url("../img/ico/ico-calendar-input.svg");
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: center right 15px;
}

.form__select {
  padding: 0;
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin-bottom: 25px;
  z-index: 10;
  width: 100%;
}
.form__select label {
  color: #333333;
  font-size: 0.75rem;
  margin-bottom: 6px;
  font-weight: 700;
  display: block;
}
.form__select.is--invalid.custom-select .select-selected, .form__select.is--invalid select {
  border-color: #D83F40;
}
.form__select select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid #D6D6D6;
  border-radius: 6px;
  padding: 15px 45px 15px 15px;
  display: block;
  background: transparent;
  width: 100%;
  min-height: 48px;
  font-size: 1.125rem;
  line-height: 1.5;
  cursor: pointer;
}
.form__select select:focus, .form__select select:focus-visible {
  box-shadow: inset 0px 0px 0px 1px #00AE50;
  border-color: #00AE50;
}
.form__select option {
  border: none;
  padding: 10px;
  font-size: 1rem;
  color: #000;
  line-height: 40px;
}
.form__select:after {
  content: "";
  position: absolute;
  right: 15px;
  bottom: 26px;
  z-index: -1;
  width: 10px;
  height: 10px;
  display: block;
  border-left: 3px solid #000;
  border-bottom: 3px solid #000;
  transform: rotate(-45deg);
}
@media (max-width: 576px) {
  .form__select {
    width: 100%;
  }
  .form__select select {
    width: 100%;
  }
}

.is--desktop .custom-select.select {
  display: none;
  /*hide original SELECT element: */
}
.is--desktop .custom-select select {
  display: none;
  /*hide original SELECT element: */
}
.is--desktop .custom-select.form__select:after {
  content: none;
}

.custom-select {
  position: relative;
  z-index: 40;
}
.custom-select .select-selected {
  color: #000000;
  border-radius: 6px;
  padding: 15px 45px 15px 15px;
  border: 1px solid #D6D6D6;
  display: block;
  background: #fff;
  width: 100%;
  font-weight: 700;
  font-size: 1.125rem;
  position: relative;
  z-index: 98;
}
.custom-select .select-selected:after {
  content: "";
  position: absolute;
  right: 15px;
  bottom: 25px;
  z-index: -1;
  width: 10px;
  height: 10px;
  display: block;
  border-left: 3px solid #000;
  border-bottom: 3px solid #000;
  transform: rotate(-45deg);
}
.custom-select .select-arrow-active {
  border-color: transparent;
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.26);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background: #EDEDED;
}
.custom-select .select-items {
  position: absolute;
  background-color: #fff;
  border: 1px solid #EDEDED;
  border-top: 0;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
  border-radius: 12px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  box-shadow: 0 6px 9px rgba(0, 0, 0, 0.36);
  overflow: auto;
  max-height: 300px;
}
.custom-select .select-items:after {
  width: 100%;
  height: 5px;
  background: #fff;
  z-index: 10;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  content: "";
  display: block;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  left: 0;
}
.custom-select .select-items.select-hide {
  display: none;
}
.custom-select .select-items div {
  padding: 8px 15px;
  cursor: pointer;
  font-size: 1.125rem;
}
@media (hover: hover) {
  .custom-select .select-items div:not(.same-as-selected):hover {
    background-color: #F46B37;
    color: #fff;
  }
}
.custom-select .same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}

.form__checkbox {
  margin-bottom: 0px;
  position: relative;
}
.form__checkbox input {
  position: absolute;
  left: 0;
  top: 0;
  padding: 0;
  z-index: -1000;
  opacity: 0;
  width: 0;
  height: 0;
}
.form__checkbox a {
  text-decoration: underline;
  font-weight: 500;
}
.form__checkbox label {
  position: relative;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 34px;
  line-height: 20px;
  color: #333333;
  font-size: 1.125rem;
  line-height: 1.4;
  cursor: pointer;
  display: inline-block;
  width: 100%;
}
@media (max-width: 576px) {
  .form__checkbox label {
    font-size: 1rem;
  }
}
.form__checkbox label:before {
  content: "";
  width: 24px;
  height: 24px;
  position: absolute;
  top: 5px;
  left: 0;
  display: block;
  border: 2px solid #D6D6D6;
  background-color: #fff;
  transition: all 0.3s ease-out;
}
.form__checkbox label:after {
  content: "";
  display: block;
  position: absolute;
  top: 12px;
  left: 7px;
  width: 0;
  height: 0px;
  transform: rotate(-45deg);
  border-radius: 2px;
}
.form__checkbox input:checked ~ label:before {
  background-color: #F46B37;
}
.form__checkbox input:checked ~ label:after {
  width: 12px;
  height: 7px;
  border-bottom: 3px solid #fff;
  border-left: 3px solid #fff;
}
.form__checkbox input:focus-visible ~ label:before {
  outline: 2px solid #F46B37;
  outline-offset: 2px;
}
.form__checkbox input[type=radio] ~ label:before {
  border-radius: 50%;
}
.form__checkbox input[type=radio]:checked ~ label:after {
  width: 14px;
  height: 14px;
  border: none;
  border-radius: 50%;
  background: #F46B37;
  top: 10px;
  left: 5px;
}
.form__checkbox input[type=radio]:checked ~ label:before {
  background: #fff;
}
.form__checkbox.is--invalid label:before {
  border-color: #D83F40;
}

.form__checkbox--small label {
  font-size: 0.75rem;
}

.form__checkbox--medium label {
  font-size: 1rem;
}

.form__input {
  margin-bottom: 15px;
  position: relative;
}
.form__input label {
  font-size: 0.625rem;
  margin-bottom: 6px;
  font-weight: 700;
  color: #000000;
  display: block;
  position: absolute;
  background: #fff;
  padding: 0 5px;
  top: 10px;
  z-index: -1;
  opacity: 0;
  transition: top 0.3s ease-out;
}
.form__input textarea {
  width: 100% !important;
}
.form__input input,
.form__input textarea {
  border: 1px solid #D6D6D6;
  width: 100%;
  display: block;
  color: #000;
  font-size: 1rem;
  padding: 12px 15px;
  min-height: 50px;
  border-radius: 0;
}
.form__input input:focus, .form__input input:focus-visible,
.form__input textarea:focus,
.form__input textarea:focus-visible {
  border-color: #000000;
  box-shadow: inset 0px 0px 0px 2px #000000;
}
.form__input input:focus::-moz-placeholder, .form__input input:focus-visible::-moz-placeholder, .form__input textarea:focus::-moz-placeholder, .form__input textarea:focus-visible::-moz-placeholder {
  opacity: 0;
}
.form__input input:focus:-ms-input-placeholder, .form__input input:focus-visible:-ms-input-placeholder, .form__input textarea:focus:-ms-input-placeholder, .form__input textarea:focus-visible:-ms-input-placeholder {
  opacity: 0;
}
.form__input input:focus::placeholder, .form__input input:focus-visible::placeholder,
.form__input textarea:focus::placeholder,
.form__input textarea:focus-visible::placeholder {
  opacity: 0;
}
.form__input input:not(:-moz-placeholder-shown) ~ label, .form__input textarea:not(:-moz-placeholder-shown) ~ label input:valid:required ~ label {
  opacity: 1;
  top: -5px;
  left: 20px;
  z-index: 5;
}
.form__input input:not(:-ms-input-placeholder) ~ label, .form__input textarea:not(:-ms-input-placeholder) ~ label input:valid:required ~ label {
  opacity: 1;
  top: -5px;
  left: 20px;
  z-index: 5;
}
.form__input input:focus ~ label, .form__input input:not(:placeholder-shown) ~ label,
.form__input textarea:focus ~ label, .form__input textarea:not(:placeholder-shown) ~ label input:valid:required ~ label {
  opacity: 1;
  top: -5px;
  left: 20px;
  z-index: 5;
}
.form__input.is--invalid label {
  color: #D83F40;
}
.form__input.is--invalid input,
.form__input.is--invalid textarea {
  border-color: #D83F40 !important;
  background-image: none;
}
.form__input.is--invalid input:focus, .form__input.is--invalid input:focus-visible,
.form__input.is--invalid textarea:focus,
.form__input.is--invalid textarea:focus-visible {
  box-shadow: inset 0px 0px 0px 2px #D83F40;
  border-color: #D83F40;
}
.form__input.is--invalid:after {
  content: "!";
  color: #D83F40;
  position: absolute;
  top: 6px;
  right: 20px;
  font-size: 26px;
  font-weight: 700;
}
.form__input.is--valid label {
  color: #14C3A8;
}
.form__input.is--valid input,
.form__input.is--valid textarea {
  border-color: #14C3A8 !important;
  background-image: none;
}
.form__input.is--valid input:focus, .form__input.is--valid input:focus-visible,
.form__input.is--valid textarea:focus,
.form__input.is--valid textarea:focus-visible {
  box-shadow: inset 0px 0px 0px 2px #14C3A8;
  border-color: #14C3A8;
}
.form__input.is--valid:after {
  content: "";
  transform: rotate(-45deg);
  border-left: 2px solid #14C3A8;
  border-bottom: 2px solid #14C3A8;
  position: absolute;
  top: 20px;
  right: 20px;
  display: block;
  width: 12px;
  height: 8px;
}
@media (max-width: 992px) {
  .form__input input,
.form__input textarea {
    font-size: 1rem;
  }
}

.form__input--bold label {
  font-size: 1.125rem;
  color: #000000;
  font-weight: 700;
  margin-bottom: 10px;
}
.form__input--bold.is--valid:after {
  top: 52px;
}
.form__input--bold.is--invalid:after {
  top: 46px;
}

.form__nolabel.is--valid:after {
  top: 15px;
}
.form__nolabel.is--invalid:after {
  top: 7px;
}

.form__input--error {
  color: #D83F40;
  font-size: 0.625rem;
  display: block;
  margin-top: 5px;
}

.form__checkbox .form__input--error {
  margin-bottom: 5px;
}

.form__select + .form__input--error {
  position: relative;
  top: -20px;
}

.form__switch {
  position: relative;
  display: inline-block;
  white-space: nowrap;
  /* Hide default HTML checkbox */
  /* The slider */
  /* Rounded sliders */
}
.form__switch input {
  opacity: 0;
  width: 0;
  height: 0;
  position: absolute;
  z-index: -1;
}
.form__switch label {
  padding-right: 60px;
  line-height: 24px;
  display: inline-block;
  font-size: 1.25rem;
  position: relative;
}
.form__switch label:before {
  content: "";
  height: 26px;
  width: 50px;
  border-radius: 16px;
  position: absolute;
  cursor: pointer;
  top: 50%;
  transform: translateY(-14px);
  right: 0;
  bottom: 0;
  background-color: #EDEDED;
  transition: 0.4s;
  z-index: 2;
  cursor: pointer;
}
.form__switch label:after {
  content: "";
  position: absolute;
  height: 20px;
  width: 20px;
  right: 26px;
  bottom: 3px;
  border-radius: 11px;
  background-color: #959595;
  transition: 0.4s;
  z-index: 3;
  cursor: pointer;
}
.form__switch input:checked + label:before {
  background-color: #F46B37;
}
.form__switch input:checked + label:after {
  background: #fff;
}
.form__switch input:checked + label:after {
  right: 3px;
}

.cart__customer {
  display: flex;
  margin-bottom: 20px;
  margin-top: 20px;
  align-items: center;
  gap: 15px;
  font-size: 0.875rem;
}
.cart__customer .form__checkbox {
  margin: 0;
}
.cart__customer .form__checkbox label {
  font-size: 1rem;
  font-weight: 700;
  border: none;
  min-height: auto;
}
.cart__customer .form__checkbox label:before {
  top: 50% !important;
  transform: translateY(-50%);
}
.cart__customer .form__checkbox label:after {
  top: 50% !important;
  transform: translateY(-50%);
  left: 7px !important;
}
@media (max-width: 992px) {
  .cart__customer {
    flex-wrap: wrap;
    gap: 10px;
    flex-direction: column;
    align-items: flex-start;
  }
}

.form-s fieldset {
  margin-bottom: 30px;
}
.form-s fieldset {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  -moz-column-gap: 20px;
       column-gap: 20px;
}
.form-s .form__input--25 {
  max-width: calc(50% - 15px);
}
.form-s .form__input,
.form-s .form__select {
  width: 100%;
}
@media (min-width: 768px) {
  .form-s .form__input,
.form-s .form__select {
    width: 100%;
  }
}
@media (min-width: 992px) {
  .form-s .form__input,
.form-s .form__select,
.form-s .form__checkbox--50 {
    flex: 0 0 50%;
    max-width: calc(50% - 10px);
  }
  .form-s .form__input--25 {
    max-width: calc(25% - 15px);
  }
}

@media (min-width: 768px) {
  .fieldset .form__gus,
.fieldset--border .form__gus {
    margin-top: 25px;
  }
}

.button__password {
  position: absolute;
  right: 1px;
  top: 8px;
  width: 40px;
  height: 38px;
  background-image: url("../img/eye.svg");
  background-size: 20px;
  background-position: center;
  background-repeat: no-repeat;
  border: none;
  z-index: 20;
  background-color: transparent;
}
.button__password:after {
  content: "";
  display: block;
  height: 1px;
  background: #ddd;
  transform: rotate(45deg);
  position: absolute;
  transition: all 0.3s ease-in-out;
  width: 20px;
  top: 19px;
  left: 10px;
}
.button__password.show:after {
  top: 10px;
  left: 10px;
  width: 0px;
}

.form__password.form__input:after {
  content: none !important;
  opacity: 0;
}

.btn-gnerate {
  background: #F46B37;
  font-size: 0.875rem;
  color: #fff;
  padding: 10px 15px;
  border-radius: 20px;
  border: none;
  white-space: nowrap;
  font-weight: 700;
}

.generate__score {
  transition: all 0.3s ease-out;
  max-height: 0px;
  overflow: hidden;
  display: flex;
  font-size: 0.875rem;
  align-items: center;
  line-height: 1.2;
  gap: 10px;
}
@media (min-width: 1200px) {
  .generate__score {
    gap: 20px;
  }
}
@media (max-width: 410px) {
  .generate__score {
    font-size: 0.75rem;
  }
}
.generate__score .generate__password {
  display: block;
}
.generate__score.open {
  max-height: 100px;
}

#input-accept {
  border: none;
  background: none;
  color: #F46B37;
  padding: 10px 0;
  font-size: 0.875rem;
  font-weight: 700;
}

.generate__wrapper {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  display: flex;
  color: #000000;
}
@media (min-width: 992px) {
  .generate__wrapper {
    margin-top: 10px;
  }
}
@media (min-width: 992px) {
  .generate__wrapper {
    flex: 0 0 50%;
    max-width: calc(50% - 10px);
    align-items: flex-start;
  }
}

@media (max-width: 390px) {
  .generate__wrapper {
    flex-direction: column;
  }

  .generate__score .generate__password {
    display: inline-block;
    margin-left: 10px;
  }
}
.btn-date-trigger {
  position: absolute;
  top: 1px;
  right: 5px;
  width: 50px;
  height: 50px;
  background: rgba(0, 0, 0, 0);
  padding: 0;
  display: block;
  z-index: 10;
  border: none;
}

.form__input .datepicker {
  left: initial !important;
  right: 0;
}

.container {
  padding-left: 20px;
  padding-right: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 1440px;
}
@media (min-width: 576px) {
  .container {
    padding-left: 60px;
    padding-right: 60px;
  }
}

.container--wide {
  max-width: 1920px;
}

.container--500 {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.container--sm {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.container--md {
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}

.container--xl {
  max-width: 930px;
  margin-left: auto;
  margin-right: auto;
}

.container--xxl {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.container--1300 {
  max-width: 1340px;
  margin-left: auto;
  margin-right: auto;
}

.container--1400 {
  max-width: 1420px;
}

.container--1500 {
  max-width: 1480px;
}

.container--header {
  max-width: 1580px;
}

.justify-content-center {
  justify-content: center !important;
}

.break-column {
  flex-basis: 100%;
  width: 0;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  width: calc(100% + 30px);
}

ul.row {
  padding-left: 0 !important;
}

[class*=col-] {
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 30px;
  width: 100%;
}

.row--24 {
  margin-left: -12px;
  margin-right: -12px;
  width: calc(100% + 24px);
}
.row--24 [class*=col-] {
  padding-left: 12px;
  padding-right: 12px;
  margin-bottom: 24px;
}
@media (max-width: 576px) {
  .row--24 {
    margin-left: -5px;
    margin-right: -5px;
    width: calc(100% + 10px);
  }
  .row--24 [class*=col-] {
    padding-left: 5px;
    padding-right: 5px;
    margin-bottom: 10px;
  }
}

.row--16 {
  margin-left: -8px;
  margin-right: -8px;
  width: calc(100% + 16px);
}
.row--16 [class*=col-] {
  padding-left: 8px;
  padding-right: 8px;
  margin-bottom: 16px;
}
@media (max-width: 576px) {
  .row--16 {
    margin-left: -5px;
    margin-right: -5px;
    width: calc(100% + 10px);
  }
  .row--16 [class*=col-] {
    padding-left: 5px;
    padding-right: 5px;
    margin-bottom: 10px;
  }
}

@media (min-width: 1200px) {
  .row--60 {
    margin-left: -30px;
    margin-right: -30px;
    width: calc(100% + 60px);
  }
  .row--60 [class*=col-] {
    padding-left: 30px;
    padding-right: 30px;
    margin-bottom: 60px;
    width: 100%;
  }
}

.w-100 {
  width: 100% !important;
  max-width: 100% !important;
  flex: 0 0 100% !important;
}

.col-6 {
  width: 50%;
  flex: 0 0 50%;
}

@media (min-width: 576px) {
  .col-sm-4 {
    max-width: 33.33%;
    flex: 0 0 33.33%;
  }

  .col-sm-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }
}
@media (min-width: 768px) {
  .col-md-4 {
    max-width: 33.33%;
    flex: 0 0 33.33%;
  }

  .col-md-5 {
    max-width: 41.66%;
    flex: 0 0 41.66%;
  }

  .col-md-8 {
    max-width: 66.66%;
    flex: 0 0 66.66%;
  }

  .col-md-50,
.col-md-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }
}
@media (min-width: 992px) {
  .col-lg-2 {
    max-width: 16.66%;
    flex: 0 0 16.66%;
  }

  .col-lg-3 {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .col-lg-4 {
    max-width: 33.33%;
    flex: 0 0 33.33%;
  }

  .col-lg-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .col-lg-9 {
    max-width: 75%;
    flex: 0 0 75%;
  }
}
@media (min-width: 1200px) {
  .col-xl-2 {
    max-width: 16.66%;
    flex: 0 0 16.66%;
  }

  .col-xl-3 {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .col-xl-4 {
    max-width: 33.33%;
    flex: 0 0 33.33%;
  }

  .col-xl-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .col-xl-8 {
    max-width: 66.66%;
    flex: 0 0 66.66%;
  }

  .col-xl-15 {
    max-width: 15%;
    flex: 0 0 15%;
  }

  .col-xl-20 {
    max-width: 20%;
    flex: 0 0 20%;
  }

  .col-xl-65 {
    max-width: 65%;
    flex: 0 0 65%;
  }
}
.img--fluid {
  max-width: 100%;
  height: auto;
}

.box--center {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.nowrap {
  white-space: nowrap !important;
}

.section {
  padding-top: 40px;
  padding-bottom: 40px;
}

@media (max-width: 576px) {
  .mainpage .section {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
.section--20 {
  padding-top: 20px;
}

.section--bottom {
  padding-bottom: 40px;
}

.section--first {
  padding-top: 60px;
}

.section--sh {
  padding-top: 130px;
}
@media (max-width: 992px) {
  .section--sh {
    padding-top: 115px;
  }
}

.section--sh2 {
  padding-top: 150px;
}
@media (max-width: 992px) {
  .section--sh2 {
    padding-top: 130px;
  }
}

.d-block {
  display: block;
}

.ml-auto {
  margin-left: auto !important;
}

.pt-0 {
  padding-top: 0px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pt-30 {
  padding-top: 30px !important;
}

.pt-40 {
  padding-top: 40px !important;
}

.pt-50 {
  padding-top: 50px !important;
}

.pb-0 {
  padding-bottom: 0px !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.pb-30 {
  padding-bottom: 30px !important;
}

.pb-40 {
  padding-bottom: 40px !important;
}

.pb-50 {
  padding-bottom: 50px !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.mt-10, .m-t-10 {
  margin-top: 10px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mt-100 {
  margin-top: 100px !important;
}
@media (max-width: 768px) {
  .mt-100 {
    margin-top: 50px !important;
  }
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-25 {
  margin-bottom: 25px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mb-60 {
  margin-bottom: 60px !important;
}

.mb-70 {
  margin-bottom: 70px !important;
}

@media (max-width: 768px) {
  .mb-50 {
    margin-bottom: 40px !important;
  }

  .mb-60 {
    margin-bottom: 40px !important;
  }

  .mb-70 {
    margin-bottom: 40px !important;
  }
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.breadcrumbs {
  padding-top: 0px;
  padding-bottom: 7px;
}
.breadcrumbs ul {
  display: inline-flex;
  flex-wrap: wrap;
}
.breadcrumbs ul span,
.breadcrumbs ul a {
  font-size: 0.75rem;
  color: rgba(255, 255, 255, 0.8);
  display: inline-block;
  padding: 5px 0;
  line-height: 1;
  font-weight: 400;
}
.breadcrumbs ul span:before,
.breadcrumbs ul a:before {
  margin-right: 5px;
  color: inherit;
  content: "/"/"";
}
.breadcrumbs ul li:first-child a:before {
  content: none;
}
.breadcrumbs ul a {
  margin-right: 5px;
}
.breadcrumbs ul a svg {
  stroke: #000000;
  transition: all 0.3s ease-in;
}
.breadcrumbs ul a:focus, .breadcrumbs ul a:hover {
  outline: none;
  color: #F46B37;
}
.breadcrumbs ul a:focus svg, .breadcrumbs ul a:hover svg {
  stroke: #F46B37;
}
.breadcrumbs ul a:focus:after, .breadcrumbs ul a:hover:after {
  color: #000000;
}
.breadcrumbs ul a:focus-visible {
  outline: 2px solid #F46B37;
}
@media (min-width: 768px) {
  .breadcrumbs {
    padding-bottom: 15px;
  }
  .breadcrumbs ul {
    line-height: 1;
  }
  .breadcrumbs ul a,
.breadcrumbs ul span {
    font-size: 0.875rem;
  }
}
@media (max-width: 576px) {
  .breadcrumbs {
    overflow: auto;
    margin-bottom: 10px;
  }
  .breadcrumbs ul {
    flex-wrap: initial;
  }
  .breadcrumbs a,
.breadcrumbs span {
    white-space: nowrap;
  }
}

.breadcrumbs2 {
  padding-bottom: 10px;
}
.breadcrumbs2 .breadcrumbs2, .breadcrumbs2 .breadcrumbs {
  padding-bottom: 0px;
}
.breadcrumbs2 ul a, .breadcrumbs2 ul span {
  color: #767676;
}
@media (max-width: 992px) {
  .breadcrumbs2 {
    padding-bottom: 5px;
  }
}

.header__fixed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
}

@-webkit-keyframes fixedMenuDown {
  0% {
    top: -100px;
  }
  100% {
    top: 0;
  }
}

@keyframes fixedMenuDown {
  0% {
    top: -100px;
  }
  100% {
    top: 0;
  }
}
.subpage .header {
  background: #000;
}

.header {
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
}
.header.fixed-header-scroll {
  position: fixed;
  top: 0;
  background: #000;
  -webkit-animation-name: fixedMenuDown;
  animation-name: fixedMenuDown;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  left: 0;
  width: 100%;
  z-index: 100;
  box-shadow: 2px 2px 15px 0 rgba(0, 0, 0, 0.25);
}
.header.fixed-header-scroll .headerControls__name {
  display: none;
}
.header.fixed-header-scroll:after {
  height: 4px;
}
@media (min-width: 992px) {
  .header.fixed-header-scroll .navMain {
    margin-top: 0;
  }
  .header.fixed-header-scroll .navMain .nav__level-1 > li > a {
    height: 52px;
    padding-top: 10px;
  }
  .header.fixed-header-scroll .nav__level-2 {
    top: 100%;
    z-index: 20;
  }
  .header.fixed-header-scroll .nav__level-2:before {
    width: 100%;
    height: 6px;
    border: none;
    top: -6px;
    background: transparent;
    left: 0;
  }
}
@media (max-width: 992px) {
  .header.fixed-header-scroll .navMain.open {
    top: 60px;
    height: calc(100vh - 60px);
  }
}
.header.fixed-header-scroll .header__wrapper {
  height: 60px;
}
.header.fixed-header-scroll .header__wrapper .logo img {
  max-width: 160px;
}

.hero__calendar {
  background: url(../img/ico/ico-calendar-white.svg) no-repeat center;
  background-size: 20px;
  padding-left: 30px;
  background-position: left center;
  line-height: 27px;
  white-space: nowrap;
}

.header--2 {
  background: #fff;
}
.header--2:after {
  height: 8px;
}

.header__wrapper {
  display: flex;
  gap: 30px;
  height: 80px;
  align-items: center;
  justify-content: space-between;
}
.header__wrapper.container {
  max-width: 1580px;
}

.logo {
  line-height: 1;
}
.logo img {
  width: 210px;
}

.header__contact {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-left: auto;
  overflow: hidden;
}

.header__social {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-left: auto;
  margin-right: auto;
}
.header__social a {
  width: 30px;
  height: 30px;
  display: inline-flex;
  line-height: 1;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
}
@media (hover: hover) {
  .header__social a:hover {
    background: #F46B37;
  }
}

.nav2 ul {
  font-size: 0.75rem;
  display: flex;
}
.nav2 ul a {
  font-weight: 700;
  color: #000000;
  text-transform: uppercase;
  display: inline-block;
  padding: 15px;
}

.headerForm {
  width: 170px;
  position: relative;
  margin-left: auto;
}
.headerForm input {
  border: none;
  width: 100%;
  border-bottom: 2px solid #000000;
  padding-right: 30px;
}
.headerForm ::-moz-placeholder {
  color: #000000;
  font-weight: 700;
}
.headerForm :-ms-input-placeholder {
  color: #000000;
  font-weight: 700;
}
.headerForm ::placeholder {
  color: #000000;
  font-weight: 700;
}
.headerForm button {
  position: absolute;
  width: 30px;
  right: 0;
  bottom: 0;
  border: none;
  padding: 0;
  display: inline-block;
  background: transparent;
  height: 100%;
  background-image: url("../img/btn-search.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.headerContact {
  margin-left: auto;
  margin-right: auto;
}

.headerContact__phone {
  color: #000000;
  line-height: 1.1;
  padding-left: 25px;
  position: relative;
  display: block;
}
.headerContact__phone:before {
  content: url("../img/phone.svg");
  position: absolute;
  left: 0px;
  top: 9px;
}
.headerContact__phone span {
  font-size: 8px;
  text-transform: uppercase;
}
.headerContact__phone strong {
  font-size: 18px;
  display: block;
}
@media (hover: hover) {
  .headerContact__phone:hover {
    color: #F46B37;
  }
}

.headerControls {
  display: flex;
  align-items: center;
  list-style: none;
  padding: 0;
  font-size: 0.875rem;
  gap: 30px;
}

.headerControls__link {
  display: inline-flex;
  min-width: 50px;
  height: 50px;
  justify-content: center;
  align-items: center;
  border: none;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../img/ico/ico-heart.svg");
}
@media (hover: hover) {
  .headerControls__link:hover svg {
    stroke: #F46B37 !important;
  }
}

.headerControls__heart.active {
  background-image: url("../img/ico/ico--full.svg");
}

.headerControls__amount {
  position: absolute;
  color: #fff;
  font-size: 11px;
  top: 5px;
  right: 4px;
}

.nav-toggle {
  min-width: 50px;
  height: 50px;
  background: none;
  border: none;
  position: relative;
  overflow: hidden;
  display: none;
  padding: 0;
  margin-left: -10px;
}
.nav-toggle span {
  width: 14px;
  height: 3px;
  background: #fff;
  display: block;
  position: absolute;
  left: 12px;
  top: 15px;
  transition: all 0.3s ease-in-out;
  transform-origin: center;
}
.nav-toggle span:nth-child(2) {
  top: 22px;
}
.nav-toggle span:nth-child(3) {
  top: 29px;
  width: 10px;
}
.nav-toggle.open span:first-child {
  transform: rotate(45deg);
  top: 24px;
}
.nav-toggle.open span:nth-child(2) {
  left: 50px;
  opacity: 0;
}
.nav-toggle.open span:nth-child(3) {
  top: 24px;
  transform: rotate(-45deg);
  width: 14px;
}

.nav {
  position: absolute;
  top: 80px;
  left: 20px;
  height: 100px;
  width: calc(100% - 40px);
  background: red;
  z-index: 100;
  display: none;
}

.page__header {
  position: relative;
  min-height: 150px;
  padding-top: 40px;
  padding-bottom: 30px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(to right, #1a7cc1, #00b2ec);
}
.page__header .breadcrumbs {
  border: none;
}
.page__header .breadcrumbs ul {
  justify-content: center;
}
.page__header .breadcrumbs ul a,
.page__header .breadcrumbs ul span {
  color: #eee;
}
.page__header h1,
.page__header h2 {
  font-size: 2.5rem;
  margin-bottom: 10px;
  color: #fff;
  text-align: center;
}
@media (max-width: 576px) {
  .page__header h1,
.page__header h2 {
    font-size: 1.875rem;
  }
}

.page__header--product {
  min-height: 100px;
  padding-top: 0;
  display: flex;
  align-items: center;
}

.page__headerImg {
  position: absolute;
  top: 0;
  height: 100%;
  width: auto;
  right: 0;
  z-index: -1;
}
@media (max-width: 1200px) {
  .page__headerImg {
    opacity: 0.2;
  }
}

header ul a {
  padding: 5px 0;
  display: block;
}

.navMain .nav__level-1 > li > a {
  padding: 10px 8px;
  font-family: "Lexend Deca", sans-serif;
}

header > ul > li {
  position: relative;
}

.navMain > ul {
  display: flex;
}
.navMain > ul > li > a {
  padding: 10px 0;
  font-size: 1rem;
  color: #fff;
  white-space: nowrap;
}
@media (hover: hover) {
  .navMain > ul a:hover {
    color: #F46B37;
  }
  .navMain > ul li:hover > a {
    color: #F46B37;
  }
}

.nav__level-2 {
  display: none;
  position: absolute;
  background: #fff;
  width: 260px;
  text-align: left;
  left: -20px;
  padding: 30px 20px 10px 45px;
}
.nav__level-2:before {
  content: "";
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #fff;
  position: absolute;
  left: 50px;
  top: -9px;
}
.nav__level-2 li {
  position: relative;
}

.nav__level-2wrapper {
  display: grid;
  gap: 30px;
  grid-template-columns: 1fr 1fr;
}

.nav__level-2C {
  width: 480px;
  display: none;
  background: #fff;
  background-image: url("../img/menu-decor.svg");
  background-repeat: repeat-y;
  background-position: left;
  box-shadow: 2px 2px 15px 0 rgba(0, 0, 0, 0.25);
}
.nav__level-2C .open {
  display: flex;
}

.nav__title {
  font-size: 1.25rem;
}

.nav__list2 {
  padding: 10px 0;
}
.nav__list2 a {
  font-size: 1rem;
  padding: 4px 0;
  color: #212121;
  white-space: nowrap;
  width: 100%;
  display: block;
}

.navMain a.active {
  color: #F46B37;
}

.nav__level-3 {
  left: 100%;
  top: 0;
  display: none !important;
}

.nav__level-4 {
  display: none !important;
  left: 100%;
  top: 0;
  position: absolute;
}

.nav__level-3.open {
  display: block !important;
}

.nav__level-4.open {
  display: block !important;
}

@media (max-width: 1400px) {
  .header__contact .ico3 {
    padding-left: 30px;
  }
  .header__contact .ico3:before {
    left: 5px;
  }
  .header__contact .ico3 span {
    opacity: 0;
    position: absolute;
    z-index: -1;
    width: 0;
    display: block;
  }
}
@media (max-width: 1200px) {
  .header__wrapper {
    gap: 10px;
  }
}
@media (max-width: 992px) {
  .nav2,
.headerContact {
    display: none;
  }

  .nav-toggle {
    display: block;
  }
}
@media (min-width: 992px) {
  .navMain {
    margin-left: 40px;
    margin-top: 0px;
  }
  .navMain .nav__level-1 > li > a {
    padding: 15px 8px 15px 8px;
  }

  .logo img {
    width: 200px;
  }

  .nav__level-2D {
    width: 420px;
  }

  .navMain__mobile {
    display: none;
  }

  .menu__item--has-children--icon {
    padding-left: 25px;
    background-image: url("../img/ico/ico-menu-wyprawy-white.svg");
    background-repeat: no-repeat;
    background-position: center left;
  }
  .menu__item--has-children--icon > a {
    padding-left: 0 !important;
  }
}
@media (min-width: 992px) and (hover: hover) {
  .menu__item--has-children--icon:hover {
    background-image: url("../img/ico/menu-close.svg");
  }
  .menu__item--has-children--icon:hover > a {
    color: #F46B37;
  }
}
@media (min-width: 1200px) {
  .logo img {
    width: 250px;
  }
}
@media (min-width: 1500px) {
  .navMain .nav__level-1 > li > a {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.menu__item--has-children {
  position: relative;
}

@media (max-width: 992px) {
  .header {
    transition: background 0.1s ease-out 0.3s;
  }

  .navMain > ul > li > a {
    color: #000000;
    padding-left: 0 !important;
    font-weight: 700;
  }

  .menu-open {
    overflow: hidden;
    height: 100vh;
  }
  .menu-open .header {
    transition: none;
  }

  .header__contact {
    gap: 5px;
  }

  .nav__level-2C {
    left: 0;
    top: 0;
    display: block;
    position: relative;
    width: 100%;
    background: transparent;
    box-shadow: none;
    padding: 0;
  }
  .nav__level-2C ul {
    display: block;
    border: none;
  }
  .nav__level-2C:before {
    content: none;
  }

  .header__wrapper {
    height: 80px;
    gap: 0;
  }

  .menu__item--has-children {
    position: relative;
  }
  .menu__item--has-children > a {
    width: 100% !important;
    padding-right: 50px;
  }
  .menu__item--has-children .nav__level-1 {
    margin-bottom: 0;
  }
  .menu__item--has-children:after {
    content: "";
    display: inline-block;
    width: 10px;
    height: 10px;
    border-left: 3px solid #000000;
    border-bottom: 3px solid #000000;
    position: absolute;
    right: 20px;
    top: 19px;
    transform: rotate(-45deg);
    z-index: -1;
  }

  .navMain {
    position: fixed;
    z-index: 100;
    background-image: url("../img/menu-decor.svg");
    background-repeat: repeat-y;
    background-position: top right;
    max-height: calc(100vh - 80px);
    top: 80px;
    width: 90vw;
    left: -100vw;
    transition: left 0.3s ease-out;
    background-color: #fff;
    padding-right: 15px;
    overflow: hidden;
    padding-left: 20px;
    padding-right: 20px;
    box-shadow: 2px 2px 15px 0 rgba(0, 0, 0, 0.25);
  }
  .navMain.open {
    left: 0;
    overflow: auto;
  }
  .navMain > ul {
    display: block;
  }
  .navMain > ul:last-child {
    margin-bottom: 60px;
  }
  .navMain > ul > li {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }
  .navMain .nav__level-2 {
    display: none;
    background: transparent;
    padding-bottom: 10px;
  }
  .navMain ul li {
    width: 100%;
  }
  .navMain ul .nav__list2 {
    margin-top: 10px;
  }
  .navMain ul .nav__list2 a {
    line-height: 1.2;
  }
  .navMain ul ul {
    width: 100%;
    position: relative;
    overflow: auto;
    padding: 0;
    transition: all 0.3s ease-out;
    -moz-column-count: 1;
         column-count: 1;
    gap: 10px;
  }
  .navMain ul ul.open {
    max-height: 500px;
  }
  .navMain ul ul li {
    padding-left: 0;
    padding-right: 0;
  }
  .navMain ul ul a {
    padding-left: 0px;
  }
  .navMain ul ul ul a {
    padding: 7px 0 7px 30px;
    font-size: 0.875rem;
  }
  .navMain > ul > li > a,
.navMain > ul > li > ul {
    padding-left: 20px;
    padding-left: 20px;
    border: none;
  }
}
@media (max-width: 768px) {
  .header__social {
    display: none;
  }
}
@media (max-width: 576px) {
  .navMain {
    top: 60px;
  }
  .navMain .nav__level-2 {
    flex-direction: column;
    padding-left: 10px;
  }

  body:not(.mainpage) .header__wrapper {
    background: #212121;
  }

  .header--2:not(.fixed-header-scroll):after {
    background: #D6D6D6;
    bottom: -8px;
  }

  .headerControls__link {
    background-size: 20px;
  }

  .header__wrapper {
    height: 60px;
  }
  .header__wrapper .logo {
    max-width: 140px;
    max-width: calc(100% - 180px);
  }
  .header__wrapper .logo img {
    max-width: 100%;
  }

  .header__wrapper.container {
    padding-right: 10px;
  }

  .nav__level-2wrapper {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr;
  }

  .header:after {
    bottom: -2px;
  }

  .navMain > ul > li {
    border-bottom: 1px solid rgba(255, 255, 255, 0.7);
  }

  .header.fixed-header-scroll .header__contact {
    display: none;
  }
  .header.fixed-header-scroll .nav-toggle {
    position: relative;
    top: 0px;
  }
  .header.fixed-header-scroll .header__wrapper {
    padding-top: 0;
  }
  .header.fixed-header-scroll .header__wrapper:after {
    content: none;
  }
}
.invite {
  padding-bottom: 10px;
  padding-top: 90px;
  background-image: linear-gradient(#BCD1DB, #e7ebec);
}
.invite h3 {
  margin-bottom: 40px;
}

.invite--2 .invite {
  position: relative;
  padding-bottom: 110px;
  margin-bottom: 30px;
}
.invite--2 .invite:after {
  content: "";
  display: block;
  width: 100%;
  height: 52px;
  bottom: 0;
  left: 0;
  background-image: url(../img/onas-z-m.svg);
  background-position: center;
  background-repeat: repeat-x;
  z-index: 1;
  position: absolute;
}

.invite__row {
  width: 100%;
  display: flex;
  gap: 100px;
  align-items: center;
}

.invite__left p {
  margin-bottom: 20px;
}

.invite__right {
  flex: 0 0 660px;
}

.nr__list {
  display: flex;
  gap: 30px 20px;
  flex-wrap: wrap;
}

.nr__item {
  flex: 0 0 50%;
  max-width: calc(50% - 20px);
  display: flex;
  align-items: center;
}
.nr__item strong {
  color: #F46B37;
  font-size: 37px;
  flex: 0 0 100px;
  display: block;
  padding-right: 15px;
  text-align: right;
  margin-right: 10px;
  border-right: 4px solid #B8BCBD;
  line-height: 50px;
}

@media (max-width: 1200px) {
  .invite {
    padding-top: 50px;
  }

  .invite__row {
    display: block;
  }

  .invite__left {
    margin-bottom: 30px;
  }
  .invite__left p {
    margin-bottom: 10px;
  }

  .nr__list {
    max-width: 620px;
  }
}
@media (max-width: 576px) {
  .nr__item strong {
    font-size: 26px;
    flex: 0 0 70px;
    padding-right: 10px;
    margin-right: 10px;
    line-height: 36px;
  }

  .nr__list {
    gap: 10px 6px;
  }

  .nr__item {
    max-width: 100%;
    flex: 0 0 100%;
  }
}
.footer {
  overflow: hidden;
  background-image: linear-gradient(#E7EBEC, #E7EBEC, #220E06, #220E06);
  position: relative;
}

.footer--2 {
  background-color: transparent;
}
.footer--2 .footer {
  background-color: transparent;
}

.footer__bgImg {
  width: auto;
  height: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 0;
  transform: translate(-50%);
}
@media (min-width: 1670px) {
  .footer__bgImg {
    width: 100%;
  }
}
@media (max-width: 576px) {
  .footer__bgImg {
    width: auto;
  }
}

.newsletter__title {
  font-size: 1.25rem;
  font-weight: 700;
  color: #fff;
}

.newsletter__form {
  max-width: 100%;
  position: relative;
  display: flex;
  gap: 5px;
}
.newsletter__form ::-moz-placeholder {
  color: #fff;
}
.newsletter__form :-ms-input-placeholder {
  color: #fff;
}
.newsletter__form ::placeholder {
  color: #fff;
}

.newsletter__form input {
  background: 0 0;
  color: #fff;
  border: 2px solid rgba(255, 255, 255, 0.4);
  padding: 10px;
  font-size: 1rem;
  transition: all 0.3s ease-out;
  border-radius: 0;
  width: 100%;
}
.newsletter__form input:focus-visible {
  outline: none;
}

.footer__wide {
  line-height: 2;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
@media (min-width: 1200px) {
  .footer__wide {
    flex-direction: row;
    gap: 50px;
  }
}
@media (min-width: 1440px) {
  .footer__wide {
    gap: 100px;
  }
}

@media (min-width: 768px) {
  .newsletter__form input {
    max-width: 300px;
  }

  .newsletter__title {
    margin-bottom: 0;
  }

  .newsletter__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
  }

  .newsletter__form {
    gap: 10px;
  }
}
@media (max-width: 576px) {
  .newsletter__form .btn-form {
    text-indent: -10000px;
    padding: 0 24px;
    background-position: center right 16px;
  }
  .newsletter__form .btn-form:after {
    text-indent: 0px;
  }

  .mt-30-sm {
    margin-top: 30px;
  }
}
.footer__center {
  color: #fff;
  padding-bottom: 25px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.footer__center [class*=col-] {
  margin-bottom: 0;
}
.footer__center .accordion {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.footer__center .accordion__btn {
  color: #fff !important;
  font-size: 1.25rem;
  font-weight: 700;
}
.footer__center .accordion__btn:after {
  color: #fff !important;
  top: 10px;
}
.footer__center .accordion h3 {
  display: none;
}
.footer__center h3 {
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 30px;
}
.footer__center ul {
  list-style: none;
  gap: 10px;
  margin-bottom: 30px;
}
.footer__center ul > li {
  position: relative;
}
.footer__center ul > li a {
  padding: 5px 0px;
  font-size: 1rem;
  color: #fff;
  line-height: 1.7;
  display: inline-block;
}
@media (hover: hover) {
  .footer__center ul > li a:hover {
    color: #F46B37;
  }
}

.footer__top {
  padding-top: 190px;
  padding-bottom: 40px;
  position: relative;
  z-index: 5;
  background-image: url("../img/bg-decor-h.svg");
  background-position: bottom;
  background-repeat: repeat-x;
}

.footer__logo {
  display: block;
  margin-bottom: 40px;
}

.footer__bottom {
  font-size: 0.875rem;
  color: #fff;
  opacity: 0.7;
  padding-bottom: 13px;
  padding-top: 13px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footer__bottom img {
  max-height: 40px;
  width: auto;
  max-width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}

.footer__brand {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding-bottom: 10px;
  padding-top: 10px;
  line-height: 1;
  gap: 20px;
}
.footer__brand a,
.footer__brand span {
  display: inline-block;
  padding: 5px;
  vertical-align: bottom;
  line-height: 1;
}
.footer__brand img {
  width: auto;
}

.footer__brandItem {
  display: flex;
  align-items: center;
}

@media (min-width: 576px) {
  .footer__center {
    margin-bottom: 0;
    padding-top: 50px;
  }
  .footer__center .accordion__btn {
    display: none !important;
  }
  .footer__center .accordion {
    border-bottom: none;
  }
  .footer__center .accordion h3 {
    display: block;
  }
}
@media (min-width: 768px) {
  .footer__bottom .container {
    display: flex;
    align-items: center;
    gap: 30px;
  }

  .footer__bgWrapper.trapez__imgWraper {
    height: 100%;
    border-bottom: none;
  }

  .footer .container {
    position: relative;
    z-index: 2;
  }
}
@media (min-width: 992px) {
  .footer__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: auto;
    flex-direction: row;
  }

  .footer__logo {
    margin-bottom: 0px;
  }
}
@media (max-width: 992px) {
  .footer__bottom {
    display: block;
  }

  .footer__brand {
    justify-content: center;
  }
}
@media (max-width: 992px) {
  .footer__brand {
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }
}
@media (max-width: 576px) {
  .footer .accordion__content {
    display: none;
  }

  .footer__top {
    margin-bottom: 15px;
    padding-top: 130px;
  }
}
.btn--heart {
  font-size: 14px;
  font-weight: 700;
  padding: 10px 0 10px 30px;
  min-width: 50px;
  height: 50px;
  border: 0;
  background: 0 0;
  position: relative;
}
.btn--heart:before {
  content: url("../img/ico/ico-heart-white.svg");
  position: absolute;
  top: 18px;
  left: 5px;
}

.btn--heartActive:before {
  content: url("../img/ico/ico-heart-white-full.svg");
}

.product__figure {
  position: relative;
  height: 0;
  padding-top: 65%;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
}
.product__figure img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  bottom: 50%;
  left: 50%;
  transform: translate(-50%, 50%);
  position: absolute;
  transition: all 0.3s ease-out;
}

.product__link {
  position: relative;
  height: 100%;
  color: #000000;
  display: flex;
  flex-direction: column;
  background: #fff;
}
@media (hover: hover) {
  .product__link:hover, .product__link.active {
    box-shadow: 0 6px 9px rgba(0, 0, 0, 0.2);
  }
  .product__link:hover .product__figure img, .product__link.active .product__figure img {
    width: 104%;
    height: 104%;
  }
}

.product__item {
  transition: opacity 0.3s ease-in-out;
  position: relative;
}
.product__item.swiper-slide {
  height: auto;
}
.product__item .btn--heart {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 4;
}

.product__item--op0 {
  opacity: 0;
}

.product__info {
  padding: 20px 30px 30px 30px;
}

.product__top {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  gap: 0 20px;
}
.product__top .product__level {
  margin-left: auto;
}

.product__last {
  padding: 5px 10px 5px 24px;
  background: #D83F40;
  color: #fff;
  font-weight: 700;
  font-size: 0.6875rem;
  line-height: 1;
  display: block;
  border-radius: 15px;
  white-space: nowrap;
  position: absolute;
  top: 15px;
  left: 15px;
  z-index: 2;
}
.product__last:before {
  content: url("../img/ico/ico-bell-2.svg");
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -5px;
  margin-top: 1px;
}

.product__country {
  display: flex;
  align-items: center;
  gap: 5px;
  color: #666666;
}

.product__bottom {
  border-top: 1px solid #D6D6D6;
  margin-top: auto;
  display: flex;
  align-items: center;
  gap: 30px;
  padding: 10px 0;
  margin-left: 30px;
  margin-right: 30px;
}

.product__right {
  margin-left: auto;
  font-size: 14px;
  color: #666666;
  white-space: nowrap;
}

.product__price {
  font-size: 18px;
  color: #000000;
}

.product__calendar {
  background: url("../img/ico/ico-calendar.svg") no-repeat center;
  background-size: 20px;
  padding-left: 30px;
  background-position: left center;
  font-size: 14px;
  color: #666666;
  line-height: 27px;
}

.otherTherm__wrapper:after {
  content: url("../img/ico/ico-arrow-calendar.svg");
  margin-left: 5px;
  position: relative;
  top: 1 px;
}

.product__title {
  margin-bottom: 0;
  transition: color 0.3s ease-in-out;
  line-height: 1.2;
  color: #000000;
  font-size: 1rem;
}

.product__title--big {
  font-size: 1.625rem;
}

.product__description {
  font-size: 16px;
  margin-top: 25px;
  margin-bottom: 35px;
  color: #000000;
}

.product__feature {
  color: #767676;
  font-size: 12px;
  display: flex;
  gap: 5px 30px;
  flex-wrap: wrap;
}
.product__feature > div {
  display: flex;
  align-items: center;
  gap: 10px;
}

.product__new {
  color: #F46B37;
  display: inline-block;
  font-size: 1.0625rem;
  background: #E4E4E4;
  border-radius: 24px;
  padding: 3px 10px 3px 23px;
  position: absolute;
  top: 20px;
  left: 37px;
  z-index: 20;
  line-height: 1;
  font-family: "Lexend Deca", sans-serif;
}
.product__new:before {
  content: "";
  display: inline-block;
  width: 34px;
  height: 34px;
  border-radius: 2px;
  background-image: url("../img/ico/ico-wykrzyknik.svg");
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -17px;
}

.otherTherm__btn {
  font-size: 15px;
  opacity: 0.8;
}
.otherTherm__btn:after {
  content: "»";
  padding-left: 5px;
}

.otherTherm__wrapper {
  position: relative;
  line-height: 1.5;
}
.otherTherm__wrapper:hover .otherTherm__list {
  opacity: 1;
  z-index: 10;
}

.product__item {
  transition: max-width 0.2s ease-out, 0.1s ease-out;
}
.product__item.noactive {
  opacity: 0;
  max-width: 0 !important;
  overflow: hidden;
  visibility: hidden;
  z-index: -1000;
  padding: 0;
}

.otherTherm__list {
  position: absolute;
  bottom: 30px;
  right: -10px;
  min-width: 100%;
  padding: 5px 10px;
  background: #000000;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  color: #fff;
  font-size: 12px;
  opacity: 0;
  z-index: -100;
  transition: opacity 0.3s ease-out;
  border-radius: 6px;
}
.otherTherm__list li {
  white-space: nowrap;
}
.otherTherm__list:before {
  content: "";
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid #000000;
  position: absolute;
  bottom: -7px;
  left: 50%;
  margin-left: -4px;
}

@media (max-width: 1200px) {
  .product__title--big {
    font-size: 1.25rem;
  }

  .product__description {
    font-size: 0.9375rem;
  }

  .product__calendar {
    font-size: 12px;
  }

  .product__right {
    font-size: 13px;
  }

  .product__price {
    font-size: 16px;
  }

  .product__info {
    padding: 20px;
  }

  .product__bottom {
    margin-left: 20px;
    margin-right: 20px;
    gap: 15px;
  }
}
@media (max-width: 576px) {
  .product__title {
    font-size: 0.875rem;
  }

  .product__calendar {
    font-size: 10px;
    background-size: 15px;
    padding-left: 22px;
  }

  .product__right {
    font-size: 11px;
  }

  .product__price {
    font-size: 14px;
  }

  .product__description {
    font-size: 10px;
  }
}
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 120;
  padding: 30px;
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  opacity: 0;
  visibility: hidden;
  transition: visibility 0s linear 0.1s, opacity 0.3s ease;
}
.modal.open {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
}
.modal .link--underline-main {
  display: block;
  margin-bottom: 10px;
}
.modal p {
  margin-bottom: 20px;
}
.modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 21;
  background-color: rgba(0, 0, 0, 0.7);
}
.modal__close {
  position: absolute;
  top: 5px;
  right: 5px;
  border: none;
  outline: none;
  background: none;
  width: 48px;
  height: 48px;
  display: inline-block;
  background: #000000;
  color: #fff;
  padding: 0;
}
.modal__close:after {
  content: "+";
  transform: rotate(45deg);
  font-size: 32px;
  position: absolute;
  top: 2px;
  left: 17px;
  transition: all 0.3s ease-out;
}
.modal__close:hover {
  background: #F46B37;
}
.modal__container {
  position: absolute;
  z-index: 22;
  max-width: 1520px;
  width: 96%;
  max-height: 90%;
  overflow: auto;
  max-width: 540px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0 auto;
  padding: 50px;
  background-color: #fff;
}
.modal__title {
  margin-bottom: 30px;
  text-transform: uppercase;
  text-align: left;
  margin-top: 0;
}
.modal h4 {
  font-size: 1.875rem;
  margin-bottom: 10px;
  color: #000;
}
@media (max-width: 992px) {
  .modal__title {
    font-size: 1.5rem;
  }
  .modal h4 {
    font-size: 1.25rem;
    margin-bottom: 5px;
  }
}
.modal ul {
  list-style: disc;
  padding-left: 30px;
}

@media (max-width: 768px) {
  .modal__container {
    padding: 15px 25px;
  }
  .modal__container .mt-30 {
    margin-top: 20px !important;
  }

  .modal__title {
    padding-right: 40px;
  }
}
.starWrapper {
  display: block;
  width: 100px;
  background-image: url("../img/ico/ico-star-grey.svg");
  background-repeat: repeat-x;
  background-position: left;
  height: 20px;
  position: relative;
}
.starWrapper span {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  background-image: url("../img/ico/ico-star.svg");
  background-repeat: repeat-x;
  background-position: left;
  height: 20px;
}

.quick-access-nav {
  position: absolute;
  top: -1000px;
  width: 100%;
  z-index: 1000;
}
.quick-access-nav ul {
  width: 100%;
}
.quick-access-nav li {
  position: absolute;
  width: 100%;
}
.quick-access-nav li a {
  display: block;
  margin: 0 auto;
  padding: 0.5em 3em;
  text-align: center;
  text-decoration: none;
  color: #fff;
  background-color: #000;
  outline: none;
  border: none;
  left: 50%;
  transform: translateX(-50%);
}
.quick-access-nav li a:focus, .quick-access-nav li a:focus-within {
  position: absolute;
  top: 1000px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.relative {
  position: relative;
}

.skip__link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding: 0 3em;
  text-align: center;
  text-decoration: none;
  color: #fff;
  background-color: #000;
  outline: none;
  border: none;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  height: 0;
  overflow: hidden;
}
.skip__link:focus, .skip__link:focus-within {
  position: absolute;
  top: 0;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 50px;
}

.bg__wrapper {
  position: relative;
  z-index: 1;
}

.bg__img {
  position: absolute;
  overflow: hidden;
  bottom: 0;
  left: 0;
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.section-txt-white {
  color: #fff;
}

.boxDecor {
  padding-left: 50px;
  padding-top: 20px;
  padding-bottom: 20px;
  position: relative;
  min-height: 120px;
  margin-bottom: 40px;
  background-image: url("../img/line-orange.svg");
  background-repeat: repeat-y;
  background-position: left top;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
}
.boxDecor .title {
  margin-bottom: 0;
}

.boxDecor--bottom {
  padding-left: 50px;
  margin-bottom: 40px;
}

.boxDecor--green {
  background-image: url("../img/line-green.svg");
}

.boxDecor--blue {
  background-image: url("../img/line-blue.svg");
}

@media (max-width: 1200px) {
  .boxDecor--bottom {
    padding-left: 40px;
  }

  .boxDecor {
    min-height: 100px;
    padding-left: 40px;
  }
}
@media (max-width: 992px) {
  .boxDecor--bottom {
    padding-left: 0;
  }
}
@media (max-width: 576px) {
  .boxDecor {
    min-height: initial;
    padding-left: 0px;
    background: none;
    margin-bottom: 10px;
    padding-top: 0;
  }
  .boxDecor h1 {
    padding-left: 20px;
    background-image: url(../img/line-orange.svg);
    background-repeat: repeat-y;
    background-position: left top;
    padding-bottom: 40px;
    padding-top: 40px;
  }
  .boxDecor .hero-no-mobile {
    display: none;
  }

  .boxDecor--blue h1 {
    background-image: url("../img/line-blue.svg");
  }

  .boxDecor--green h1 {
    background-image: url("../img/line-green.svg");
  }

  .heroProduct .boxDecor h1 {
    padding-bottom: 10px;
    padding-top: 10px;
  }
}
.swiper2__nav {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 50px;
  margin-top: 30px;
  width: 100%;
}
.swiper2__nav .container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 50px;
}
.swiper2__nav .swiper-pagination-bullets.swiper-pagination {
  width: auto;
}
.swiper2__nav .swiper-button-prev, .swiper2__nav .swiper-button-next, .swiper2__nav .swiper-pagination {
  position: static;
  margin-top: 0;
}
.swiper2__nav .swiper-button-prev, .swiper2__nav .swiper-button-next {
  background-color: transparent;
}
@media (hover: hover) {
  .swiper2__nav .swiper-button-prev:hover, .swiper2__nav .swiper-button-next:hover {
    background-color: #fff;
  }
}
.swiper2__nav .swiper-button-prev {
  background-image: url("../img/ico/ico-swiper-left.svg");
}
.swiper2__nav .swiper-button-next {
  background-image: url("../img/ico/ico-swiper-right.svg");
}

.swiper .swiper-pagination-bullet {
  width: 6px;
  height: 6px;
  display: inline-block;
  border-radius: 0;
  background: #000000;
  opacity: var(--swiper-pagination-bullet-inactive-opacity, 0.2);
}
.swiper .swiper-pagination-bullet-active {
  opacity: 1;
  background: #000000;
}
.swiper .swiper-pagination {
  display: flex;
  align-items: center;
  padding-right: 20px;
}

.swiper-button-stop {
  width: 50px;
  height: 50px;
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("../img/ico/ico-swiper-stop.svg");
  transition: all 0.3s ease-out;
}
@media (hover: hover) {
  .swiper-button-stop:hover {
    background-color: #fff;
  }
}
.swiper-button-stop.swiper-button-play {
  background-image: url("../img/ico/ico-swiper-play.svg");
}

.swiper-lazy:not(.swiper-lazy-loaded) {
  opacity: 0;
}

section ::-webkit-scrollbar {
  height: 2px;
}
section ::-webkit-scrollbar-track {
  background: #D6D6D6;
  border-radius: 0px;
}
section ::-webkit-scrollbar-thumb {
  background-color: #000000;
  outline: 1px solid #767676;
  border-radius: 2px;
}
section ::-webkit-scrollbar:horizontal {
  height: 2px;
}

.section--top {
  padding-top: 60px;
}
@media (max-width: 768px) {
  .section--top {
    padding-top: 30px;
  }
}

.heroS {
  min-height: 350px;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 80px;
  color: #fff;
  background: #000;
}
@media (max-width: 576px) {
  .heroS {
    margin-top: 60px;
  }
}

.heroS__title h1 {
  font-size: 37px;
  margin-bottom: 5px;
}

.heroProduct {
  margin-top: 0px;
  min-height: 800px;
  justify-content: flex-end;
}
.heroProduct .heroS__imgWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.heroProduct .heroS__imgWrapper:before {
  content: "";
  display: block;
  width: 100%;
  top: 50%;
  height: 50%;
  background-image: linear-gradient(rgba(45, 44, 42, 0), #2d2c2a);
  z-index: 1;
  position: absolute;
}
.heroProduct .heroS__img {
  opacity: 1;
  z-index: 0;
}
.heroProduct .heroS__container {
  padding-bottom: 0px;
}
.heroProduct .heroS__title {
  max-width: 800px;
}
@media (max-width: 768px) {
  .heroProduct {
    min-height: 600px;
    overflow: hidden;
  }
  .heroProduct .breadcrumbs {
    position: absolute;
    top: 80px;
    left: 20px;
    width: calc(100% - 20px);
  }
  .heroProduct:before {
    content: "";
    display: block;
    width: 100%;
    top: 0;
    left: 0;
    height: 200px;
    background-image: linear-gradient(#2d2c2a, rgba(45, 44, 42, 0));
    z-index: 1;
    position: absolute;
  }
}

.heroS.heroS-small {
  min-height: 200px;
}

.heroS-mountains:after {
  content: "";
  display: block;
  width: 100%;
  height: 50px;
  bottom: 0;
  left: 0;
  background-image: url("../img/header-mountains.svg");
  background-position: center;
  background-repeat: repeat-x;
  z-index: 1;
  position: absolute;
}
.heroS-mountains .boxDecor {
  margin-bottom: 0;
}
.heroS-mountains .heroS__container {
  padding-bottom: 50px;
}

.heroS-list.heroS-mountains {
  min-height: 350px;
}
.heroS-list.heroS-mountains .heroS__container {
  padding-bottom: 150px;
}
.heroS-list.heroS-mountains:after {
  bottom: 0px;
}

.heroS__img {
  position: absolute;
  top: 0;
  left: 0;
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 100%;
  opacity: 0.4;
}

.heroS__container {
  padding-top: 50px;
  padding-bottom: 90px;
  z-index: 20;
}
.heroS__container .boxDecor {
  gap: 10px;
}
.heroS__container ::-webkit-scrollbar {
  height: 2px;
}
.heroS__container ::-webkit-scrollbar-track {
  background: #000;
  border-radius: 0px;
}
.heroS__container ::-webkit-scrollbar-thumb {
  background-color: #333;
  outline: 1px solid #333;
  border-radius: 2px;
}
.heroS__container ::-webkit-scrollbar:horizontal {
  height: 2px;
}

.heroS__des {
  opacity: 0.8;
  font-size: 0.875rem;
}

.hero__info {
  display: flex;
  align-items: center;
  gap: 5px;
}
.hero__info img {
  width: 35px;
}
.hero__info .hero__time {
  padding-left: 15px;
  padding-right: 10px;
  border-left: 1px solid rgba(255, 255, 255, 0.3);
  border-right: 1px solid rgba(255, 255, 255, 0.3);
  white-space: nowrap;
}
.hero__info .hero__time.ico--time {
  padding-left: 26px;
  margin-left: 6px;
}
.hero__info .hero__time.ico--time:before {
  left: 3px;
}

@media (max-width: 768px) {
  .heroS__title h1 {
    font-size: 30px;
  }
}
@media (max-width: 576px) {
  .heroS__title h1 {
    font-size: 24px;
  }
}
@media (min-width: 992px) {
  .heroS {
    min-height: 710px;
  }
}
.accordion img:not(:last-child) {
  max-width: 100%;
  margin-bottom: 10px;
}
.accordion p:not(:last-child) {
  margin-bottom: 10px;
}

.accordion__content[aria-hidden=true] {
  display: none;
}

.accordion__btn:not(.btn) {
  border: none;
  width: 100%;
  display: block;
  position: relative;
  padding: 18px 50px 18px 20px;
  text-align: left;
  background: transparent;
  font-family: "Lexend Deca", sans-serif;
  font-size: 1.4375rem;
  color: #212121;
  background: #F4F4F4;
}
.accordion__btn:not(.btn):after {
  content: "";
  width: 40px;
  height: 40px;
  display: block;
  background: url("../img/ico/ico-arrow-down-black.svg") no-repeat;
  background-position: center;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s ease-out;
  position: absolute;
}
.accordion__btn:not(.btn).active:after {
  transform: translateY(-50%) rotate(180deg);
}

.accordion__panel {
  padding-bottom: 10px;
  padding-top: 20px;
}
.accordion__panel img {
  width: 100%;
}
@media (min-width: 576px) {
  .accordion__panel img.w-50 {
    display: inline-block;
    width: calc(50% - 10px) !important;
    margin-bottom: 14px !important;
  }
  .accordion__panel img.w-50:not(:nth-child(even)) {
    margin-right: 10px;
  }
}

.accordion--small {
  font-size: 14px;
}
.accordion--small .accordion__btn:not(.btn) {
  font-size: 16px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-right: 20px;
  color: #767676;
}
.accordion--small .accordion__btn:not(.btn):after {
  font-size: 1.7rem;
  top: 3px;
}

.tabs__navlist {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 3px;
  position: relative;
  overflow: auto;
  width: 100%;
  padding: 8px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.16);
  border-radius: 12px;
  margin-bottom: 20px;
  background: #fff;
}
@media (min-width: 1200px) {
  .tabs__navlist {
    gap: 6px;
    margin-bottom: 40px;
  }
}

.tabs ::-webkit-scrollbar {
  height: 2px;
}
.tabs ::-webkit-scrollbar-track {
  background: #D6D6D6;
  border-radius: 2px;
}
.tabs ::-webkit-scrollbar-thumb {
  background-color: #000000;
  outline: 1px solid #767676;
  border-radius: 2px;
}
.tabs ::-webkit-scrollbar:horizontal {
  height: 2px;
}

.tabs__nav-trigger {
  padding: 9px 8px 9px 8px;
  border: none;
  background: none;
  display: inline-block;
  font-size: 1.25rem;
  transition: all 0.3s ease-in-out;
  white-space: nowrap;
  display: flex;
  align-items: center;
  font-family: "Lexend Deca", sans-serif;
  gap: 7px;
  color: #F46B37;
  height: 50px;
  border-radius: 6px;
}
@media (hover: hover) {
  .tabs__nav-trigger.hover.tabs__nav--last {
    background: #F46B37;
    color: #fff;
  }
  .tabs__nav-trigger.hover.guide__btn--filter.tabs__nav--last img, .tabs__nav-trigger.hover.tabs__nav--last.tabs__nav--white img {
    filter: brightness(0) invert(1);
  }
}
.tabs__nav-trigger[aria-selected=true].tabs__nav--last, .tabs__nav-trigger.active.tabs__nav--last {
  background: #F46B37;
  color: #fff;
}
.tabs__nav-trigger[aria-selected=true].tabs__nav--last img, .tabs__nav-trigger.active.tabs__nav--last img {
  filter: none;
}
.tabs__nav-trigger[aria-selected=true].guide__btn--filter.tabs__nav--last img, .tabs__nav-trigger[aria-selected=true].tabs__nav--last.tabs__nav--white img, .tabs__nav-trigger.active.guide__btn--filter.tabs__nav--last img, .tabs__nav-trigger.active.tabs__nav--last.tabs__nav--white img {
  filter: brightness(0) invert(1);
}

@media (max-width: 1200px) {
  .tabs__navContainer.container {
    max-width: 100%;
  }
}

.tabs__panel {
  display: none;
}
.tabs__panel.is-current {
  display: block;
}

@media (min-width: 1420px) {
  .tabs__nav-trigger {
    width: 100%;
    justify-content: center;
    gap: 10px;
    font-size: 1.5rem;
    padding: 9px 16px 9px 16px;
  }
  .tabs__nav-trigger img {
    width: 36px;
  }
}
.tabs__nav-trigger[aria-selected=true], .tabs__nav-trigger.active {
  color: #fff !important;
}
.tabs__nav-trigger[aria-selected=true] img, .tabs__nav-trigger.active img {
  filter: brightness(0) invert(1);
}

.accordion2__btn:not(.btn) {
  background: transparent;
  border: 0;
  width: 100%;
  display: block;
  position: relative;
  padding: 15px 25px 15px 0;
  text-align: left;
  background: 0 0;
  font-family: "Lexend Deca", sans-serif;
  font-size: 1.4375rem;
  color: #000000;
}
.accordion2__btn:not(.btn):after {
  content: "+";
  font-size: 2.5rem;
  position: absolute;
  right: 0;
  top: 6px;
  color: #767676;
  font-weight: 300;
  line-height: 1;
  background: none;
  transform: none !important;
  width: initial;
  height: initial;
}
.accordion2__btn:not(.btn).active:after {
  content: "-"/"";
}

.category__wrapper {
  margin-top: 30px;
}

.category__title {
  text-transform: uppercase;
  font-size: 0.8125rem;
  padding: 15px 10px;
  text-align: center;
  font-weight: 400;
  transition: color 0.3s ease-in-out;
  margin-bottom: 0;
  line-height: 1.2;
}

.category__figure {
  position: relative;
  height: 0;
  padding-top: 61%;
  border: 1px solid #D6D6D6;
}
.category__figure img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

.listProduct__wrapper .category__link {
  background: #EDEDED;
}

.category__link {
  display: block;
  text-align: center;
}
@media (hover: hover) {
  .category__link:hover, .category__link.active {
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.15);
    background: #EDEDED;
  }
  .category__link:hover .category__title, .category__link.active .category__title {
    color: #F46B37;
  }
}

.category__item {
  margin-bottom: 16px;
}

.heart__wrapper .hide {
  display: none;
}

.similar {
  padding-top: 30px;
  padding-bottom: 30px;
}
.similar .product__brand {
  font-size: 0.8125rem;
}
.similar .product__title {
  font-size: 0.875rem;
  margin-bottom: 5px;
}
.similar .product__priceOld {
  font-size: 0.875rem;
}
.similar .product__priceNew {
  font-size: 0.875rem;
}
.similar .product__figure {
  margin-bottom: 20px;
}

.product__discount2 {
  width: 25px;
  height: 25px;
  background: #EDEDED;
  color: #000000;
  border-radius: 50%;
  z-index: 10;
  font-size: 0.625rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gallery__wrapper {
  display: grid;
  padding-left: 0 !important;
  list-style: none !important;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
}
@media (max-width: 576px) {
  .gallery__wrapper {
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    grid-template-areas: "gallery1 gallery1 " "gallery2 gallery3 " "gallery4 gallery4 ";
  }
}

.gallery__list {
  padding-left: 0 !important;
  list-style: none !important;
}

.gallery__link {
  height: 0;
  padding-top: 63%;
  position: relative;
  display: block;
  width: 100%;
  overflow: hidden;
}
.gallery__link img {
  position: absolute;
  top: 50%;
  left: 50%;
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  transition: all 0.3s ease-out;
}
.gallery__link:before {
  content: url(../img/ico/ico-search.svg);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1.5);
  z-index: -1;
  opacity: 0;
  transition: opacity 1s ease-out;
  margin-top: 5px;
}
@media (hover: hover) {
  .gallery__link:hover {
    background: black;
  }
  .gallery__link:hover:before {
    z-index: 12;
    opacity: 1;
  }
  .gallery__link:hover img {
    transform: translate(-50%, -50%) scale(1.1);
    opacity: 0.7;
  }
}

.blog {
  background: #EDEDED;
}

.blog__list {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  margin-bottom: 30px;
  list-style: none !important;
  padding-left: 0 !important;
}

.blog__item {
  flex: 0 0 100%;
  background: #fff;
  padding-bottom: 10px;
  box-shadow: 0 6px 9px rgba(0, 0, 0, 0);
  transition: all 0.3s ease-out;
  overflow: hidden;
  position: relative;
}
.blog__item:after {
  content: "";
  position: absolute;
  bottom: 20px;
  right: 25px;
  transition: all 0.3s ease-out;
  width: 33px;
  height: 33px;
  background-image: url("../img/ico/ico-btn-arrow.svg");
  background-size: 20px;
  background-repeat: no-repeat;
}
.blog__item a {
  display: block;
  height: 100%;
}
@media (hover: hover) {
  .blog__item:hover {
    color: #000000;
    box-shadow: 0 6px 9px rgba(0, 0, 0, 0.16);
  }
  .blog__item:hover .blog__icons:after {
    background-image: url("../img/ico/arrow-blog-hover.svg");
  }
  .blog__item:hover .blog__figure > img {
    transform: translate(-50%, -50%) scale(1.1);
  }
}
@media (max-width: 1200px) {
  .blog__item:after {
    bottom: 10px;
    right: 15px;
  }
}

.blog__figure {
  width: 100%;
  padding-top: 64%;
  position: relative;
  overflow: hidden;
  height: 0;
  margin-bottom: 30px;
}
@media (max-width: 576px) {
  .blog__figure {
    margin-bottom: 20px;
  }
}
.blog__figure > img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: 2;
  transform: translate(-50%, -50%);
  transition: all 0.3s ease-out;
}

.blog__title {
  font-size: 1.125rem;
  margin-top: 10px;
  font-weight: 700;
  transition: all 0.3s ease-out;
  color: #000000;
  font-weight: 400;
}

.blog__txt {
  padding-left: 35px;
  padding-right: 35px;
  padding-bottom: 50px;
}
@media (max-width: 1200px) {
  .blog__txt {
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 40px;
  }
}
@media (max-width: 576px) {
  .blog__txt {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 40px;
  }
}

.blog__data {
  color: #AAAAAA;
  position: relative;
  font-size: 0.6875rem;
  display: block;
  line-height: 1;
  margin-bottom: 15px;
}

.blog__summary {
  font-size: 1rem;
  color: #000000;
}

@media (min-width: 576px) {
  .blog__list {
    margin-bottom: 50px;
  }

  .blog__item {
    max-width: calc(50% - 15px);
    flex: 0 0 50%;
  }
}
@media (min-width: 992px) {
  .blog__item {
    max-width: calc(33.33% - 20px);
    flex: 0 0 33.33%;
  }
}
@media (min-width: 1200px) {
  .blog__data {
    font-size: 1rem;
    margin-bottom: 25px;
  }
}
.artpag__list {
  padding-top: 50px;
  padding-bottom: 90px;
  position: relative;
  gap: 30px;
}
@media (min-width: 992px) {
  .artpag__list {
    display: flex;
  }
  .artpag__list li:not(.artpag__last) {
    flex: 0 0 50%;
    max-width: calc(50% - 15px);
  }
}
@media (max-width: 576px) {
  .artpag__list {
    padding-top: 30px;
  }
}

.img--bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: -1;
}

.img__wrapper {
  width: 100%;
  padding-top: 100%;
  position: relative;
  overflow: hidden;
  height: 0;
}
.img__wrapper svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.img__wrapper img {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.artpag__last {
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
}
@media (max-width: 576px) {
  .artpag__last {
    bottom: 30px;
  }
}

.artpag__link {
  display: block;
  margin-bottom: 30px;
}
.artpag__link strong {
  display: block;
  margin-bottom: 10px;
  font-size: 13px;
  color: #767676;
  font-weight: 400 !important;
  position: relative;
}
.artpag__link strong:after {
  content: "";
  width: 10px;
  height: 10px;
  border-left: 2px solid #767676;
  border-bottom: 2px solid #767676;
  position: absolute;
  display: block;
  top: 5px;
  transform-origin: center;
  border-radius: 2px;
}
.artpag__link.artpag__link--left strong {
  padding-left: 13px;
}
.artpag__link.artpag__link--left strong:after {
  left: 0;
  transform: rotate(45deg);
}
.artpag__link.artpag__link--right strong {
  padding-right: 13px;
}
.artpag__link.artpag__link--right strong:after {
  right: 0;
  transform: rotate(-135deg);
}
.artpag__link .img__wrapper {
  padding-top: 56px;
  max-width: 100px;
  min-width: 100px;
}
.artpag__link .date {
  font-size: 0.75rem;
  margin-bottom: 5px;
  display: block;
  font-weight: 400;
}
.artpag__link .artpag__title {
  font-weight: 700;
  font-size: 1.25rem;
  margin-bottom: 0px;
  color: #000000;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-family: "Lexend Deca", sans-serif;
  transition: all 0.3s ease-out;
  line-height: 1.1;
}
.artpag__link .artpag__title span {
  background-position: 0 80%;
}
@media (hover: hover) {
  .artpag__link:hover .artpag__title {
    color: #F46B37;
  }
}
@media (min-width: 992px) {
  .artpag__link {
    gap: 30px;
  }
  .artpag__link .date {
    margin-top: -5px;
  }
  .artpag__link .img__wrapper {
    padding-top: 78px;
    max-width: 140px;
    min-width: 140px;
  }
}
@media (max-width: 576px) {
  .artpag__link .artpag__title {
    font-size: 1rem;
  }
  .artpag__link .artpag__wrapper {
    gap: 10px;
    position: relative;
  }
  .artpag__link .artpag__txt {
    position: static;
    padding-bottom: 0;
    width: 100%;
  }
  .artpag__link .artpag__link strong {
    margin-bottom: 5px;
  }
}

.artpag__wrapper {
  display: flex;
  align-items: center;
  gap: 20px;
  transition: background 0.3s ease-out;
}
.artpag__wrapper img {
  border-radius: 6px;
}

.artpag__txt {
  position: relative;
  width: 100%;
}
.artpag__txt svg {
  position: absolute;
  transition: all 0.3s ease-out;
  bottom: 10px;
}

.artpag__link--right {
  margin-left: auto;
  text-align: right;
}
.artpag__link--right svg {
  right: 0;
}

.artpag__link--left {
  margin-right: auto;
}
.artpag__link--left svg {
  left: 0;
}

.article__intro {
  margin-top: 30px;
  margin-bottom: 20px;
  font-size: 1.25rem;
  font-weight: 700;
}

.article__imggroup {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  padding-top: 20px;
  padding-bottom: 20px;
}
.article__imggroup img {
  max-width: calc(25% - 9px);
  flex: 0 0 33.33%;
  margin: 0;
}
@media (max-width: 768px) {
  .article__imggroup {
    gap: 10px;
  }
  .article__imggroup img {
    max-width: calc(33.33% - 10px);
    flex: 0 0 33.33%;
  }
}
@media (max-width: 768px) {
  .article__imggroup {
    gap: 10px;
  }
  .article__imggroup img {
    max-width: 100%;
    flex: 0 0 100%;
  }
}

.ico2 {
  width: 18px;
  height: 18px;
  display: inline-block;
  background: url("../img/ico/ico-ostatnie-miejsca-s.svg") no-repeat center;
  background-size: 100% !important;
}
@media (min-width: 768px) {
  .ico2 {
    width: 34px;
    height: 34px;
  }
}

.ico2--last {
  background-image: url("../img/ico/ico-ostatnie-miejsca-s.svg");
}

.ico2--important {
  background-image: url("../img/ico/ico-wykrzyknik.svg");
}

.ico {
  display: block;
  position: relative;
  padding-left: 24px;
  min-height: 15px;
  color: #fff;
  font-size: 1.25rem;
  font-family: "Lexend Deca", sans-serif;
  line-height: 1.1;
}
.ico:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 22px;
  height: 22px;
  background: url("../img/ico/ico-calendar.svg") no-repeat center;
  background-size: contain;
}
@media (min-width: 992px) {
  .ico {
    font-size: 1.6875rem;
    min-height: 30px;
  }
  .ico:before {
    width: 30px;
    height: 30px;
  }
}

.ico--date:before {
  background: url("../img/ico/ico-calendar.svg") no-repeat center;
}

.ico--destination:before {
  background: url("../img/ico/ico-kraj.svg") no-repeat center;
}

.ico--time:before {
  background: url("../img/ico/ico-time.svg") no-repeat center;
}

.ico3 {
  display: inline-flex;
  position: relative;
  padding-left: 25px;
  min-height: 48px;
  color: #fff;
  font-size: 1rem;
  line-height: 48px;
  white-space: nowrap;
}
.ico3:before {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 100%;
  background: url("../img/ico/ico-phone.svg") no-repeat center;
}
@media (hover: hover) {
  .ico3:hover {
    color: #F46B37;
  }
}

.ico--tel:before {
  background: url("../img/ico/ico-phone.svg") no-repeat center;
}

.ico--mail:before {
  background: url("../img/ico/ico-mail.svg") no-repeat center;
}

.advantages__section {
  padding-top: 40px;
}

.advantages__row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 10px;
}

.advantages__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: flex-end;
  margin-bottom: 30px;
  gap: 20px;
  flex: 0 0 50%;
  max-width: 50%;
}
.advantages__item img {
  max-width: 90%;
}

.advantages__title {
  font-size: 1.125rem;
}
.advantages__title span {
  color: #00AE50;
  display: block;
  font-size: 1.4375rem;
}

@media (min-width: 992px) {
  .advantages__title {
    font-size: 1.25rem;
  }
  .advantages__title span {
    font-size: 1.5625rem;
  }
}
@media (min-width: 992px) {
  .advantages__item {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
}
@media (min-width: 1200px) {
  .advantages__item {
    flex: 0 0 20%;
    max-width: 20%;
    gap: 30px;
    margin-bottom: 0;
  }
}
.searchM__section {
  z-index: 42;
  position: relative;
  margin-bottom: 20px;
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
}
.searchM__section .btn {
  font-family: "Lexend Deca", sans-serif;
}
@media (max-width: 992px) {
  .searchM__section {
    background-color: #000000;
  }
}

.searchM__container {
  padding: 20px;
  width: 100%;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  max-width: 1100px;
}
@media (max-width: 992px) {
  .searchM__container .searchM__btn {
    border-color: #212121;
  }
}

.searchM__box {
  display: flex;
  gap: 6px;
  justify-content: center;
  flex-wrap: wrap;
}

.searchM__btnList {
  display: none;
}

.searchM__panel {
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  background: #fff;
  z-index: 30;
  width: 100%;
  box-shadow: 0px 7px 12px 0px rgba(0, 0, 0, 0.16);
  display: none;
  overflow: hidden;
}
.searchM__panel.show {
  height: auto;
}

.searchM__btn {
  width: 100%;
  text-transform: initial;
  text-align: left;
  position: relative;
  border: 1px solid #414141;
  padding-right: 50px;
  padding-left: 25px;
  padding-top: 15px;
  padding-bottom: 15px;
  color: #fff;
  font-size: 1rem;
  min-height: 60px;
  font-weight: 700;
  background: transparent;
}
.searchM__btn:after {
  content: "";
  width: 12px;
  height: 12px;
  border-left: 3px solid #fff;
  border-bottom: 3px solid #fff;
  position: absolute;
  display: block;
  top: 50%;
  right: 20px;
  transform-origin: center;
  transform: translateY(-50%) rotate(-45deg);
  margin-top: -3px;
}
@media (hover: hover) {
  .searchM__btn:hover {
    background: #F46B37;
    color: #fff !important;
  }
  .searchM__btn:hover:after {
    border-color: #fff;
  }
}
.searchM__btn[aria-expanded=true] {
  background: #fff;
  color: #212121 !important;
}
.searchM__btn[aria-expanded=true]:after {
  border-color: #212121;
}
.searchM__btn[aria-expanded=true]:before {
  content: "";
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid white;
  position: absolute;
  right: 18px;
  top: 105%;
}

.searchM__fieldset {
  width: 100%;
}

.searchM__btn2 {
  background: #F46B37;
  min-width: 170px;
  margin-top: 20px;
  color: #fff !important;
  padding: 15px 20px;
  font-size: 20px;
  justify-content: center;
}
.searchM__btn2:before {
  content: url("../img/ico/ico-search.svg");
  margin-right: 10px;
  position: relative;
  top: 3px;
}
@media (hover: hover) {
  .searchM__btn2:hover {
    background: #f55b1f;
  }
}

.searchM__wrapperCC {
  padding: 30px;
}

.searchM__continent {
  width: 280px;
  padding-right: 30px;
  border-right: 1px solid #D6D6D6;
}
.searchM__continent label {
  font-weight: 700;
  font-size: 1.125rem;
  padding-top: 3px;
  padding-bottom: 3px;
  margin-bottom: 15px;
}

#searchMDirection {
  position: absolute;
  left: 0px;
  top: 0px;
  width: calc(100% - 130px);
}

.searchM__countryWrapper {
  overflow: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  max-height: 90%;
}
.searchM__countryWrapper .form__checkbox label {
  font-size: 16px;
  padding-left: 28px;
}
.searchM__countryWrapper .form__checkbox label:before {
  width: 20px;
  height: 20px;
}
.searchM__countryWrapper .form__checkbox label:after {
  top: 10px;
  left: 5px;
}

.searchM__country {
  position: absolute;
  top: 30px;
  height: calc(100% - 60px);
  left: 350px;
  width: calc(100% - 400px);
  padding-top: 70px;
}
.searchM__country .form__checkbox--hide {
  display: none;
}
.searchM__country label small {
  color: #767676;
  font-size: 80%;
}

#searchMDirectionClear {
  position: absolute;
  right: 0px;
  top: 4px;
  color: #D83F40;
  font-size: 1rem;
  background: none;
  border: none;
  font-weight: 700;
  padding: 10px 25px 10px 10px;
}
#searchMDirectionClear:after {
  content: "+";
  display: inline-block;
  right: 0;
  top: 0px;
  font-weight: 300;
  transform: rotate(45deg);
  position: absolute;
  font-size: 30px;
}

.searchM__fieldset {
  position: relative;
}

.searchM__options {
  transition: max-height 0.3s ease-out;
}

.searchM__options--wide {
  max-width: 1084px;
  width: calc(100vw - 60px);
  left: 0;
}

.searchM__options--small {
  width: 280px;
  left: initial;
  right: 0;
}
.searchM__options--small.active {
  max-height: 350px !important;
}
.searchM__options--small .overflow-auto {
  max-height: 340px;
  margin-top: 5px;
  overflow: auto;
  width: calc(100% - 10px);
}
.searchM__options--small .searchM__wrapper {
  padding: 20px 10px 20px 20px;
}
.searchM__options--small .searchM__wrapper label {
  font-weight: 700;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 44px;
  transition: all 0.3s ease-out;
}
.searchM__options--small .searchM__wrapper label:before {
  left: 10px;
  top: 8px;
}
.searchM__options--small .searchM__wrapper label:after {
  left: 16px;
  top: 15px;
}
@media (hover: hover) {
  .searchM__options--small .searchM__wrapper label:hover {
    background: #f3f3f3;
  }
}

.searchM__mobile, .searchM__mobileTitle, .searchM__btnTab {
  display: none;
}

@media (min-width: 576px) {
  .searchM__fieldset {
    max-width: 45%;
    flex: 0 0 45%;
    justify-content: space-between;
  }

  .searchM__btn2 {
    margin-top: 0px;
    width: 45%;
  }
}
@media (min-width: 992px) {
  .searchM__box {
    flex-wrap: nowrap;
    gap: 15px;
  }

  .searchM__btn2 {
    width: auto;
  }

  .searchM__fieldset {
    flex: 1 1 auto;
  }

  .searchM__container {
    border-radius: 12px;
    padding: 8px;
  }

  .searchM__options--small {
    right: 0px;
    left: initial;
  }

  .searchM__section {
    margin-top: -76px;
  }

  .heroS-list.heroS-mountains:after {
    bottom: 76px;
  }
}
@media (min-width: 1200px) {
  .searchM__btn2 {
    min-width: 180px;
  }
}
@media (max-width: 992px) {
  .searchM__countryWrapper {
    grid-template-columns: 1fr 1fr;
  }

  .searchM__options--wide {
    left: -10%;
  }

  .searchM__continent {
    width: 250px;
    padding-right: 20px;
  }

  .searchM__country {
    left: 300px;
    width: calc(100% - 320px);
  }
}
@media (max-width: 992px) {
  .searchM__section.container {
    padding-left: 0px;
    padding-right: 0px;
    max-width: 100%;
  }
}
@media (max-width: 768px) {
  .accordion-active {
    overflow: hidden;
    height: 100vh;
    position: fixed;
  }
  .accordion-active .header {
    z-index: 30;
  }

  .searchM__wrapperCC {
    padding: 10px 25px 30px 25px;
  }

  .searchM__options--small .searchM__mobileTitle {
    padding: 10px 25px 10px 25px;
    border-bottom: 2px solid #D6D6D6;
  }
  .searchM__options--small .searchM__wrapper {
    padding-top: 0px;
    margin-top: 15px;
  }
  .searchM__options--small .overflow-auto {
    height: calc(100% - 180px);
    padding-top: 0;
    position: absolute;
    left: 0;
    top: 70px;
    z-index: 10;
    max-height: 100%;
  }

  .searchM__btnTab {
    position: absolute;
    bottom: 22px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 100;
    display: none;
  }

  .searchM__options {
    transition: none;
  }
  .searchM__options.active .searchM__btnTab {
    display: block;
  }

  .searchM__mobileTab {
    display: flex;
    width: 100vw;
    position: relative;
    left: -25px;
    margin-top: 5px;
    margin-bottom: 15px;
  }

  .searchM__btnT {
    height: 50px;
    background: #EDEDED;
    color: #767676;
    text-align: center;
    font-family: "Lexend Deca", sans-serif;
    width: 50%;
    border: none;
    position: relative;
    border-radius: 0;
    font-size: 1.25rem;
    z-index: 10;
  }
  .searchM__btnT.active {
    background: #000;
    color: #fff;
  }
  .searchM__btnT.active:before {
    content: "";
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #000;
    position: absolute;
    left: calc(50% - 4px);
    top: 100%;
  }

  .searchM__options.active {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    border-radius: 0;
    z-index: 10000;
    max-height: 100vh !important;
    height: 100vh !important;
    height: -webkit-fill-available !important;
    height: -moz-available !important;
    height: stretch !important;
    transition: none;
  }

  .searchM__mobile {
    display: block;
  }

  .searchM__mobileTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.25rem;
    font-family: "Lexend Deca", sans-serif;
    position: relative;
    z-index: 2;
  }

  .searchM__mobileClose {
    font-size: 50px;
    min-width: 48px;
    height: 48px;
    background: none;
    border: 0;
    transform: rotate(45deg);
    color: #000;
    position: relative;
    left: 15px;
    top: -6px;
  }

  #searchMDirection {
    left: 25px;
    width: calc(100% - 145px);
    top: 0px;
  }

  #searchMDirectionClear {
    top: 10px;
    right: 20px;
  }

  .searchM__countryWrapper {
    max-height: calc(100% - 135px);
  }

  .searchM__continent {
    width: 100%;
    padding: 0;
    height: 100%;
    position: absolute;
    left: 25px;
    top: 125px;
    opacity: 1;
    background: #fff;
    border: none;
    overflow: auto;
    max-height: calc(100% - 210px);
    width: calc(100% - 50px);
    z-index: -1;
    opacity: 0;
  }
  .searchM__continent.active {
    z-index: 10;
    opacity: 1;
  }

  .searchM__country {
    background: #fff;
    width: 100%;
    max-width: 100%;
    left: 0;
    top: 125px;
    padding: 70px 25px 30px 25px;
    z-index: -1;
    opacity: 0;
  }
  .searchM__country.active {
    z-index: 10;
    opacity: 1;
  }
}
@media (max-width: 576px) {
  .searchM__btn {
    padding-bottom: 15px;
    padding-top: 15px;
  }

  .searchM__btn2 {
    margin-top: 5px;
    padding-bottom: 15px;
    padding-top: 15px;
  }
}
.why__section {
  background-image: url("../img/dh-decor-bg.svg");
  overflow: hidden;
  position: relative;
  z-index: 1;
  padding-top: 20px;
  background-position: bottom;
  padding-bottom: 40px;
}
.why__section .title3 {
  padding-top: 30px;
}

.why__decor {
  position: absolute;
  top: 0px;
  left: 50%;
  height: 100%;
  width: auto;
}

.why__txt {
  max-width: 650px;
}
.why__txt strong {
  display: block;
  margin-top: 25px;
}

.why__icons {
  display: flex;
  gap: 20px;
  padding-bottom: 40px;
  position: relative;
  margin-bottom: 30px;
  margin-top: 50px;
}
.why__icons:after {
  content: "";
  height: 8px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to right, #FFB534 0%, #FFB534 10%, #995348 19%, #995348 28%, #D83F40 37%, #D83F40 46%, #428CC3 55%, #428CC3 64%, #505184 73%, #505184 82%, #00AE50 90%, #00AE50 100%);
}

.why__item strong {
  font-size: 3.125rem;
  display: block;
  color: #00AE50;
  font-weight: 400;
  font-family: "Lexend Deca", sans-serif;
}

@media (min-width: 1200px) {
  .why__icons {
    gap: 40px;
  }
  .why__icons img {
    width: auto;
    height: 40px;
  }
}
@media (min-width: 768px) {
  .why__icons {
    justify-content: flex-end;
    margin-top: -25px;
    margin-bottom: 40px;
  }
}
@media (max-width: 576px) {
  .advantages__section, .why__section, .ueInfo {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.guide__link {
  display: flex;
  width: 100%;
  position: relative;
  color: #000000;
  background: #F4F4F4;
}
@media (hover: hover) {
  .guide__link:hover {
    color: #000000;
  }
  .guide__link:hover .guide__img {
    transform: translate(-50%, -50%) scale(1.1);
  }
}

.guide__item--hide {
  opacity: 0;
  max-width: 0 !important;
  overflow: hidden;
  visibility: hidden;
  z-index: -1000;
  padding: 0 !important;
}

.guide2__item {
  transition: max-width 0.2s ease-out, 0.1s ease-out;
}

.guide__figure {
  position: relative;
  flex: 0 0 140px;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
}

.guide__img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: 1;
  transition: all 0.3s ease-out;
}

.guide__title {
  font-weight: 700;
  margin-bottom: 12px;
  font-size: 20px;
}

.quide__info {
  padding: 20px 10px 20px 25px;
  min-height: 140px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.guide__icons--front {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 15;
}

.guide2__hover {
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: #F4F4F4;
  z-index: 20;
  padding: 20px;
  color: #000000;
  transition: all 0.3s ease-out;
  overflow: hidden;
}

.guide__figure2 {
  display: flex;
  gap: 15px;
  align-items: center;
}
.guide__figure2 img {
  min-width: 90px;
  max-width: 90px;
  height: 90px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 50%;
}

.guide__btn {
  font-size: 0.875rem;
  border-radius: 8px;
  display: block;
  line-height: 1;
  padding: 12px 12px 12px 30px;
  position: relative;
  color: #000000;
  text-transform: uppercase;
  font-weight: 700;
  transition: all 0.3s ease-out;
  background: #EDEDED;
}
.guide__btn:before {
  display: block;
  content: "";
  width: 14px;
  height: 100%;
  background: url("../img/ico/ico-i.svg") center no-repeat;
  background-size: 14px;
  position: absolute;
  left: 10px;
  top: 0;
}
.guide__btn:hover {
  background: #000;
  color: #fff;
}
.guide__btn:hover:before {
  filter: brightness(0) invert(1);
}

.guide__des {
  margin-top: 20px;
  display: -webkit-box;
  width: 100%;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.guide__bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  position: absolute;
  bottom: 0;
  left: 20px;
  width: calc(100% - 40px);
  padding: 20px 0;
  border-top: 1px solid #D6D6D6;
}

.guide2__title {
  margin-bottom: 0;
}

.guide2__link:hover .guide2__hover {
  left: 0;
}

@media (max-width: 768px) {
  .guide__figure {
    flex: 0 0 100px;
  }

  .guide__title {
    font-size: 16px;
  }

  .quide__info {
    font-size: 14px;
    padding: 15px 10px 15px 20px;
    min-height: 100px;
  }
}
.slice__contact {
  background: #F2F7F8;
  padding: 30px;
  -moz-column-gap: 10px;
       column-gap: 10px;
}
.slice__contact p {
  color: #767676;
}
.slice__contact .ico3 {
  color: #000;
  font-size: 1.25rem;
  font-weight: 700;
  display: block;
  min-height: 30px;
  line-height: 30px;
}
.slice__contact .ico--mail {
  font-size: 16px;
}
@media (max-width: 576px) {
  .slice__contact {
    padding: 20px;
  }
}

.slice__h {
  font-size: 10px;
  padding-left: 25px;
}

.slice__telForm {
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 15px;
}
.slice__telForm input {
  height: 50px;
  box-sizing: border-box;
  border: 2px solid #E8E4DF;
  border-right: none;
  background-image: url("../img/ico/ico-phone.svg");
  background-position: left 20px center;
  padding: 9px 0 9px 50px;
  background-repeat: no-repeat;
  width: calc(100% - 50px);
}
.slice__telForm input:focus-visible {
  border-color: #000000;
  box-shadow: inset 0 0 0 1px #000000;
}
.slice__telForm .btn {
  padding: 0;
  width: 50px;
  background-position: center;
}

.subHero {
  background: #F4F4F4;
  padding-bottom: 40px;
  margin-bottom: 30px;
  font-size: 1.125rem;
}
.subHero p {
  max-width: 950px;
}
.subHero h2 {
  color: #505184;
}
.subHero img {
  border-radius: 12px;
}

.subHero__p {
  padding: 0 40px;
  margin: 0 auto;
  text-align: center;
}

.paymentsUrl {
  border: 1px solid #D6D6D6;
  box-shadow: 0 6px 9px rgba(0, 0, 0, 0.16);
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 30px;
}
.paymentsUrl h2 {
  padding-top: 5px;
  font-size: 1.75rem;
}
.paymentsUrl h2 strong {
  white-space: nowrap;
  color: #00AE50;
}

.paymentsUrl__list p {
  margin-bottom: 5px;
}

.paymentsUrl__item {
  border-radius: 12px;
  background: #EDEDED;
  padding: 30px;
}
.paymentsUrl__item.noborder {
  border: none;
  background: transparent;
  padding: 20px;
}
.paymentsUrl__item.border-main {
  border: 1px solid #F46B37;
}

.paymentsUrl__title {
  font-size: 20px;
  font-family: "Lexend Deca", sans-serif;
}

.copy-bank-account {
  display: inline-block;
  font-size: 12px;
  color: #fff;
  background: #000000;
  border-radius: 12px;
  line-height: 22px;
  padding: 0 12px 0 26px;
  position: relative;
  font-weight: 700;
  background-image: url(../img/ico-copy.svg);
  background-position: left 10px center;
  background-repeat: no-repeat;
  background-size: 12px;
  border: none;
}

.copy-to-clipboard-info {
  position: absolute;
  top: 120%;
  right: -10px;
  background: #fff;
  border-radius: 10px;
  padding: 5px 10px;
  font-size: 10px;
  display: block;
  white-space: nowrap;
  z-index: 20;
  color: #767676;
  line-height: 1;
  font-weight: 400;
  width: auto !important;
  box-shadow: 2px 2px 15px 0 rgba(0, 0, 0, 0.25);
}
.copy-to-clipboard-info:after {
  position: absolute;
  right: 20px;
  top: -4px;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #fff;
  content: "";
  display: block;
}

.item-bank-account {
  display: flex;
  align-items: center;
  gap: 5px;
  flex-wrap: wrap;
  margin-bottom: 5px;
  justify-content: center;
}

.term__label2 .btn {
  background: #F46B37;
  padding: 12px 15px;
  font-size: 16px;
  font-family: "Lexend Deca", sans-serif;
  font-weight: 400;
  margin-top: 15px;
  margin-bottom: 5px;
}
@media (hover: hover) {
  .term__label2 .btn:hover {
    background: #212121;
  }
}

.property .detailsMap {
  display: none;
  color: #fff;
  background: #000;
  padding: 5px 10px;
  border-radius: 2px;
  position: absolute;
  top: 100%;
  left: 50%;
  font-size: 15px;
  transform: translateX(-50%);
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.3);
  white-space: nowrap;
}

.property .icon img {
  filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.3));
}

.highlight .detailsMap {
  display: block;
}

.mpl {
  padding-bottom: 40px;
}

.mpl__list {
  margin-bottom: 10px;
}

.mpl__item {
  padding-top: 75%;
  position: relative;
  color: #fff;
  display: block;
  overflow: hidden;
}
.mpl__item h3 {
  font-size: 18px;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 25px 10px 25px 25px;
  width: 100%;
  margin-bottom: 0;
  z-index: 3;
}
.mpl__item img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 100%;
  z-index: 0;
  transition: all 0.3s ease-out;
}

@media (hover: hover) {
  a.mpl__item:hover {
    color: #fff;
    box-shadow: 0 6px 9px rgba(0, 0, 0, 0.16);
  }
  a.mpl__item:hover img {
    transform: translate(-50%, -50%) scale(1.1);
  }
}

.mpl__list--shadow a:before {
  display: block;
  content: "";
  width: 100%;
  height: 80%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75));
}

.wideo__wrapper {
  position: relative;
  padding-top: 56%;
}
.wideo__wrapper iframe, .wideo__wrapper video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.wideo__outer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.wideo__outer .wideo__item {
  width: 100%;
}
@media (min-width: 576px) {
  .wideo__outer .wideo__item:not(:first-child) {
    max-width: calc(50% - 10px);
  }
}

.fullWidth__outer {
  padding-left: 60px !important;
}
@media (max-width: 992px) {
  .fullWidth__outer {
    padding-left: 20px !important;
  }
  .fullWidth__outer .swiper-button-next, .fullWidth__outer .swiper-button-prev {
    opacity: 0;
    z-index: -1;
    width: 0;
    height: 0;
    overflow: hidden;
  }
}

.fullWidth__wrapper {
  position: relative;
  padding-top: 40px;
  padding-bottom: 40px;
}
.fullWidth__wrapper .swiper-slide.swiper-slide-active + .swiper-slide + .swiper-slide + .swiper-slide {
  opacity: 0.4;
}
.fullWidth__wrapper .swiper-button-next, .fullWidth__wrapper .swiper-button-prev {
  top: 40px;
  left: initial;
}
.fullWidth__wrapper .swiper-button-next:after, .fullWidth__wrapper .swiper-button-prev:after {
  border: none;
  transform: none;
  width: 26px;
  height: 26px;
  top: 0;
  left: 0;
  transform: none;
  background-size: contain;
}
.fullWidth__wrapper .swiper-button-prev {
  right: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.fullWidth__wrapper .swiper-button-prev:after {
  background: url("../img/ico/arrow-left-black.svg") center center no-repeat;
  background-size: contain;
  position: static;
}
.fullWidth__wrapper .swiper-button-next {
  right: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.fullWidth__wrapper .swiper-button-next:after {
  background: url("../img/ico/arrow-right-black.svg") center center no-repeat;
  background-size: contain;
  position: static;
}

@media (max-width: 576px) {
  .fullWidth__wrapper {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}