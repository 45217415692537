
.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 120;
    padding: 30px;
    width: 100%;
    height: 100vh;
    margin: 0;
    padding: 0;

    opacity: 0;
    visibility: hidden;
    transition: visibility 0s linear 0.1s, opacity 0.3s ease;

    &.open {
        visibility: visible;
        opacity: 1;
        transition-delay: 0s;
    }

    .link--underline-main {
        display: block;
        margin-bottom: 10px;
    }

    p {
        margin-bottom: 20px;
    }

    &__overlay {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 21;
        background-color: rgba(0, 0, 0, 0.7);

    }

    &__close {
        position: absolute;
        top: 5px;
        right: 5px;
        border: none;
        outline: none;
        background: none;
        width: 48px;
        height: 48px;
        display: inline-block;
        background: $txt;
        color: #fff;
        padding: 0;

        &:after {
            content: "+";

            transform: rotate(45deg);
            font-size: 32px;
            position: absolute;
            top: 2px;
            left: 17px;
            transition: all 0.3s ease-out;

        }

        &:hover {
            background: $main;
        }
    }



    &__container {
        position: absolute;
        z-index: 22;
        max-width: 1520px;
        width: 96%;
        max-height: 90%;
        overflow: auto;
        max-width: 540px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin: 0 auto;
        padding: 50px;
        background-color: #fff;
 }

    &__title {
        margin-bottom: 30px;
        text-transform: uppercase;
        text-align: left;
        margin-top: 0;
    }
    h4 {
        font-size: $font30;
        margin-bottom: 10px;
        color: #000;
    }

    @media(max-width:$grid-breakpoints-lg) {
        &__title {
            font-size: $font24;
        }

        h4 {
            font-size: $font20;
            margin-bottom: 5px;
        }
    }

    ul {
        list-style: disc;
        padding-left: 30px;
    }

}

@media(max-width: $grid-breakpoints-md){
    .modal__container{
        padding:15px 25px;

        .mt-30{
            margin-top:20px !important;
        }
    }

    .modal__title{
        padding-right:40px;
    }
}
