@import "breadcrumbs";


.header__fixed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
}

@keyframes fixedMenuDown {
  0% {
    top: -100px;
  }

  100% {
    top: 0;
  }
}

.subpage {
  .header {
    background: #000;
  }
}

.header {
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;

  &.fixed-header-scroll {
    position: fixed;
    top: 0;
    background: #000;
    -webkit-animation-name: fixedMenuDown;
    animation-name: fixedMenuDown;
    -webkit-animation-duration: .5s;
    animation-duration: .5s;
    left: 0;
    width: 100%;
    z-index: 100;
    box-shadow: 2px 2px 15px 0 rgba(0, 0, 0, 0.25);

    .headerControls__name {
      display: none;
    }

    &:after {
      height: 4px;
    }

    @media(min-width: $grid-breakpoints-lg) {
      .navMain {

        margin-top: 0;

        .nav__level-1 > li > a {
          height: 52px;
          padding-top: 10px;
        }
      }

      .nav__level-2 {
        top: 100%;
        z-index: 20;

        &:before {
          width: 100%;
          height: 6px;
          border: none;
          top: -6px;
          background: transparent;
          left: 0;
        }
      }
    }


    @media(max-width: $grid-breakpoints-lg) {
      .navMain.open {
        top: 60px;
        height: calc(100vh - 60px);
      }
    }

    .header__wrapper {
      height: 60px;

      .logo {
        img {
          max-width: 160px;
        }
      }
    }

  }
}

.hero__calendar{
  background: url(../img/ico/ico-calendar-white.svg) no-repeat center;
  background-size: 20px;
  padding-left: 30px;
  background-position: left center;
  line-height: 27px;
  white-space: nowrap;
}

.header--2 {
  background: #fff;

  &:after {
    height: 8px;
  }
}

.header__wrapper {
  display: flex;
  gap: 30px;
  height: 80px;
  align-items: center;
  justify-content: space-between;

  &.container {
    max-width: 1580px;
  }
}


.logo {
  line-height: 1;

  img {
    width: 210px;
  }
}

.header__contact {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-left: auto;
  overflow: hidden;
}

.header__social {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-left: auto;
  margin-right: auto;

  a {
    width: 30px;
    height: 30px;
    display: inline-flex;
    line-height: 1;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 50%;

    @media(hover: hover) {
      &:hover {
        background: $main;
      }
    }

  }
}


.nav2 ul {
  font-size: $font12;

  display: flex;

  a {
    font-weight: 700;
    color: $txt;
    text-transform: uppercase;
    display: inline-block;
    padding: 15px;
  }
}

.headerForm {
  width: 170px;
  position: relative;
  margin-left: auto;

  input {
    border: none;
    width: 100%;
    border-bottom: 2px solid $txt;
    padding-right: 30px;
  }

  ::placeholder {
    color: $txt;
    font-weight: 700;
  }

  button {
    position: absolute;
    width: 30px;
    right: 0;
    bottom: 0;
    border: none;
    padding: 0;
    display: inline-block;
    background: transparent;
    height: 100%;
    background-image: url("../img/btn-search.svg");
    background-repeat: no-repeat;
    background-position: center;

  }
}


.headerContact {
  margin-left: auto;
  margin-right: auto;
}

.headerContact__phone {
  color: $txt;
  line-height: 1.1;
  padding-left: 25px;
  position: relative;
  display: block;


  &:before {
    content: url("../img/phone.svg");
    position: absolute;
    left: 0px;
    top: 9px;

  }

  span {
    font-size: 8px;
    text-transform: uppercase;
  }

  strong {
    font-size: 18px;
    display: block;
  }

  @media(hover: hover) {
    &:hover {
      color: $main;
    }
  }
}


.headerControls {
  display: flex;
  align-items: center;
  list-style: none;
  padding: 0;
  font-size: $font14;
  gap: 30px;

}


.headerControls__link {
  display: inline-flex;
  min-width: 50px;
  height: 50px;
  justify-content: center;
  align-items: center;
  border: none;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../img/ico/ico-heart.svg");

  @media(hover: hover) {
    &:hover {
      svg {
        stroke: $main !important;
      }
    }
  }
}

.headerControls__heart {
  &.active {
    background-image: url("../img/ico/ico--full.svg");
  }
}

.headerControls__amount {
  position: absolute;
  color: $white;
  font-size: 11px;
  top: 5px;
  right: 4px;
}


.nav-toggle {
  min-width: 50px;
  height: 50px;
  background: none;
  border: none;
  position: relative;
  overflow: hidden;
  display: none;
  padding: 0;
  margin-left: -10px;


  span {
    width: 14px;
    height: 3px;
    background: $white;
    display: block;
    position: absolute;
    left: 12px;
    top: 15px;
    transition: all 0.3s ease-in-out;
    transform-origin: center;

    &:nth-child(2) {
      top: 22px;
    }

    &:nth-child(3) {
      top: 29px;
      width: 10px;
    }

  }

  &.open span {
    &:first-child {
      transform: rotate(45deg);
      top: 24px;
    }

    &:nth-child(2) {
      left: 50px;
      opacity: 0;
    }

    &:nth-child(3) {
      top: 24px;
      transform: rotate(-45deg);
      width: 14px;
    }
  }

}

.nav {
  position: absolute;
  top: 80px;
  left: 20px;
  height: 100px;
  width: calc(100% - 40px);
  background: red;
  z-index: 100;
  display: none;

}

.page__header {
  position: relative;
  min-height: 150px;
  padding-top: 40px;
  padding-bottom: 30px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-image: linear-gradient(to right, #1a7cc1, #00b2ec);

  .breadcrumbs {

    ul {
      justify-content: center;

      a,
      span {
        color: #eee;
      }
    }

    border: none;
  }

  h1,
  h2 {
    font-size: $font40;
    margin-bottom: 10px;
    color: #fff;
    text-align: center;
  }

  @media(max-width: $grid-breakpoints-sm) {
    h1,
    h2 {
      font-size: $font30;
    }
  }


}


.page__header--product {
  min-height: 100px;
  padding-top: 0;
  display: flex;
  align-items: center;

}

.page__headerImg {
  position: absolute;
  top: 0;
  height: 100%;
  width: auto;
  right: 0;
  z-index: -1;

  @media(max-width: $grid-breakpoints-xl) {
    opacity: 0.2;
  }

}


header ul a {
  padding: 5px 0;
  display: block;
}

.navMain .nav__level-1 > li > a {
  padding: 10px 8px;
  font-family: $font2-family-name;
}

header > ul > li {
  position: relative;
}


.navMain > ul {
  display: flex;
  // gap: 30px;

  > li > a {
    padding: 10px 0;
    font-size: $font16;
    color: $white;
    white-space: nowrap;
  }

  @media(hover: hover) {
    a:hover {
      color: $main;
    }

    li:hover > a {
      color: $main;
    }
  }
}


.nav__level-2 {
  display: none;
  position: absolute;
  background: #fff;
  width: 260px;
  text-align: left;
  left: -20px;
  padding: 30px 20px 10px 45px;

  &:before {
    content: "";
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;

    border-bottom: 10px solid #fff;
    position: absolute;
    left: 50px;
    top: -9px;
  }

  // box-shadow: 2px 2px 15px 0 rgba(0, 0, 0, 0.25);

  li {
    position: relative;

  }
}

.nav__level-2wrapper {
  display: grid;
  gap: 30px;
  grid-template-columns: 1fr 1fr;
}

.nav__level-2C {
  width: 480px;
  display: none;
  background: #fff;
  background-image: url("../img/menu-decor.svg");
  background-repeat: repeat-y;
  background-position: left;
  box-shadow: 2px 2px 15px 0 rgba(0, 0, 0, 0.25);

  .open {
    display: flex;
  }
}

.nav__title {
  font-size: $font20;
}


.nav__list2 {
  padding: 10px 0;


  a {
    font-size: $font16;
    padding: 4px 0;
    color: $black;
    white-space: nowrap;
    width: 100%;
    display: block;
  }
}

// .navMain>ul>li:hover ul {
//     display: block;
// }

// .navMain>ul>li:hover>ul {
//     display: block;
//     z-index: 100;
// }

.navMain a.active {
  color: $main;
}


.nav__level-3 {
  left: 100%;
  top: 0;
  display: none !important;
}

.nav__level-4 {
  display: none !important;
  left: 100%;
  top: 0;
  position: absolute;
}


.nav__level-3.open {
  display: block !important;
}

.nav__level-4.open {
  display: block !important;
}


@media(max-width: 1400px) {
  .header__contact {
    .ico3 {
      padding-left: 30px;

      &:before {
        left: 5px;
      }

      span {
        opacity: 0;
        position: absolute;
        z-index: -1;;
        width: 0;
        display: block;
      }
    }
  }
}

@media(max-width: $grid-breakpoints-xl) {
  .header__wrapper {
    gap: 10px;
  }
}


@media(max-width: $grid-breakpoints-lg) {

  .nav2,
  .headerContact {
    display: none;
  }
  .nav-toggle {
    display: block;
  }


}


@media(min-width: $grid-breakpoints-lg) {

  .navMain {
    margin-left: 40px;
    margin-top: 0px;

    .nav__level-1 > li > a {
      padding: 15px 8px 15px 8px;
    }
  }
  .logo img {
    width: 200px;
  }


  .nav__level-2D {
    width: 420px;

  }


  .navMain__mobile {
    display: none;
  }

  .menu__item--has-children--icon {
    padding-left: 25px;

    > a {
      padding-left: 0 !important;
    }

    background-image: url("../img/ico/ico-menu-wyprawy-white.svg");
    background-repeat: no-repeat;
    background-position: center left;
    @media(hover: hover) {
      &:hover {
        background-image: url("../img/ico/menu-close.svg");

        > a {
          color: $main;
        }
      }
    }
  }
}

@media(min-width: $grid-breakpoints-xl) {
  .logo img {
    width: 250px;
  }
}

@media(min-width: 1500px) {

  .navMain .nav__level-1 > li > a {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.menu__item--has-children {
  position: relative;
}


@media(max-width: $grid-breakpoints-lg) {

  .header {
    transition: background 0.1s ease-out 0.3s;
  }

  .navMain > ul > li > a {
    color: $txt;
    padding-left: 0 !important;
    font-weight: 700;
  }

  .menu-open {
    overflow: hidden;
    height: 100vh;

    .header {
      //background: rgba(33, 33, 33, 0.97);
      transition: none;

    }
  }
  .header__contact {
    gap: 5px;
  }

  .nav__level-2C {
    left: 0;
    top: 0;
    display: block;
    position: relative;
    width: 100%;
    background: transparent;
    box-shadow: none;
    padding: 0;

    ul {
      display: block;
      border: none;
    }

    &:before {
      content: none;
    }
  }


  .header__wrapper {
    height: 80px;
    gap: 0;
  }


  .menu__item--has-children {
    position: relative;

    > a {
      width: 100% !important;
      padding-right: 50px;
    }

    .nav__level-1 {
      margin-bottom: 0;
    }

    &:after {
      content: "";
      display: inline-block;
      width: 10px;
      height: 10px;
      border-left: 3px solid $txt;
      border-bottom: 3px solid $txt;
      position: absolute;
      right: 20px;
      top: 19px;
      transform: rotate(-45deg);
      z-index: -1;

    }
  }


  .navMain {

    position: fixed;
    z-index: 100;
    background-image: url("../img/menu-decor.svg");
    background-repeat: repeat-y;
    background-position: top right;
    max-height: calc(100vh - 80px);
    top: 80px;
    width: 90vw;
    left: -100vw;
    transition: left 0.3s ease-out;
    background-color: #fff;
    padding-right: 15px;
    overflow: hidden;
    padding-left: 20px;
    padding-right: 20px;
    box-shadow: 2px 2px 15px 0 rgba(0, 0, 0, .25);


    &.open {
      left: 0;
      overflow: auto;
    }

    > ul {
      display: block;

      &:last-child {
        margin-bottom: 60px;
      }

      > li {
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      }
    }

    .nav__level-2 {
      display: none;
      background: transparent;
      padding-bottom: 10px;

      //&.nav__level-2C {
      //  display: block;
      //}
    }

    ul {
      li {
        width: 100%;

      }

      .nav__list2 {
        margin-top: 10px;

        a {
          line-height: 1.2;
        }
      }

      ul {

        width: 100%;
        position: relative;

        overflow: auto;
        padding: 0;
        transition: all 0.3s ease-out;

        column-count: 1;
        gap: 10px;

        &.open {
          max-height: 500px;
        }

        li {
          padding-left: 0;
          padding-right: 0;
        }

        a {
          padding-left: 0px;

        }

        ul a {
          padding: 7px 0 7px 30px;
          font-size: $font14;

        }
      }
    }

    > ul {
      //max-height: calc(100% - 80px);
      //overflow: auto;

      > li {

        > a,
        > ul {
          padding-left: 20px;
          padding-left: 20px;
          border: none;
        }
      }
    }
  }
}


@media(max-width: $grid-breakpoints-md) {
  .header__social {
    display: none;
  }
}

@media(max-width: $grid-breakpoints-sm) {
  .navMain {
    top: 60px;

    .nav__level-2 {
      flex-direction: column;
      padding-left: 10px;
    }
  }

  body:not(.mainpage){
    .header__wrapper{
      background:$black;
    }
  }

  .header--2:not(.fixed-header-scroll):after {
    background: $line;
    bottom: -8px;
  }


  .headerControls__link {
    background-size: 20px;
  }


  .header__wrapper {
    height: 60px;

    .logo {
      max-width: 140px;
      max-width: calc(100% - 180px);

      img {
        max-width: 100%;
      }
    }

    //.header__contact {
    //  position: absolute;
    //  left: 60px;
    //  bottom: 2px;
    //  top: initial
    //}
  }

  .header__wrapper.container {
    padding-right: 10px;
  }

  .nav__level-2wrapper {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr;
  }

  .header:after {
    bottom: -2px;
  }

  .navMain > ul > li {
    border-bottom: 1px solid rgba(255, 255, 255, 0.7);
  }

  .header.fixed-header-scroll {
    .header__contact {
      display: none;
    }

    .nav-toggle {
      position: relative;
      top: 0px;
    }

    .header__wrapper {
      padding-top: 0;

      &:after {
        content: none;
      }


    }
  }

}

